// Page wrapper styles

.pi-analytics-wrapper {
    height: 100%;
    overflow-y: auto;

    .pi-page-body {
        display: flex;
        flex-wrap: wrap;

        .pi-column-md {
            width: 440px;
        }

        .pi-column-lg {
            width: 100%;
            padding-left: 20px;
            max-width: calc(100% - 440px);
        }
    }
}