.pi-simple-list {
    li {
        display: flex;
        font-size: $sm;
        font-weight: 500;
        color: $color-text;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        a {
            text-decoration: underline !important;

            &:hover {
            }
        }
    }
}