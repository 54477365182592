// Map picker info window

// Old styles overides
.gm-style .gm-style-iw-c {
    overflow: hidden !important;
    min-height: inherit !important;
}

// Map picker tooltip
.pi-picker-tooltip {
    p {
        font-size: $sm;
        margin-bottom: 0;
        color: $color-text;
    }
}

.gm-style .gm-style-iw-c {
    padding: 12px !important;
}

.gm-style .gm-style-iw-d {
    * > div {
        min-height: auto !important;
    }
}

.pi-picker-window {
    overflow: hidden;

    &.pi-size {
        &-md {
            width: 100%;
            max-width: 535px;
        }
        &-lg {
            width: 100%;
            max-width: 630px;
        }
        &-sm {
            width: 100%;
            max-width: 430px;
        }
    }

    .pi-details-body {
        display: flex;

        .pi-mute-btn {
            margin-left: auto;
            margin-right: auto;
            padding-left: 10px;
            padding-right: 10px;
        }

        &.pi-with-image {
            .pi-image-wrapper {
                width: 100%;
                max-width: 175px;
                padding: 20px 8px;
                border-right: 1px solid $color-border;

                .pi-image {
                    width: 100%;
                    padding-top: 100%;
                    max-height: 160px;
                    background: no-repeat center center;
                    background-size: contain;
                }

                .pi-content-wrapper {
                    max-width: calc(100% - 160px);
                }
            }
        }

        .pi-content-wrapper {
            width: 100%;
            padding: 12px 12px 0px 15px;

            &.pi-sp-sm {
                padding: 5px;
            }

            .pi-title-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h5 {
                    width: 100%;
                    font-weight: 500;
                    margin-bottom: 12px;
                    word-break: break-word;
                    text-transform: uppercase;
                    color: rgba($color-text, 0.7);
                    max-width: calc(100% -  100px);
                }

                h4 {
                    margin-bottom: 0;

                    a {
                        font-weight: 500;
                        color: $color-text;
                        text-decoration: underline;
                    }
                }
            }

            .pi-info-row {
                .pi-row {
                    display: flex;

                    &:not(.pi-no-border) {
                    }

                    &.pi-with-border {
                        padding: 8px 0;
                        border-top: 1px solid $color-border;
                    }

                    .pi-detail-item.pr-0 {
                        padding-right: 0;
                    }
                }

                .toggle-switch-title {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        .pi-btn-wrapper {
            .pi-mr-5 {
                margin-right: 5px;
            }
        }
    }

    .pi-details-footer {
        display: flex;
        align-items: center;
        padding: 14px 5px 5px;
        justify-content: space-between;
        border-top: 1px solid $color-border;

        .gm-style .gm-style-iw-c {
            padding: 0 !important;
        }

        .pi-detail-item:not(:first-of-type):not(.pi-no-p) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .pi-btns-group {
        display: flex;
    }
}
