// Global checkbox element

.pi-checkbox {
    cursor: pointer;
    margin-bottom: 0;
    align-items: center;
    @include transition(all 0.2s);

    &:not(.pi-inline) {
        display: flex
    }

    &.pi-inline {
        display: inline-flex;
    }

    input {
        display: none;

        &:checked + .pi-check-icon {
            &::before {
                content: $icon-checkbox;
            }
        }

        &:disabled {
            & ~ i, & ~ span {
                cursor: default;
                color: rgba($color-text, 0.6);
            }

            & + i::before {
                color: rgba($color-text, 0.6) !important;
            }
        }
    }

    .pi-check-icon {
        display: block;
        padding-right: 10px;

        &::before {
            font-size: 24px;
            content: $icon-checkbox-outline;
            font-family: $font-family-icons;
        }
    }

    .pi-check-text {
        font-size: $sm;
        font-weight: 500;
        color: $color-text;
    }

    // Color modifications
    &.pi-primary {
        .pi-check-icon {
            &::before {
                color: $color-primary;
            }
        }
    }

    &.pi-secondary {
        .pi-check-icon {
            &::before {
                color: $color-secondary;
            }
        }
    }

    &.pi-text {
        .pi-check-icon {
            &::before {
                color: $color-text;
            }
        }
    }

    // Reversed version
    &.pi-reversed {
        justify-content: space-between;
        flex-direction: row-reverse;

        i {
            margin-right: 0;
        }
    }
}

// Checkbox lists
.pi-checklist {

    &.pi-with-border {
        li:not(:last-child) {
            border-bottom: 1px solid rgba($color-text, 0.1);
        }
    }

    li {
        padding: 5px 0;
    }
}