.device-detail-container {
    .mute-btn {
        color: white;
        background-color: $orange;
        border-color: transparent;

        &:active {
            color: white;
            background-color: $orange;
            border-color: transparent;
        }

        &.unmute {
            background-color: rgba(#bdbdbd,0.7);
            color: white;

            &:hover {
                background-color: #bdbdbd;
            }
        }

        &:disabled {
            color: $orange;
            background-color: white;
            border-color: $orange;
        }
    }

    .device-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        .device-image {
            max-height: 200px;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    .wz-device-item-container {
        cursor: pointer;
        .wz-device-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px !important;
            border: 1px solid $orange;
            border-radius: 5px;
            .wz-device-image {
                width: 150px;
                height: 150px;
                object-fit: contain;
            }
        }

        .wz-sm-image-container {
            .wz-device-image {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }

            .wz-index {
                text-align: center;
                font-weight: bold;
            }
        }

        .lamps-container {
            max-height: 512px;
            overflow-y: auto;
        }

        .b-solar-barricade-container {
            max-height: 256px;
            overflow-y: auto;
            overflow-x: hidden;

            .device-map-container {
                height: 120px;
            }
        }
    }

    .info-icon-wrap {
        width: 25px;
        margin-right: 8px;
        display: inline-block;
        text-align: center;
    }

    .device-map-container {
        min-height: 400px;
    }
}
