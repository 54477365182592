// Material date & time picker

.pi-timepicker {
    position: relative;

    // START: Should be deleted after new timepicker integration
    & > span {
        display: block;
        font-weight: 500;
        color: $color-text;
        margin-bottom: 8px;
    }
    .pi-filed-wrapper {
        position: relative;

        .MuiFormControl-root {
            width: 100%;
            .MuiInputBase-root {
                border: none;
                .MuiInputBase-input {
                    height: 40px;
                    border: 1px solid $color-border;
                    box-sizing: border-box;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                    border-radius: 4px;
                    margin: 0 !important;
                    padding-left: 15px;
                    padding-right: 45px;
                    background-color: #fff;
                }
            }

            button.MuiButtonBase-root {
                padding: 0;
                right: 20px;
                position: absolute;
            }
        }

        .MuiInput-underline::after {
            display: none;
        }
    }

    // END: Old timepicker


    // New time picker
    .pi-field-wrapper {
        position: relative;

        .pi-icon {
            top: 50%;
            right: 10px;
            font-size: 24px;
            position: absolute;
            @include transform(translateY(-50%));
        }
    }

    &.pi-invalid {
        .pi-error-message {
            font-size: $xs;
            margin-bottom: 0;
            color: $color-red;
            position: absolute;
            top: 42px;
        }
    }

    .react-datepicker__input-container {
        @extend .pi-text-field;

        input {
            padding-right: 48px;
        }
    }

    // DateTimePicker plugin styles
    .react-datepicker-popper {
        left: 0 !important;
        width: 100%;
        transform: translateX(0) !important;
        top: 45px !important;
    }

    .react-datepicker-popper[data-placement^=bottom] {
        padding-bottom: 0;
    }

    .react-datepicker__header {
        display: none;
    }

    .react-datepicker {
        width: 100%;
        color: $color-text;
        font-size: $sm;
        border-radius: $radius-sm;
        border-color: $color-border;
        font-family: $font-family-base;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__time-container .react-datepicker__time {
        border-radius: $radius-sm;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
    .react-datepicker--time-only .react-datepicker__time-container {
        width: 100%;
        text-align: left;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: $color-primary;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        height: 38px;
        padding: 9px 10px;

        &:first-child {
            border-top-right-radius: $radius-sm;
            border-top-left-radius: $radius-sm;
        }
    }
}