.font-size-lg {
    font-size: $font-size-lg;
}

.font-size-sm {
    font-size: $font-size-sm;
}

.font-huge {
    font-size: $font-size-huge;
    font-weight: $font-weight-bold;
}