.pi-device-map {
  &-content {
    height: calc(100% - 65px) !important;
  }
  .gmnoprint {
    & > div {
      -webkit-border-radius: 5px !important;
      -moz-border-radius: 5px !important;
      border-radius: 5px !important;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .1);
      .gm-control-active {
        img {
          width: 12px !important;
          height: 12px !important;
          opacity: .7;
        }
      }
    }
  }
}
.pi-device-details {
    .pi-device-map {
        &-content {
            height: 100% !important;
        }
    }
}

.pi-map-actions {
    display: flex;

    .pi-btn {
        &.pi-icon i {
            font-size: 18px;
        }
    }
}

.map-marker-mode-button {
    &.device-marker {
        display: flex;
        align-items: center;
        span {
            margin-left: 8px;
            font-size: 12px;
        }
        img {
            width: 12px;
        }
    }
    &.mode-marker {
        margin-top: 10px;
        margin-left: 10px;
        padding: 0 10px;
    }
    &.reset-marker {
        display: flex;
        align-items: center;
        span {
            margin-left: 8px;
        }
    }
}
.reset-marker-block {
    bottom: 13px !important;
    right: 180px !important;
}

.pi-map-button {
    &.pi-bottom-right {
        position: absolute;
        bottom: 25px;
        right: 0;
    }

    &.pi-top-right {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &.pi-top-left {
        position: absolute;
        top: 10px;

        &.pi-order-first {
            left: 10px;
        }

        &.pi-order-second {
            left: 65px;
        }
    }
}