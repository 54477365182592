@mixin font-face($family-folder, $font-name, $font-face-name, $font-style, $font-weight, $woff2: false) {
    $file-path: $fonts-folder + '/' + $family-folder + '/' + $font-name;
    @font-face {
        font-family: $font-face-name;
        font-style: $font-style;
        font-weight: $font-weight;
        @if($woff2) {
            unicode-range: U+000-5FF;
            src: local($font-face-name),
            url($file-path + ".woff2") format('woff2'),
            url($file-path + ".woff") format('woff'),
            url($file-path + ".ttf") format('truetype'),
            url($file-path + ".eot") format('embedded-opentype');
        } @else {
            src: local($font-face-name),
            url($file-path + ".woff") format('woff'),
            url($file-path + ".ttf") format('truetype'),
            url($file-path + ".eot") format('embedded-opentype');
        }

    }
}

@mixin position($position: absolute, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin ellipsis($width) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin line-clamp($count) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// Alignment

@mixin YAlign {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin XAlign {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin XYAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Background image

@mixin bgImage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin transition($count) {
    -webkit-transition: $count;
    -moz-transition: $count;
    -ms-transition: $count;
    -o-transition: $count;
    transition: $count;
}

@mixin transform($count) {
    -webkit-transform: $count;
    -moz-transform: $count;
    -ms-transform: $count;
    -o-transform: $count;
    transform: $count;
}

@mixin close-button($top, $right, $size) {
    border: 0;
    top: $top;
    right: $right;
    position: absolute;
    color: rgba($color-text, 0.7);
    background-color: transparent;
    @include transition(all 0.2s);

    &:hover {
        color: $color-text;
    }

    i {
        font-size: $size;
    }
}

@mixin table() {
    &.pi-list-resp {
        padding-left: 0;
        padding-right: 0;

        thead {
            display: none;
        }

        tbody {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            tr {
                display: block;
                overflow: hidden;
                padding-top: 10px;
                position: relative;
                margin-bottom: 10px;
                padding-bottom: 10px;
                background-color: #fff;
                border-radius: $radius-md;
                border: 1px solid $color-border;
                box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.04);

                td {
                    border: 0;
                    width: 100%;
                    display: block;

                    &.pi-type-icon-btns {
                        position: absolute;
                        top: 14px;
                        right: 0;
                        width: 45px;
                        background-color: transparent;

                        .pi-td {
                            padding-left: 0;
                            padding-right: 0;
                            ul {
                                li {
                                    &:last-child {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .pi-icon-btn {
                            padding-left: 10px;
                            padding-right: 10px;
                            i {
                                font-size: 20px;
                            }
                        }
                    }
                    &.pi-type-action-btn {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        width: 24px;
                        background-color: transparent;
                        .pi-td {
                            padding: 0;
                            button {
                                width: 22px;
                                height: 22px;
                                background: transparent;
                                padding: 0;
                                overflow: hidden;
                                i {
                                    color: $color-text;
                                }
                            }
                        }
                    }

                    .pi-td {
                        padding-top: 3px;
                        padding-bottom: 3px;

                        p {
                            font-weight: 500;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@mixin table-actions() {
    .pi-actions-top {
        position: absolute !important;
        top: 0;
        right: 0;
        width: 41px !important;

        .pi-td {
            padding-top: 0;
            padding-left: 0;

            .pi-menu-expandable {
                margin: 0 !important;
            }
        }

        .pi-btn {
            display: none;
        }

        .pi-action-menu {
            width: inherit;
            padding: 3px 5px;
            box-shadow: none;

            h5 {
                display: none;
            }

            ul {
                display: flex;

                li {
                    padding: 0;

                    a {
                        padding: 6px;

                        i {
                            margin-right: 0;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            &:not(.pi-expanded) {
                display: block;
            }

            &.pi-position-right {
                right: 5px;
                top: 5px;
            }
        }
    }

    .pi-actions-bottom {
        .pi-td {
            & > ul {
                width: 100%;

                li {
                    width: 100%;
                }
            }
        }
        .pi-btn {
            display: none;
        }

        .pi-menu-expandable.mr-10 {
            margin-right: 0;
        }

        .pi-action-menu {
            padding: 3px 15px;
            box-shadow: none;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            border: 0;
            width: inherit;
            justify-content: space-between;

            h5 {
                display: none;
                margin-bottom: 0;
            }

            ul {
                display: flex;

                li {
                    padding: 0;

                    a {
                        padding: 6px;

                        i {
                            margin-right: 0;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            &:not(.pi-expanded) {
                display: flex;
            }
        }
    }
}

@mixin signal-icon($index, $color) {
    span {
        &.path#{$index} {
            &::before {
                color: $color !important;
            }
        }
    }
}