// React strap modal styles

.pi-modal {
    &.pi-grey-bg {
        .modal-content {
            background-color: $color-bg;
        }
    }
    
    .pi-modal-header {
        position: relative;
        padding: 25px 30px;

        h4 {
            display: flex;
            font-weight: 500;
            margin-bottom: 0;
            color: $color-text;
            align-items: center;
            text-transform: uppercase;

            .pi-back-btn {
                border: 0;
                padding: 0;
                font-size: 24px;
                margin-right: 12px;
                color: $color-text;
                background-color: transparent;

                i {
                    @include transform(rotate(90deg));
                }
            }
            span {
                b {
                    color: rgba($color-text, .7);
                }
            }
        }

        .pi-close {
            @include close-button(24px, 24px, 24px);
        }
    }

    .pi-modal-body {
        color: $text-color;
        &:not(.no-padding) {
            padding: 0 20px;
        }
    }

    .pi-modal-footer {
        display: flex;
        background-color: #fff;
        justify-content: flex-end;
        padding: 20px 32px 32px 32px;
        border-top: 1px solid $color-border;
        border-bottom-left-radius: $radius-md;
        border-bottom-right-radius: $radius-md;

        .pi-btn:first-of-type {
            margin-right: 8px;
        }

        &:not(.pi-no-space) {
            margin-top: 40px;
        }
    }

    &.pi-two-column-form {
        .pi-columns {
            display: flex;
            justify-content: space-between;
        }

        .pi-column {
            width: calc(50% - 10px);
        }

        .pi-row {
            width: 100%;
        }
    }

    &.pi-add-device-modal {
        .modal-content {
            overflow: visible;
        }
        .pi-select {
            position: relative;
            .pi-error-message {
                bottom: -19px;
                font-size: $xs;
                margin-bottom: 0;
                color: $color-red !important;
                position: absolute;
            }
        }
    }

    .pi-modal-loader {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(#000, 0.3);
    }
}