// This styles should be removed after team page redesign
@media (min-width: 661px) {
    .dash_graph_index_teams {
        .pi-page-title {
            display: none;
        }
    }
}

@media (max-width: 660px) {
    .dash_graph_index_teams {
        display: block;
    }
}

@media only screen and (max-width: 636px) {
    .teams_mobileView {
        tr {
            td {
                &:first-child {
                    padding-right: 20px;
                }
            }
        }
        .pi-detail-item {
            &.pi-center strong {
                justify-content: flex-start;
            }
        }
    }
}