.pi-roles-table-wrapper {
	overflow-x: auto;
	margin: 15px auto;
	width: calc(100% - 24px);
	border-radius: $radius-md;
	border: 1px solid $color-border;
}

.pi-roles-table {
	width: 100%;
	border-spacing: 0 0;
	border-collapse: separate;

	tbody {
		tr {
			&:not(:last-child) {
				td {
					border-bottom: 1px solid $color-border;
				}
			}

			&:last-child {
				td {
					&:first-child {
						border-bottom-left-radius: 8px;
					}

					&:last-child {
						border-bottom-right-radius: 8px;
					}
				}
			}
		}
	}

	th, td {
		background-color: #fff;

		&:not(:last-child) {
			border-right: 1px solid $color-border;
		}

		&:not(:first-child) {
			width: 100%;
			min-width: 170px;
		}

		&:first-child {
			left: 0;
			z-index: 1;
			width: 100%;
			min-width: 300px;
			position: sticky;
		}
	}

	th {
		font-weight: 600;
		padding: 19px 25px;
		color: rgba($color-text, 0.7);
		border-bottom: 1px solid $color-border;

		&:first-child {
			border-top-left-radius: 8px;
		}

		&:last-child {
			border-top-right-radius: 8px;
		}

		&:not(:first-child) {
			text-align: center;
		}

		button {
			border: 0;
			padding: 0;
			cursor: pointer;
			font-weight: 600;
			@include transition(all 0.2s);
			color: rgba($color-text, 0.7);
			background-color: transparent;

			&:hover {
				opacity: 0.9;
			}
		}

		span {
			text-decoration: underline;
		}

		i {
			position: relative;
			top: 1px;
			padding-left: 5px;
			display: inline-block;
		}
	}

	td {
		padding: 10px 15px;

		&:first-child {
			text-align: left;
		}

		p {
			margin-bottom: 0;
			position: relative;
			padding-left: 10px;
			display: inline-block;

			&::before {
				content: ' ';
				width: 4px;
				height: 4px;
				display: block;
				position: absolute;
				left: 0;
				top: 8px;
				border-radius: 100%;
				background-color: $color-text;
			}
		}

		.icon-help {
			position: relative;
			top: 1px;
			padding-left: 5px;
			display: inline-block;
		}

		.pi-icon {
			width: 22px;
			height: 22px;
			margin: auto;
			color: #fff;
			display: block;
			font-size: 17px;
			line-height: 22px;
			position: relative;
			border-radius: 100%;

			&.pi-icon-minus {
				background-color: #727a86;

				&::before {
					top: 50%;
					left: 50%;
					width: 12px;
					content: ' ';
					display: block;
					position: absolute;
					height: 2px;
					background-color: #fff;
					@include transform(translate(-50%, -50%));
				}
			}

			&.icon-check {
				background-color: $color-green;
			}
		}
	}
}