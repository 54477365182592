// Team page container heading

@media (max-width: 1600px) {
  .pi-content-heading {
    padding-right: 20px;

    .pi-details {
      .pi-back-btn {
        font-size: 18px;
      }

      h2 {
        font-size: 18px;
      }

      .pi-team-stats {
        li {
          padding: 0 16px;
        }
      }
    }
  }
}

@media (min-width: 1201px) {
  .pi-state-team {
    display: flex;
    flex-direction: row;
  }
  .pi-state-team .pi-content-heading {
    .pi-back-btn {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .pi-content-heading {
    .pi-details {
      flex-wrap: wrap;

      .pi-title-area {
        order: 1;
      }

      .pi-actions {
        order: 2;
      }

      .pi-team-stats {
        order: 3;
        width: 100%;
        padding-left: 0;
        margin-top: 15px;

        li:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .pi-content-heading {
    .pi-details {
      .pi-title-area {
        h2 {
          font-size: 16px;
        }
      }

      .pi-team-stats {
        display: block;

        li {
          padding: 0;
          margin-top: 5px;

          &:not(:first-child) {
            border-left: 0;
          }

          .icon-account {
            display: none;
          }

          .pi-info-string-text {
            display: block;

            .pi-info-label {
              padding-right: 8px;
            }
          }
        }
      }

      .pi-add-group {
        display: none;
      }
    }
  }
}