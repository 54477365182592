// Teams page team list sidebar

.pi-teams-sidebar {
  width: 250px;
  overflow-y: auto;
  padding: 20px 10px;
  background-color: #fff;
  border-right: 1px solid $color-border;

  .pi-teams-list {
    .pi-team-card {
      //border-bottom: 1px solid $color-border;
    }
  }
}

.pi-teams-search {
  .pi-search-bar {
    display: flex;
    align-items: center;
    padding: 16px 12px 10px;
    justify-content: space-between;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;

    .pi-text-field {
      width: 100%;
      max-width: calc(100% - 50px);
    }
  }
}

.pi-search-mode {
  .pi-teams-sidebar {
    width: 100%;
    height: 100%;
    border-right: 0;
    display: block !important;
  }
}

