// Add new workzone flow responsiveness

@media (min-width: 1001px) {
    .pi-add-workzone-table, .pi-assign-device-table {
        overflow-y: auto;
        min-height: 300px;
        max-height: calc(100vh - 300px);

        .pi-cell-holder {
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .pi-add-workzone-table,
    .pi-assign-device-table,
    .pi-add-location-table,
    .pi-subgroup-location-table,
    .pi-subgroup-members-table,
    .pi-add-member-table,
    .pi-members-table {
        &.pi-table {
            @include table();

            tr {
                width: calc(50% - 5px);

                .pi-type-text-icon .pi-td strong {
                    width: inherit;
                }
            }
        }

        .modal-content {
            padding-bottom: 70px;
        }
    }

    .pi-add-workzone-modal {

        .modal-content {
            padding-bottom: 80px;
        }

        .pi-side-panel {
            position: fixed;
            max-width: 100%;
            padding-top: 30px;

            .pi-close {
                display: block;
            }
        }
    }
}

@media (max-width: 800px) {
    .pi-add-location-table {
        height: calc(var(--app-height) - 230px);
        max-height: calc(var(--app-height) - 230px);
    }
}

@media (max-width: 700px) {
    .pi-new-workzone {
        .pi-content-wrapper {
            justify-content: center;

            .pi-form-side {
                border-right: 0;

                .pi-set-btn {
                    display: none;
                }
            }

            .pi-map-side {
                display: none;
            }
        }
    }
}

@media (max-width: 700px) {
    .pi-add-workzone-table,
    .pi-assign-device-table,
    .pi-add-location-table,
    .pi-add-member-table,
    .pi-subgroup-location-table,
    .pi-subgroup-members-table,
    .pi-members-table {
        &.pi-table {
            &.pi-list-resp {
                tbody {
                    tr {
                        width: 100%;
                    }
                }
            }
        }
    }

    .pi-new-workzone {
        form {
            height: 100%;
        }
        .pi-content-wrapper {
            overflow-y: auto;
            position: relative;
            height: calc(100% - 84px - 94px);
        }

        .pi-btn-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            text-align: center;
            background-color: #fff;
            padding: 20px 32px 32px 32px;
            border-top: 1px solid $color-border;
        }
    }
}