@media (max-width: 1660px) and (min-width: 1201px) {
    .pi-filter-bar {
        &-item {
            padding: 0;
            .pi-filter-date {
                display: none;
                position: relative;
                &.show {
                    display: block;
                    position: absolute;
                    top: 53px;
                    left: 0;
                    background-color: #fff;
                    width: 270px;
                    padding: 20px;
                    z-index: 3;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
                    .pi-date-picker {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
            .pi-filter-custom {
                display: block;
            }
            &:after {
                display: none;
            }
        }
    }
}
@media (max-width: 1200px) {
    .pi-filter-bar {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100%;
        border-radius: 0;
        z-index: 3;
        background: #fff;
        width: 100%;
        left: 0;
        padding: 0;
        overflow: auto;
        &-top {
            display: flex;
        }
        &-bottom {
            display: flex;
        }
        &-content {
            padding: 8px 24px 32px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: calc(100% - 180px);
            &-left {
                display: block;
                width: 100%;
            }
            &-right {
                padding-top: 24px;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
                .pi-group-row {
                    display: block;
                    label {
                        margin-bottom: 16px;
                    }
                }
            }
        }
        &-item {
            padding: 24px 0;
            border-bottom: 1px solid #E4E4E4;
            .pi-radio-group {
                flex-wrap: wrap;
            }
            .pi-filter-custom {
                display: none;
            }
            .pi-filter-date {
                display: block;
                .pi-date-picker {
                    margin-right: 0;
                    max-width: 240px;
                    &:not(:last-of-type) {
                        margin-bottom: 16px;
                    }
                }
            }
            &:first-child {
                padding-bottom: 16px;
            }
            &:after {
                display: none;
            }
        }
        &.show {
            display: block;
        }
    }
    .pi-activity-wrapper {
        .pi-filter-bar {
            &-content {
                &-left {
                    display: flex;
                    padding-top: 30px;
                    .pi-radio-group {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}