@media (max-width: 1200px) {
    .pi-locations-wrapper {
        .pi-page-body {
            height: calc(100% - 170px);
            .pi-table {
                height: calc(100% - 130px);
            }
        }
        .pi-statistics, .pi-table {
            padding-left: 0;
            padding-right: 0;
        }

        .pi-device-details {
            max-width: 100%;

            .pi-filter-block .pi-block-body .pi-group-row .pi-custom-column {
                width: 100%;
                padding-left: 0;
                margin-top: 20px;
            }
        }
    }
}

@media (max-width: 991px) {
    .pi-locations-wrapper {
        .pi-device-details {
            max-width: 100%;
        }
    }
}

@media (max-width: 800px) {
    .pi-locations-wrapper {
        .pi-device-details {
            .pi-device-info {
                padding: 10px;
            }

            .pi-details-body {
                border: 0;
                border-radius: 0;
                border-top: 1px solid $color-border;

                .pi-image {
                    border: 0;

                    img {
                        padding: 15px;
                        border-radius: $radius-md;
                        border: 1px solid $color-border;
                        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
                    }
                }

                .pi-btn {
                    width: 100%;
                    justify-content: center;
                }

                .pi-menu-expandable {
                    top: 10px;
                    right: 10px;

                    /*.pi-btn {
                        display: none;
                    }*/

                    /*.pi-action-menu {
                        top: 5px;
                        border: 0;
                        padding: 0;
                        width: auto;
                        display: block;
                        box-shadow: none;
                        position: relative;

                        h5 {
                            display: none;
                        }

                        ul {
                            padding: 5px;
                            display: flex;

                            li {
                                padding: 0;

                                &:last-child {
                                    margin-left: 8px;
                                }

                                a {
                                    width: 41px;
                                    height: 41px;
                                    display: flex;
                                    line-height: 39px;
                                    text-align: center;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: $radius-sm;
                                    border: 1px solid $color-border;
                                }

                                i {
                                    margin-right: 0;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }
                    }*/


                }

                .pi-details-overview {
                    flex-wrap: wrap;
                    position: relative;

                    .pi-details {
                        padding-left: 10px;
                        padding-right: 0;
                        position: initial;

                        .pi-details-list {
                            li {
                                flex-wrap: wrap;
                                border-bottom: 0;
                                padding-top: 0;
                                padding-bottom: 0;

                                .pi-detail-item {
                                    width: 100%;
                                    display: flex;
                                    padding: 5px 0;
                                    flex-wrap: wrap;
                                    max-width: 100%;
                                    align-items: center;

                                    &::before {
                                        display: none;
                                    }

                                    p, strong {
                                        font-size: 14px;
                                        margin-bottom: 0;
                                    }

                                    p {
                                        padding-right: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pi-info-tabs {
                padding: 10px;
                background-color: $color-bg;

                .pi-tab-navigation {
                    padding: 0;
                }
            }

            .pi-team-overview {
                flex-wrap: wrap;

                .pi-details-label {
                    max-width: 100%;
                    border-right: 0;
                    padding: 32px 20px 18px;
                }

                .pi-details-part {
                    padding-top: 0;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column-reverse;

                    .pi-details-group {
                        margin-top: 0;
                    }

                    .pi-details-info {
                        flex-wrap: wrap;
                        margin-top: 25px;

                        p {
                            max-width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    .pi-device-details {
        .pi-device-orientation {
            flex-wrap: wrap;
            padding: 32px 20px 20px;

            h3 {
                padding: 0;
                width: 100%;
                margin-bottom: 25px;
            }

            ul {
                li {
                    &:not(.pi-active) {
                        display: none;
                    }
                }
            }
        }

        .pi-device-sensitivity {
            padding: 41px 20px 20px;

            .pi-rc-slider {
                .pi-slider-header {
                    flex-wrap: wrap;

                    h3 {
                        width: 100%;
                        margin-top: 0;
                        margin-bottom: 25px;
                    }

                    .pi-slider-settings {
                        flex-wrap: wrap;
                        max-width: 265px;
                    }

                    .pi-btn-wrapper {
                        width: 100%;
                        margin-top: 10px;
                        position: relative;
                    }
                }

                .pi-slider-inner {
                    width: 100%;
                    flex-wrap: wrap;
                    flex-direction: row;
                    padding: 15px 20px 0;
                    justify-content: space-between;

                    .rc-slider {
                        order: 1;
                        margin: 0;
                        max-width: 100%;
                    }

                    .pi-high-value {
                        order: 2;
                        margin-top: 15px;
                    }

                    .pi-low-value {
                        order: 3;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .pi-locations-wrapper {
        .pi-device-details {
            .pi-details-body {
                .pi-details-overview {
                    .pi-details {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .pi-edit-location-wrapper {
        flex-wrap: wrap;

        .pi-image-wrapper {
            margin: 0 auto 25px;

            .pi-action-btns {
                width: 100%;
                right: 0;
                bottom: -55px;

                li {
                    .pi-icon-btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: $color-text;
                        }
                    }
                }
            }
        }

        .pi-edit-form {
            padding: 0;
            max-width: 500px;
            margin: 55px auto 0;
        }

        .pi-modal-footer {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .pi-history-search {
        padding: 25px 15px 10px;
    }

    .pi-history-logs {
        padding-left: 0;

        .pi-collapse {
            &::before, &::after {
                display: none;
            }

            .pi-collapse-header {
                &::before, &::after {
                    display: none;
                }

                .pi-logs-header {
                    flex-wrap: wrap;
                    padding: 11px 5px;
                    justify-content: space-between;

                    &::before, &::after {
                        display: none;
                    }

                    .pi-border-left {
                        padding-left: 5px;
                        padding-right: 5px;

                        &::before, &::after {
                            display: none;
                        }

                        &.pi-username {
                            width: 100%;
                        }

                        .pi-info-tag {
                            font-size: 12px;
                        }
                    }

                    .pi-datetime {
                        display: flex;
                        align-items: center;

                        strong {
                            font-size: 14px;
                            padding-left: 5px;
                        }
                    }

                    .pi-user-avatar {
                        margin-top: 15px;

                        span {
                            width: 24px;
                            height: 24px;
                            font-size: 10px;
                            line-height: 24px;
                        }

                        strong {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .pi-logs-body {
            padding-left: 10px;
            padding-right: 10px;

            .pi-log-title {
                h5 {
                    font-size: 12px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            .pi-log-info-list {
                .pi-info-string {
                    flex-wrap: wrap;
                    padding-left: 30px;
                    position: relative;

                    i {
                        left: 0;
                        top: 3px;
                        font-size: 18px;
                        position: absolute;
                    }

                    .pi-info-label, .pi-info-text {
                        width: 100%;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (min-width: 801px) {
    .pi-edit-location-wrapper {
        .pi-image-wrapper {
            .pi-action-btns {
                span {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 374px) {
    .pi-history-logs .pi-collapse .pi-collapse-header .pi-logs-header .pi-datetime {
        width: 100%;
        margin-bottom: 5px;
    }
}