// Global tags and content elements

body {
    font-family: $font-family-base;
}

// Block headings
.pi-block-heading {
    font-weight: 500;
    color: $color-text;
    text-transform: uppercase;
}

// Block body wrapper
.pi-page-body {
    padding-left: 20px;
    padding-right: 20px;
}

// Width modifications
.pi-full {
    width: 100%;
}

// Overlay styles
.pi-page-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: rgba(60, 65, 69, 0.3);
}

// Empty state of components
.pi-empty-state {
    font-size: $sm;
    font-weight: 500;
    margin-bottom: 0;
    padding: 25px 15px;
    color: rgba($color-text, 0.7);
}

// Old styles normalizations
.pi-wrapper {
    position: relative;

    .content {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

// Flexbox
.pi-flex {
    display: flex;
}

.pi-items-center {
    align-items: center;
}

.pi-justify-between {
    justify-content: space-between;
}

.pi-rotate-90 {
    transform: rotate(90deg);
}

// Dividers
.pi-dashed {
    border-top-style: dashed;
}
