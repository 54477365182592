@media (max-width: 1200px) {
    .pi-devices-details {
        max-width: 100%;
    }
    .pi-wz-devices {
        .pi-page-body {
            height: calc(100% - 150px);
        }
    }
    .pi-devices-details-workzone {
        max-width: 100%;
    }
}
@media (max-width: 992px) {
    .pi-devices-details{
        .pi-device-info {
            .pi-details-body {
                .pi-details-settings {
                    &-switch {
                        display: block;
                        .toggle-switch {
                            padding: 0;
                            &:not(:last-of-type) {
                                margin-bottom: 10px;
                            }
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .pi-devices-details {
        .pi-details-body {
            .pi-details {
                .pi-details-list {
                    li {
                        display: block;
                        border: none !important;
                        padding: 0;
                        .pi-detail-item {
                            display: flex;
                            align-items: center;
                            padding: 0;
                            margin-bottom: 10px;
                            max-width: 100% !important;
                            &:before {
                                display: none;
                            }
                            p {
                                margin-bottom: 0;
                                margin-right: 10px;
                            }
                        }
                        .pi-device-info-switch {
                            margin: 0;
                            max-width: 165px;
                        }
                    }
                }
            }
            .pi-details-settings-switch {
                display: block;
                .toggle-switch {
                    padding: 0;
                    &:not(:last-of-type) {
                       margin-bottom: 10px;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .pi-devices-details-workzone {
        .pi-device-info-top {
            .flex {
                display: block;
            }
            .pi-device-info-switch {
                margin-right: 0;
                margin-bottom: 15px;
            }
            p {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
        }
        .pi-info-tabs {
            .tab-content {
                .pi-information-team {
                    display: block;
                    &-title {
                        margin-left: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
@media (max-width: 580px) {
    .pi-devices-details {
        .pi-device-info {
            .pi-details-body {
                .pi-details-overview {
                    display: block;
                }
                .pi-image {
                    max-width: 100%;
                    border-right: none;
                    border-bottom: 1px solid $color-border;
                }
                .pi-details {
                    max-width: 100%;
                    padding: 30px 15px;
                }
                .pi-details-settings {
                    display: block;
                    &-switch {
                        margin-bottom: 10px;
                    }
                }
                .pi-details-scheduleMode {
                    &-body {
                        .pi-details-scheduleMode-edited {
                            display: block;
                            .pi-info-string {
                                padding: 0;
                                margin-bottom: 10px;
                                &:before {
                                    display: none;
                                }
                            }
                            .pi-details-scheduleMode-weekdays {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .pi-devices-workzone-details-info {
            .pi-devices-workzone-details-top {
                .pi-information-team-title {
                    margin-right: 15px;
                    p {
                        line-height: 1.5rem;
                        button {
                            text-align: left;
                        }
                    }
                }
            }
            .pi-devices-workzone-details-bottom {
                display: block;
                .pi-detail-item {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    max-width: 100%;
                    p {
                        margin-right: 15px;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .pi-devices-details-workzone,
    .pi-devices-details {
        .pi-rc-slider {
            .pi-slider-header {
                display: block;
                h3 {
                    margin-bottom: 20px;
                    margin-right: 0;
                }
                .pi-slider-settings {
                    max-width: 100%;
                }
            }
        }
    }
}
@media (max-width: 385px) {
    .pi-devices-details-workzone,
    .pi-devices-details {
        .pi-rc-slider {
            .pi-slider-header {
                .pi-text-field {
                    font-size: 20px;
                }
                .pi-btn-wrapper {
                    top: 2px;
                }
            }
        }
    }
}