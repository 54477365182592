//device event page
.device-ticket-description {
  margin-right: auto;
  margin-left: auto;

  .list-left,
  .list-right {
    li {
      align-items: center;
      margin-bottom: 5px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list-left {
    margin-bottom: 0;

    li {
      span {
        max-width: 670px;

        &:first-child {
          min-width: 150px;
        }
      }
    }
  }

  .list-right {
    margin-bottom: 0;

    li {
      justify-content: flex-end;

      span {
        margin-right: 0;
      }
    }
  }

  .card {
    justify-content: space-between;
    flex-direction: row;
    border-radius: 16px;
    margin-bottom: 4px;
    padding: 10px 25px;
    margin-right: auto;
    margin-left: auto;
  }

  .file-upload {
    position: absolute;
    bottom: 2px;
    right: 15px;
  }

  .comment-list {
    margin: 0 auto;
    width: 75%;

    .card {
      flex-direction: column;
    }

    .comment-card-title {
      justify-content: space-between;
      display: flex;

      .created-time {
        color: rgba(#333333, 0.7);
      }
    }

    .details {
      color: rgba(#333333, 0.85);
      margin: 0 30px 5px;
      max-width: 910px;

      img {
        max-width: 140px;
        margin: 5px 25px;
        width: 100%;
      }

      &.hide {
        overflow: hidden;
        display: none;
      }
    }
  }
}

//comment styles
.comment-form {
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto 5px;
  display: flex;

  .comment-open {
    position: relative;
    margin-right: auto;
    margin-left: auto;
  }

  .block-with-border {
    border: 1px solid #ced4da;
    background: #ffffff;
    border-radius: 16px;
    height: 80px;

    .comment-field {
      border-radius: 16px;
      padding-top: 15px;
      height: 50px;
      resize: none;
      border: none;

      &.form-control:focus {
        box-shadow: none;
      }
    }
  }
}

.device-detail-container .table-hover tbody tr.expanding-row {
  background-color: rgba(0, 0, 0, 0.075);
}

.border-top-gray {
  border-top: 3px solid rgba(125, 125, 125, 0.07);
  padding-top: 25px;
}

.modified-date {
  color: rgba(51, 51, 51, 0.7);
  font-size: 12px;
}

.device-map {
  position: relative;
  margin: 0 -$content-padding;
  height: calc(100vh - 295px);
  min-height: 300px;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    margin: 0 -$spacer * 1.5;
  }
}

.info-sidebar-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 450px;
  background: #fff;
  box-shadow: 0 0px 10px 0px rgba($black, 0.25);
  z-index: 100;

  .info-sidebar {
    padding: 40px 60px;

    .img-wrap {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    h5,
    h4 {
      margin: 0;
    }

    ul {
      margin: 15px 5px;

      li {
        color: $gray-600;
        margin-bottom: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .title {
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 10px;

      &:after {
        content: '';
        width: calc(100% + 30px);
        position: absolute;
        bottom: 0;
        left: -15px;
        border-bottom: 1px solid #e5e9f2;
      }
    }

    .main-section {
      display: inline-block;
      margin-bottom: 45px;
    }

    .additional-info {
      display: inline-block;

      ul {
        li {
          font-size: 12px;
        }
      }
    }

    .battery-info {
      margin: 0 5px;

      p {
        font-size: 12px;
        color: $gray-600;
        margin-bottom: 10px;
      }

      span {
        color: $green-dark;
        font-size: 22px;
        font-family: $font-headlines;
      }
    }
  }
}

.device-map-small {
  width: 100%;
  height: 66vh;
  position: relative;

  .info-sidebar-wrap {
    width: 320px;

    .info-sidebar {
      padding: 30px 20px;
    }
  }
}

// Create new asset/workzone modal
.create-device-options {
  display: flex;
  min-height: 560px;
  align-items: center;
  justify-content: center;

  button {
    width: 265px;
    height: 265px;
    border: 1px solid $gray-300;
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    margin: 0 35px;

    svg {
      font-size: 55px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.create-device-options {
  min-height: 400px;

  button {
    width: 200px;
    height: 200px;
  }
}

// asset-modal
.asset-modal {
  .img-wrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 350px;
    height: 210px;
    background-color: #f0f0f0;
    margin: 15px 0 15px 15px;
  }
}
