// Simple text tag

.pi-info-tag {
    color: #fff;
    padding: 0 10px;
    font-weight: 500;
    display: inline-block;
    border-radius: $radius-sm;

    &.pi-size-md {
        height: 29px;
        font-size: 14px;
        line-height: 29px;
    }

    &.pi-size-sm {
        height: 21px;
        font-size: 12px;
        line-height: 21px;
    }

    &:not(.pi-opacity) {
        @each $key, $val in $button-colors {
            &.pi-#{$key} {
                color: #fff;
                background-color: $val;
            }
        }
    }

    &.pi-opacity {
        @each $key, $val in $button-colors {
            &.pi-#{$key} {
                color: $val;
                background-color: rgba($val, 0.1);
            }
        }
    }
}