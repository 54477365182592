.mainMemberTableWrapperReports {
  .dropdown-menu {
    min-width: 60px !important;
    width: 60px !important;
    .dropdown-item {
      padding: 0px !important;
      text-align: center;
    }
  }
}
.dash_view {
  color: #faa528;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.19px;
  margin-top: 47px;
  line-height: 14px;
}
.dash_welcome {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-top: 30px;
}
.dash_msg {
  text-align: center;
  font-weight: bold;
}
.dash_card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 17px;
  grid-gap: 7px;
}
.dash_card_height {
  height: 234px;
  text-align: center;
  margin-bottom: 0px !important;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}
.dash_card_height:nth-child(1) {
  margin-left: 6px;
}
.dash_card_height_ptag {
  color: #faa528;
  font-family: Roboto;
  font-size: 70px;
  font-weight: 100;
  letter-spacing: 1.09px;
  line-height: 82px;
  text-align: center;
  margin-top: 23px;
}
.dash_p_bold {
  color: #4b5258;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 19px;
  text-align: cente;
}
.dashboard_maps {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 80px;
}
.dash_card_height_map {
  height: 473px;
  margin: 13px 9px 3px 3px;
  border-radius: 8px;
}
.dash_card_height_map > .card-body {
  padding: 40px 0px;
}
.reports_table_style {
  margin-top: 8px;
  padding: 0 6px;
}
.dash_view > span {
  margin-right: 5px;
}
.sub_group_heading {
  background: #f7f7f7;
}
.sub_grp_title {
  margin-left: 6px;
  font-size: 20px;
  margin-top: 5px;
}
.sub_grp_edit {
  color: #ffffff;
  background-color: #32393e;
  height: 50px;
  padding-left: 20px;
  font-size: 20px;
  position: relative;
  margin-left: 15px;
  padding-top: 10px;
}
.pencil_svg_sub {
  width: 15px;
  height: 16px;
  position: absolute;
  left: 19%;
  top: 33%;
}
.tabs_comp {
  margin-left: 15px;
  display: grid;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  height: 60px;
  background-color: #f2f2f2;
  grid-template-columns: 15% 15% 15% 55%;
}
.members_tab {
  background-color: #ffffff;
  font-weight: bold;
}
.tabs_comp > p {
  margin-bottom: 0px;
  padding-top: 16px;
}
.report_border_sub {
  position: relative;
  display: grid;
  grid-template-columns: 21% 5% 9% 5% 37% 14%;
  grid-gap: 10px;
  background-color: #ffffff;
}
.search_icon_tabs {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 40%;
  left: 19%;
  font-weight: normal;
}
.teamlist_search_tabs {
  height: 40px;
  position: relative;
  padding-left: 16px;
  outline: none;
  width: 194px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.report_cardBody {
  padding: 13px !important;
}
.report_cardBody_div {
  padding: 1px !important;
}
.reports_per_page {
  margin-top: 4px;
}
.reports_per_page > p {
  margin-bottom: 0px;
  margin-top: 9px;
  margin-left: 8px;
  color: #4b5258;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
  text-align: center;
}
.download_report {
  box-sizing: border-box;
  height: 40px;
  min-height: 40px;
  width: 195px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  position: absolute;
  top: 12px;
  right: 0;
  margin-right: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.activity_popup_download_report {
  box-sizing: border-box;
  height: 40px;
  width: 167px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  position: absolute;
  top: 78px;
  left: 33%;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.activity_popup_download_report > p {
  color: #faa528;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 25px;
}
.download_report > p {
  color: #faa528;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 25px;
}
.mainMemberTableBodyReports {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
  text-align: center;
  height: 40px;
}
.mainMemberTableHeaderReports {
  color: #4b5258;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.19px;
  line-height: 14px;
  text-align: center;
  margin-top: 25px;
}
.mainMemberTableHeaderReports > tr > th {
  border: none;
}
.mainMemberTableBodyReports > tr > td {
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 16px;
  text-align: center;
}
.mainMemberTableBodyReports > tr:last-child > td {
  border-bottom: 1px solid #dee2e6;
}
.mainMemberTableBodyReports > tr:hover {
  background: #f6f6f6;
  font-weight: bold;
}
.mainMemberTableWrapperReports > div > div > .dropdown {
  position: absolute !important;
  top: 17px;
  box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-secondary:focus:not(:disabled):not(.disabled) {
  box-shadow: none;
}
.mainMemberTableWrapperReports > div > .dropdown > .btn:focus {
  box-shadow: none;
  outline: none;
  border: none;
}
.mainMemberTableWrapperReports > div > div > .dropdown > .dropdown-toggle:after {
  border-color: #faa528;
}
.mainMemberTableWrapperReports > div > div > .pagination {
  justify-content: center;
}
.reports_table_style > .card {
  border-radius: 8px !important;
}
.mainMemberTableWrapperReports > div > div > ul > .page-item.active .page-link {
  color: #faa528;
  background: none;
  border: none;
}
.mainMemberTableWrapperReports > div > div > ul > li:first-child > a,
.mainMemberTableWrapperReports > div > div > ul > li:last-child > a {
  color: #faa528 !important;
}

.mainMemberTableWrapperReports > div > div > ul > .page-item .page-link {
  background: none;
  border: none;
}
.page-link:focus {
  box-shadow: none;
}
.popup_date {
  color: #bababa;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 21px;
  margin-bottom: 7px;
}
.popup_msg {
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 21px;
  word-break: break-all;
}
.image_popup > p {
  text-align: center;
  margin-bottom: -10px;
  margin-top: 10px;
}
.close_activity_popup {
  cursor: pointer;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
}
.activity-detail {
  color: #4b5258;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 0px !important;
}
.reports_popUp_table {
  display: grid;
  justify-content: start;
  margin-top: 20px;
  margin-left: 57px;
}
.reports_popUp_table > table > tbody > tr > td:first-child {
  color: #4b5258;
  font-family: Roboto;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.22px;
  line-height: 25px;
  display: grid;
}
.reports_popUp_table > table > tbody > tr > td:nth-child(2) {
  color: #4b5258;
  font-family: Roboto;
  font-size: 14px;
  padding-left: 50px;
  width: 324px;
  letter-spacing: 0.22px;
  text-align: left;
}

.download_report_popup {
  box-sizing: border-box;
  height: 40px;
  width: 167px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  margin: 0;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  color: #faa528;
}
.MuiDialog-paperWidthSm {
  border-radius: 8px !important;
}
.popupMainLabel {
  font-size: 14px;
  font-weight: bold;
}
.popupSubLabel {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
#filterpopup {
  display: flex !important;
}
#filterpopup[data-show] {
  display: flex !important;
}
.applyFilterButton,
.applyFilterButton:hover {
  height: 40px;
  width: 127px;
  color: #ffffff !important;
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: #faa528 !important;
}
.fil_btn_teams_bg {
  background: #faa528;
  padding: 8px 5px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.fil_btn_teams {
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.settings_btn {
  padding: 8px 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.content1datetimeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content1datetime {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.content1datetimeinput,
.content1datetimeinput:focus {
  height: inherit;
  color: #bababa;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-right: 0 !important;
  box-shadow: none;
}
.inputAddon {
  color: #faa528;
  background-color: #ffffff;
  border-left-width: 0;
}

.carousel-list {
  margin-top: 5px;
  padding: 0 30px;

  .slick-prev,
  .slick-next {
    height: 20px;
    width: 20px;
    color: #faa528;

    &:hover {
      color: #faa528;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }

  .slick-slide {
    padding: 0 1px;

    img {
      width: 100%;
    }
  }

  .slick-disabled {
    display: none !important;
  }
}

.mobile_table_views {
  display: none !important;
}

.settings-element-check {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  display: inline-block;
}

.settings-check-element {
  height: 30px;
}

.settings-element-check-name {
  font-style: normal;
  font-weight: bold;
  width: 225px;
  height: 25px;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  margin: 5px 0px 0px 10px;
  display: inline-block;
  vertical-align: top;
}

.settings-popup-container {
  position: relative;
  padding: 0px 30px;
  background-color: #ffffff;
  z-index: 101;
  width: 299px;
  justify-content: space-evenly;
  border: 1px solid #e4e4e4;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.settings-popup-container:focus {
  outline: 0px;
}

.popup-label {
  font-size: 14px;
}

input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 7px;
  background: orange;
  top: 0.9ex;
  left: 0.5ex;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type='checkbox'] {
  line-height: 2.1ex;
}

input[type='radio'],
input[type='checkbox'] {
  position: absolute;
  left: -999em;
}

input[type='checkbox'] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[type='checkbox'] + label::before {
  content: '';
  display: inline-block;
  vertical-align: -25%;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 2px;
  margin-right: 13px;
}

input[type='checkbox']:checked + label::before {
  content: '';
  display: inline-block;
  vertical-align: -25%;
  height: 20px;
  width: 20px;
  background-color: orange;
  border: none;
  border-radius: 2px;
  margin-right: 13px;
}

.applySettingsButton,
.applySettingsButton:hover {
  height: 40px;
  width: 100%;
  color: #ffffff !important;
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: #faa528 !important;
  margin-top: 10px;
  margin-bottom: 30px;
}

.close-icon {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 2;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}

#header-fixed {
  position: fixed;
  top: 0px;
  display: none;
  background-color: white;
}

.reports_table_fixed_header > .react-bootstrap-table {
  height: calc(100vh - 483px);
  min-height: 576px;
  overflow: auto;
  position: relative;
}

.reports-table {
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
}

.react-bootstrap-table {
  flex-grow: 1;
}

.table-wrapper-reports-page {
  height: calc(100% - 65px);
}

.table table-bordered {
  position: relative;
}

.reports-header {
  position: sticky !important;
  top: 0;
  background: #ffffff;
  z-index: 101;
}

#reportsTable {
  position: relative;
}

.lap_view {
  flex-grow: 1;
}

.card {
  height: 100%;
}

.reports_header {
  display: flex;
  flex-direction: column;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
}

.react-bs-table-sizePerPage-dropdown {
  border: 1px solid #e4e4e4;
  right: 211px;
  border-radius: 4px;
  height: 40px;
  top: 12px !important;
}

.row-per-page {
  position: absolute;
  right: 285px;
  top: 20px;
}

#pageDropDown {
  height: 38px;
}

.pagination > li:first-child,
.pagination > li:last-child {
  width: 40px !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInputBase-root {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%);
}

.MuiInputBase-input {
  margin-left: 6px !important;
}

.MuiIconButton-label {
  color: #faa528;
}

.MuiFormLabel-root {
  font-size: 18px !important;
}

.filter-modal .modal-content {
  width: 66% !important;
}

.report-table-row > :nth-child(8) {
  cursor: pointer !important;
}

.report-table-row > :nth-child(9) {
  cursor: pointer !important;
}

.react-bootstrap-table::-webkit-scrollbar {
  width: 12px;
}
.react-bootstrap-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.react-bootstrap-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #d3d3d3;
}

@media only screen and (max-width: 636px) {
  #filterpopup {
    width: 95vw !important;
  }
  .MuiPickersBasePicker-pickerView {
    max-width: auto;
    min-width: 90vw !important;
  }
  .dash_card {
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 7px !important;
    margin-top: 0px !important;
  }

  .dash_card_height_ptag {
    padding-top: 0px !important;
  }
  .mobile_table_views {
    display: block !important;
  }
  .dash_card_height:nth-child(1) {
    margin-left: 0px !important;
  }

  .download_report {
    display: none !important;
  }

  .report_border_sub {
    display: grid;
    padding-top: 4%;
    padding-left: 5%;
    width: 86vw;
    grid-template-columns: 70% 15% 15% !important;
  }

  .reports_table_style {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .teamlist_search_tabs {
    width: 100% !important;
    font-family: 'Roboto';
    font-weight: bold;
  }

  .fil_btn_teams {
    width: 100% !important;
  }

  .settings_btn {
    width: 100% !important;
  }

  .mainMemberTableWrapperReports {
    margin-top: -16px !important;
  }

  .mobile-data {
    padding-bottom: 6%;
  }

  .assetname {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 10px;
  }

  .arrow_icon_mobile-data {
    float: right;
    padding-right: 10px;
    color: #c2bfbf;
    font-size: 14px;
  }

  .report_border {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .dash_card_height:nth-child(4) > div > p:nth-child(3) {
    margin-top: 20%;
  }

  .dash_view {
    display: none;
  }

  .asset-select {
    padding-top: 6%;
    padding-bottom: 6%;
    padding-left: 10px;
  }
  .asset-select_bg {
    padding-top: 6%;
    padding-bottom: 6%;
    padding-left: 10px;
    background: #f8f8f8;
  }

  .setasset {
    background: lightgray;
  }

  .removeasset {
    background: none;
  }

  .typo-image {
    width: 100%;
    margin: 10px;
  }

  .close-icon-div {
    position: relative;
    left: 22px;
    top: -10px;
  }

  .close-icon-div span {
    font-size: 21px;
    color: black;
    display: block;
    position: relative;
    top: 5px;
  }
  .mobile_toggle_data > div > p:nth-child(1) {
    color: #4b5258;
    font-family: Roboto;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.22px;
    margin: 0;
    line-height: 26px;
  }
  .mobile_toggle_data > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .mobile_toggle_data > div:last-child > p {
  }

  .close {
    cursor: pointer;
    font-size: 24px;
    transform: translate(0%, -50%);
  }

  .modal-dlg.makeStyles-paper-20 {
    border: none !important;
    outline: 0px;
  }

  .modal-dlg.makeStyles-paper-20:focus {
    border: none !important;
    outline: 0px;
  }

  .slider-index {
    margin: 10px;
    text-align: center;
    font-family: 'Roboto';
    font-weight: bold;
    position: relative;
    top: 30px;
  }

  .dash_graph_index {
    grid-template-columns: 100% !important;
  }
}
.titleAndDownload .react-bootstrap-table {
  display: none;
}

@media only screen and (max-width: 370px) {
  .dash_card_height:nth-child(3) > div > p:nth-child(3) {
    margin-top: 20%;
  }
}
