// Product signal icon states

.pi-signal-icon {
    font-size: 24px;

    .icon-signal {
        span {
            &::before {
                opacity: 1;
            }

            &:not(.active) {
                &::before {
                    color: rgb(226, 226, 226);
                }
            }
        }

        &.pi-active-1 {
            @include signal-icon(4, $color-signal-25);
        }

        &.pi-active-2 {
            @include signal-icon(4, $color-signal-50);
            @include signal-icon(3, $color-signal-50);
        }

        &.pi-active-3 {
            @include signal-icon(4, $color-signal-75);
            @include signal-icon(3, $color-signal-75);
            @include signal-icon(2, $color-signal-75);
        }

        &.pi-active-4 {
            @include signal-icon(4, $color-signal-100);
            @include signal-icon(3, $color-signal-100);
            @include signal-icon(2, $color-signal-100);
            @include signal-icon(1, $color-signal-100);
        }
    }
}