@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-colored {
  .modal-header,
  .modal-footer {
    border-color: rgba(255, 255, 255, 0.33)
  }
}

.modal {

	&-custom {

		.modal-header {
			padding: 1.5rem 1rem 1.5rem 3rem;
		}

		.modal-title {
			text-transform: uppercase;
			font-size: $h3-font-size;
		}

		.close {
			color: $gray-400;
			position: relative;
			width: 50px;
			height: 50px;
			text-align: center;

			span {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 40px;
    			font-weight: 400;
    			line-height: 50px;
			}
		}

		.modal-body {
			background: $body-bg;
			padding: 1.5rem 3rem;
		}

		.modal-footer {
		    border-top: none;
		    background: $body-bg;
		    padding: 0 1rem 1.5rem 3rem;
		}
		
		.description-field {

			textarea {
				height: 150px;
				resize: none;
			}
		}

		.info-list {

			li {
				color: $gray-700;
        		margin-bottom: 3px;

				&:last-child {
		          margin-bottom: 0;
		        }
			}
		}

	}
}

.modal {
	.modal-header {
		.close {
			padding: 1rem 1rem !important;
			margin: -1rem -1rem -1rem auto !important;
			position: static !important;
			font-size: 25px !important;
			color: #000 !important;
			transform: translate(0, 0) !important;
		}
	}
}