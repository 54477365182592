.pi-phone-number {
  @extend .pi-text-field;
}

.pi-phone-number-field.react-tel-input {
  display: flex;
  align-items: center;
  border-radius: 6px;

  .form-control {
    width: 100%;
    height: 100%;
    line-height: 39px;
    padding-left: 48px;
    border-color: $color-border;

    &:focus {
      box-shadow: none;
      border-color: $color-text !important;

      & + .flag-dropdown {
        border-color: $color-text;
        border-right-color: $color-border;
      }
    }
  }

  .flag-dropdown {
    border-color: $color-border;
    border-radius: 6px 0px 0px 6px !important;
  }
  .selected-flag,
  .flag-dropdown.selected-flag:hover {
    overflow: hidden;
    border-radius: 6px 0px 0px 6px !important;
  }

  .country-list {
    min-width: 249px;
    border-radius: 5px;
    border-color: $color-border;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}

