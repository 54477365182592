.pi-modal-header {
  background-color: $color-bg;
  border-bottom: 1px solid $color-border;
}

.pi-team-addNewLocation {
  width: 100%;
  padding: 32px 25px;
  position: relative;
  z-index: 3;
  border-top: 1px solid $color-border;
  display: flex;
  grid-column-gap: 17px;
  align-self: center;
  text-wrap: nowrap !important;

  &-upload-section {
    width: 240px;
  }
  &-content {
    //width: calc(100% - 240px);
    //max-width: 520px;
    & .pi-field-wrapper .pi-icon.icon-map {
      right: 15px;
      color: $color-secondary;
    }
  }

  & .pi-field-wrapper input,
  .pi-team-select input {
    height: 38px;
  }
  & .pi-field-label {
    margin-bottom: 0px;
  }

  .pi-text-field {
    margin-bottom: 0px !important;
    box-shadow: unset !important;
  }

  .pi-team-select {
    margin-bottom: 0px !important;
  }
  .pi-team-select-label {
    color: #4b5258;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    margin-bottom: 0px !important;
  }

  .pi-map-button {
    display: flex;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    height: 40px;
    padding: 0;
    border: none;
    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 100%;
      background-color: #fff;
      border-radius: 8px 0 0 8px;
      i {
        font-size: 25px;
        color: $color-secondary;
      }
    }
    &-right {
      width: calc(100% - 48px);
      height: 100%;
      background-size: cover;
      border-radius: 0 8px 8px 0;
      position: relative;
      overflow: hidden;
      img {
        width: 22px;
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.11);
        z-index: 1;
        left: 0;
        top: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .pi-modal-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    justify-content: unset !important;
  }
  .pi-team-addNewLocation {
    flex-direction: column;
    grid-row-gap: 17px;
    max-width: 520px;

    &-upload-section {
      width: 100%;
      min-height: 300px;
    }

    &-content {
      width: 100%;
      align-items: center;
      padding-bottom: 100px;
    }
  }

  label span,
  .pi-field-label,
  .pi-team-select-label {
    font-size: 13px !important;
  }
}

.pi-team-addNewLocation .react-select-container {
  box-shadow: none !important;
  border-radius: none !important;
  border: none !important;
  .react-select__indicators {
    display: none;
  }

  .react-select__control {
    height: 38px !important;
    min-height: 38px !important;
    box-shadow: none !important;
  }

  .react-select__value-container {
    padding: 0px;
  }

  .react-select__single-value {
    padding: 0px 12px;
  }
  .react-select__menu * {
    overflow-x: hidden !important;
  }

  input {
    height: 100%;
    padding: 0px 12px !important;
    width: 100% !important;
  }
}
