.container {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  gap: 20px;

  .row {
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    .items {
      min-width: 200px;
      flex: 1;
      margin-bottom: 8px;
    }
  }

  .types {
    position: relative;
    .typesTitle {
      display: block;
      font-weight: 500;
      margin-bottom: 3px;
      color: rgba(#354052, 0.8);
    }
    .typesList {
      display: flex;
      gap: 10px;
    }

    .error {
      font-size: 12px;
      margin-bottom: 0;
      color: #e90f0f;
      position: absolute;
      bottom: -19px;
    }
  }
}

.modal {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 700px;
  }
}
