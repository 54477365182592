@media (max-width: 1600px) {
  .pi-groups-tab {
    > ul {
      > li {
        width: 33.33%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .pi-teams-wrapper {
    display: block;

    .pi-team-content {
      max-width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .pi-groups-tab {
    > ul {
      > li {
        width: 50%;
      }
    }
  }

  .pi-group-members,
  pi-group-locations {
    .pi-type-custom-actions {
      display: flex !important;
      justify-content: flex-end;

      .pi-actions-bottom .pi-td > ul {
        width: 190px;
      }
    }
  }

  .pi-search-mode {
  }
}

@media (min-width: 1201px) {
  .pi-subgroup-members {
    .pi-team-members-table {
      .pi-show-resp {
        display: none;
      }
    }
  }

  .pi-team-tab-nav {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .pi-teams-wrapper .pi-table-wrapper {
    padding-top: 10px;
  }

  .pi-subgroup-members,
  .pi-subgroup-locations {
    .pi-team-members-table,
    .pi-team-locations-table {
      @include table();

      tbody {
        tr {
          overflow: visible !important;
          width: calc(50% - 5px);

          .pi-type-custom-actions {
            .pi-td {
              display: flex;
              justify-content: flex-end;

              .pi-hide-resp {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .pi-subgroup-wrapper {
    padding: 10px 20px;

    .pi-subgroup-header {
      flex-wrap: nowrap;
      &:after {
        top: 50%;
        right: 20px;
      }
    }

    .pi-subgroup-body {
      .pi-table-wrapper {
        padding: 15px 15px 0;
      }
    }

    .pi-collapse-header {
      position: relative;

      .pi-header-action {
        right: 25px;
      }
    }
  }
}

@media (max-width: 850px) {
  .pi-groups-tab {
    > ul {
      > li {
        width: 100%;
      }
    }
  }

  .pi-teams-wrapper {
    .pi-content-tabs {
      .pi-tab-navigation {
        padding: 0 20px;

        .pi-tab-nav-item {
          width: 33.33%;
        }
      }
    }

    .pi-search-bar {
      padding: 15px;

      .pi-btn {
        i {
          padding-right: 0;
        }

        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .pi-subgroup-members,
  .pi-subgroup-locations {
    .pi-team-members-table,
    .pi-team-locations-table {
      @include table();

      tbody {
        tr {
          width: 100%;
        }
      }
    }
  }

  .pi-teams-wrapper {
    .pi-team-content {
      overflow: auto;

      .pi-content-tabs {
        height: auto;
        overflow: hidden;
      }
    }
  }

  .pi-subgroup-wrapper {
    .pi-collapse-header {
      .pi-header-action {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
      }

      .pi-btn {
        display: none;
      }

      .pi-action-menu {
        padding: 3px 0;
        box-shadow: none;
        display: flex;
        align-items: center;
        position: relative;
        top: 0;
        border: 0;
        width: inherit;
        justify-content: space-between;

        h5 {
          display: none;
        }

        ul {
          display: flex;

          li {
            padding: 0;

            a {
              padding: 6px;

              i {
                margin-right: 0;
              }

              span {
                display: none;
              }
            }
          }
        }

        &:not(.pi-expanded) {
          display: flex;
        }
      }
    }
  }
}

