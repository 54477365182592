// Battery icon

.pi-battery-icon {
    width: 70px;
    height: 23px;
    padding: 2px;
    display: block;
    margin-right: 15px;
    position: relative;
    border-radius: $radius-xs;
    background-color: #EBEBEB;

    &::after {
        top: 50%;
        width: 5px;
        content: '';
        right: -4px;
        height: 15px;
        display: block;
        position: absolute;
        background: #EBEBEB;
        border-radius: 0 2px 2px 0;
        @include transform(translateY(-50%));
    }

    .pi-battery-bar {
        height: 19px;
        display: block;
        background: $color-green;
        border-radius: $radius-xs - 1px;
        max-width: 100%;
    }
}