.fl {
  &-body {
    background-color: #fff;
  }

  &-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 710px;
  }

  &-content {
    padding: 62px 0 40px;
  }

  &-headline {
    padding-top: 90px;
    background-color: $color-gray;
  }

  &-logo {
    max-width: 80px;
  }

  &-device-info {
    display: flex;
    position: relative;
    bottom: -38px;
    z-index: 1;
  }

  &-device-wrapper {
    width: 115px;
    height: 115px;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    background: #fff no-repeat center;
    background-size: contain;
  }

  &-device-title {
    font-weight: 500;
    padding-top: 35px;
    padding-left: 20px;

    h2 {
      color: #ffffff;

      span {
        font-size: $sm;
        padding-left: 4px;
        font-style: italic;
      }
    }
  }

  &-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .pi-text-field,
    .pi-select {
      margin-bottom: 32px;
      width: calc(50% - 8px);
    }
  }

  &-layout-full {
    width: 100%;
  }

  &-layout-distance {
    display: flex;
    justify-content: space-between;

    .pi-field {
      width: calc(100% - 105px);

      .pi-text-field {
        width: 100%;
      }
    }

    .pi-select {
      width: 90px;
    }
  }

  &-notifs {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .fl-width-50 {
      width: calc(50% - 8px);
    }

    li:not(.fl-width-50):not(:first-child) {
      padding-left: 12px;
    }

    &:not(.fl-no-border) {
      padding-bottom: 20px;
      border-bottom: 1px solid $color-border;
    }
  }

  &-action {
    width: 100%;
  }

  // Success view details
  &-success {
    &-view {
      padding: 24px 0;
      max-width: 500px;

      p {
        a {
          padding: 0 4px;
        }
      }
    }

    &-message {
      display: flex;
      padding: 24px 0;
      align-items: center;
      border-top: 1px solid $color-border;
      border-bottom: 1px solid $color-border;

      p {
        font-size: $md;
        font-weight: 400;
        margin-bottom: 0;
        color: rgba($color-text, 0.6);
      }
    }

    &-icon {
      padding: 0 20px;

      i {
        font-size: 44px;
        color: $color-green;
      }
    }
  }

  &-details-group {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid $color-border;

    .pi-detail-item {
      padding: 5px 0 !important;

      &::before {
        display: none;
      }
    }
  }

  &-column {
    &:first-of-type {
      padding-right: 30px;
    }
  }

  &-popover-container {
    position: relative;
    display: inline-block;

    label {
      display: flex;
      align-items: center;

      i {
        padding-left: 12px;
      }
    }

    .pi-align-custom {
      bottom: 0;
    }
  }

  &-info-text {
    display: flex;
    align-items: center;

    a {
      padding: 0 4px;
    }

    i {
      font-size: 18px;
      padding-right: 5px;
    }
  }
}

@media (max-width: 1366px) {
  .fl {
    &-content {
      padding: 45px 0;
    }

    &-headline {
      padding-top: 65px;
    }

    &-logo {
      max-width: 66px;
    }

    &-device-info {
      bottom: -25px;
    }

    &-device-wrapper {
      width: 104px;
      height: 104px;
    }

    &-device-title {
      font-size: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .fl {
    &-container {
      max-width: 340px;
    }

    &-device-title {
      padding-top: 20px;

      h2 span {
        display: block;
        padding-left: 0;
        padding-top: 4px;
      }
    }

    &-form {
      .pi-text-field {
        width: 100%;
      }
    }

    &-notifs {
      flex-wrap: wrap;

      li {
        width: 100%;

        &:not(.fl-width-50):not(:first-child) {
          padding-left: 0;
          padding-top: 12px;
        }
      }
    }

    &-action {
      .pi-btn {
        width: 100%;
      }
    }

    // Success view details
    &-success {
      &-view {
        max-width: 335px;
      }
    }

    &-details-group {
      flex-wrap: wrap;
    }

    &-column {
      width: 100%;

      &:first-of-type {
        padding-right: 0;
      }
    }

    &-popover-container {
      .pi-align-custom {
        bottom: 30px;
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .fl {
    &-popover-container {
      .pi-align-custom {
        right: -100% !important;
      }
    }
  }
}

