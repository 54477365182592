.pi-toggle-list {
	li {
		display: flex;
		margin-bottom: 5px;
		align-items: center;
		background-color: #fff;
		border-radius: $radius-md;
		justify-content: space-between;
		border: 1px solid $color-border;
	}

	.pi-toggle-area {
		width: 75px;

		.toggle-switch {
			justify-content: center;
		}

		.toggle-switch-title {
			display: none;
		}
	}

	.pi-toggle-title {
		padding: 11px 20px;
		width: calc(100% - 75px);
		border-right: 1px solid $color-border;

		span {
			font-weight: 500;
			color: $color-text;
		}
	}
}