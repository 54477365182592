// WorkZone: Map page filter

.pi-workzone-map-filter {
    .pi-filter-bar-group {
        display: flex;
        padding: 12px 20px;
        align-items: center;

        .pi-filter-bar-item {
            display: flex;
            align-items: center;
            padding-right: 20px;


            .pi-filter-name {
                font-size: $sm;
                font-weight: 500;
                padding-right: 15px;
                text-transform: uppercase;
                color: rgba($color-text, 0.6);
            }

            .pi-select {
                width: 167px;
            }

            .pi-radio-group {
                display: flex;
                align-items: center;

                li {
                    &:not(:first-child) {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}