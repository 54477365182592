@media (min-width: 1301px) {
    .pi-old-team {
        .mar-top-maps {
            height: calc(var(--app-height) - 65px) !important;
            min-height: inherit;
        }
    }
}
@media (max-width: 1300px) and (min-width: 1201px){
    .pi-old-team {
        .mar-top-maps {
            height: calc(var(--app-height) - 91px) !important;
            min-height: inherit;
        }
    }
}

@media (max-width: 1200px) {
    .pi-old-team {
        .pi-map-button {
            &.pi-bottom-right {
                right: 90px;
            }
        }
    }
}

@media (min-width: 660px) {
    .pi-old-team {
        .pi-page-title {
            display: none;
        }
    }
}

@media (max-width: 659px) {
    .pi-old-team {
        height: 100%;
        display: block;

        .mar-top-maps {
            height: calc(var(--app-height) - 72px) !important;
            min-height: inherit;
        }
    }
}