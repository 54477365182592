@media (min-width: 1201px) {
	.pi-roles-history-wrapper {
		.pi-filter-mobile-btn {
			display: none;
		}

		.pi-filter-bar-top, .pi-filter-bar-bottom {
			display: none;
		}

		.pi-roles-history-logs {
			height: calc(var(--app-height) - 150px - 78px);
		}
	}
}

@media (max-width: 1200px) {
	.pi-roles-history-wrapper {
		position: relative;
		overflow: hidden;

		.pi-filter-mobile-btn {
			display: block;
		}

		.pi-filter-bar {
			&.pi-open {
				display: block;
				position: fixed;
				height: 100%;
				width: 100%;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 4;
				overflow: auto;
				background-color: #fff;
			}

			&:not(.pi-open) {
				display: none;
			}

			.pi-radio-group {
				flex-wrap: wrap;
			}
		}

		.pi-roles-history-logs {
			padding-left: 10px;
			padding-right: 10px;
			height: calc(var(--app-height) - 175px);
		}
	}
}