// --------------------------------------------
// Settings for some of the most global styles.
// --------------------------------------------

// Typography
// Font, line-height, and color for body text, headings, and more.

// Font Family
$font-family-base: 'Roboto', sans-serif !default;
$font-family-icons: 'Pi-lit' !default;

// Font Sizes
$font-size-base: 16px !default;
$xs: 12px !default;
$sm: 14px !default;
$md: 16px !default;
$lg: 18px !default;
$xl: 20px !default;
$extra: 32px !default;

$text-font-sizes: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  extra: $extra,
) !default;

$heading-font-sizes: (
  h6: $xs,
  h5: $sm,
  h4: $md,
  h3: $lg,
  h2: $xl,
  h1: $extra,
) !default;

// Main Colors Plates
$color-primary: #2c7be5 !default;
$color-secondary: #faa528 !default;
$color-red: #e90f0f !default;
$color-green: #64c27d !default;
$color-purple: #8278f0 !default;
$color-disable: #ddd !default;
$color-border: #e4e4e4 !default;
$color-text: #354052 !default;
$color-bg: #f5f8fa !default;
$color-gray: #4a5257 !default;
$color-light-gray: #e4e7ea !default;
$border-light-gray: rgba(74, 82, 87, 0.3) !default;

// Status colors
$color-status-maintenance: $color-secondary !default;
$color-status-inspection: $color-red !default;
$color-status-online: $color-green !default;
$color-status-offline: $color-text !default;

// Statistic colors
$color-status-locations: rgba($color-primary, 0.2) !default;
$color-status-maintenance: rgba($color-secondary, 0.2) !default;
$color-status-inspection: rgba($color-red, 0.2) !default;
$color-status-online: rgba($color-green, 0.2) !default;
$color-status-offline: rgba($color-text, 0.2) !default;
$color-status-impacts: rgba($color-purple, 0.2) !default;

// Signal state colors
$color-signal-25: $color-red !default;
$color-signal-50: #e5ad1d !default;
$color-signal-75: #bfcc30 !default;
$color-signal-100: $color-green !default;

// Colors collected
$signal-colors: (
  4: $color-signal-25,
  3: $color-signal-50,
  2: $color-signal-75,
  1: $color-signal-100,
) !default;

// Text Color Plates
$color-text-50: rgba($color-text, 0.5) !default;
$color-text-70: rgba($color-text, 0.7) !default;

// Button filled and outline colors
$button-colors: (
  primary: $color-primary,
  red: $color-red,
  secondary: $color-secondary,
  gray: $color-text,
) !default;

// Button filled and outline colors
$typography-colors: (
  primary: $color-primary,
  secondary: $color-secondary,
  red: $color-red,
  green: $color-green,
  white: #fff,
  gray: #4a5257,
  purple: $color-purple,
  text: $color-text,
) !default;

// Unit-less line-height for use in components like buttons.
$line-height-base: 18px !default;
// Computed "line-height" (`font-size` * line-height`) for use with `margin, padding, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;

// Background color for <body>
$body-bg: $color-bg !default;
// Global text color on <body>.
$text-color: $color-text !default;

// Global textual link color.
$link-color: $color-primary !default;
$placeholder-color: #757575;
// Link hover color set via darken() function.
$link-hover-color: darken($link-color, 15%) !default;
// Link hover decoration.
$link-hover-decoration: underline !default;

// Border radius sizes
$radius-xs: 4px !default;
$radius-sm: 6px !default;
$radius-md: 8px !default;
$radius-circle: 100% !default;

// Font paths
$fonts-folder: '../../fonts';

