@media only screen and (min-width: 1201px) {
  .pi-bulk-select-resp {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .pi-users-table-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pi-roles-tab .pi-tab-navigation {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pi-create-user-role-wrapper .pi-create-role-content {
    padding-left: 0;
    padding-right: 0;
  }

  .pi-create-user-role-wrapper {
    .pi-header,
    .pi-create-role-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .pi-create-user-role-wrapper {
    .pi-header {
      flex-wrap: wrap;

      .pi-btn-wrapper {
        width: 100%;
        margin-top: 10px;
      }
    }

    .pi-create-role-container {
      //height: calc(var(--app-height) - 200px);
    }
  }

  .pi-role-accordions {
    ul {
      li {
        padding: 0 5px;

        i {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1001px) {
  .pi-roles-table-wrapper-resp {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .pi-roles-table-wrapper {
    display: none;
  }

  .pi-roles-table-wrapper-resp {
    overflow: auto;
    padding: 15px 10px;
    height: calc(100vh - 200px);

    .pi-collapse {
      margin-bottom: 15px;
      border-radius: $radius-sm;
      border: 1px solid $color-border;
      box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    }

    .pi-role-resp-header.pi-teamsGroups-logs-header {
      margin: 0;
      cursor: pointer;
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      background: rgba(53, 64, 82, 0.1);
      border-top-left-radius: $radius-sm;
      border-top-right-radius: $radius-sm;

      &::after {
        right: 10px;
      }
    }

    .pi-role-accordions {
      height: 100%;
      flex: 1;
      .pi-permission-name {
        margin-bottom: 0;
        font-weight: 500;
        color: rgba($color-text, 0.8);
      }

      ul {
        li:first-child {
          padding-right: 10px;
        }
      }
    }

    .pi-role-resp-item {
      padding: 0 15px 15px;

      ul {
        li {
          width: 100%;
          display: flex;
          padding: 8px 0;
          align-items: center;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            color: $color-text;
            position: relative;
            padding-left: 10px;
            padding-right: 15px;
            display: inline-block;
            width: calc(100% - 30px);

            &::before {
              content: ' ';
              width: 4px;
              height: 4px;
              display: block;
              position: absolute;
              left: 0;
              top: 8px;
              border-radius: 100%;
              background-color: $color-text;
            }
          }

          .icon-help {
            position: relative;
            top: 1px;
            padding-left: 5px;
            display: inline-block;
          }

          .pi-icon {
            width: 22px;
            color: #fff;
            height: 22px;
            margin: auto;
            display: block;
            font-size: 17px;
            line-height: 22px;
            position: relative;
            text-align: center;
            border-radius: 100%;

            &.pi-icon-minus {
              background-color: #727a86;

              &::before {
                top: 50%;
                left: 50%;
                width: 12px;
                content: ' ';
                display: block;
                position: absolute;
                height: 2px;
                background-color: #fff;
                @include transform(translate(-50%, -50%));
              }
            }

            &.icon-check {
              background-color: $color-green;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1020px) {
  .pi-users-table-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .pi-users-table-wrapper .pi-users-table {
    max-height: 1300px;
  }
}
