.headerTitle {
    font-size: 20px;
    font-weight: bold;
    color: #4B5258;
    text-align: center;
    margin-bottom: 41px;
}

.signInInputFields {
    box-sizing: border-box;
    height: 40px !important;
    width: 259px !important;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
}

.fieldLabel {
    color: #4B5258;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}

.forgotPasswordStyles{
    color: #4B5258 !important;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
}

.addButton,.addButton:hover,.addButton:focus,.addButton:active {
    border-radius: 4px;
    background-color: #FAA528 !important;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    height: 40px;
}

.accountYet{
  margin-top: 32px;
 color:#4B5258;
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 14px;
  text-align: center;
}

.accountYet {
	>span {
		color: #FAA528;
		font-weight: bold;
	}
}

.auth0-lock-submit{
    display: none !important;
}

.auth0-lock-widget {
    width: 380px !important;
}