body,
html,
#root {
  height: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

body {
  //overflow-y: auto;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}
