// Text, icons global classes for colors
@each $key, $val in $typography-colors {
    &.pi-color-#{$key} {
        color: $val;
    }
}

.pi-color-text-80 {
    color: rgba($color-text, 0.8);
}

.pi-color-text-50 {
    color: rgba($color-text, 0.5);
}

.pi-opacity-50 {
    opacity: 0.5;
}

.pi-bold-700 {
    font-weight: 700;
}
.pi-bold-500 {
    font-weight: 500;
}
.pi-bold-400 {
    font-weight: 400;
}

// Text transformations
.pi-uppercase {
    text-transform: uppercase;
}
.pi-capitalize {
    text-transform: capitalize;
}

// Title styling
.pi-block-title {
    font-weight: 500;
    color: $color-text;
    text-transform: uppercase;
}

// Heading font size styles
@each $key, $val in $heading-font-sizes {
    #{$key} {
        font-size: $val;
    }
}

// Text font size styles
@each $key, $val in $text-font-sizes {
    &.pi-font-size-#{$key} {
        font-size: $val;
    }
}

.pi-font-size-24 {
    font-size: 24px;
}

// Form components label
.pi-field-label {
    font-size: $sm;
    display: block;
    font-weight: 500;
    margin-bottom: 3px;
    color: rgba($color-text, 0.8);
}

// N/A (no value) text styles
.pi-no-value {
    opacity: 0.6;
    font-style: normal;
}

.pi-italic {
    font-style: italic;
}

// Text Alignments
.pi-text-center {
    text-align: center;
}

.pi-text-left {
    text-align: left;
}

.pi-text-right {
    text-align: right;
}

.pi-underline {
    text-decoration: underline;
}