// Material select

.pi-select {
    .react-select-container {
        .react-select__control {
            height: 41px;
            border-radius: $radius-sm;
            border-color: $color-border;

            &--is-focused {
                border-radius: $radius-sm;
                border-color: $color-text !important;
            }
        }
    }

    &.pi-shadow {
        .react-select-container {
            .react-select__control {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
            }
        }
    }

    .react-select__menu {
        z-index: 5;
    }

    &.pi-invalid {
        position: relative;

        .pi-error-message {
            bottom: -21px;
            font-size: $xs;
            margin-bottom: 0;
            color: $color-red;
            position: absolute;
        }
    }
}