.pi-activity-wrapper {
  .pi-device-details {
    max-width: 936px;
    .pi-device-info {
      background: $color-bg;
      padding: 28px 15px 24px;
      .pi-device-header {
        h2 {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-weight: 500;
          padding-right: 50px;
          button {
            &.pi-back-btn {
              border: 0;
              padding: 0;
              font-size: 24px;
              margin-right: 12px;
              color: $color-text;
              background-color: transparent;

              i {
                @include transform(rotate(90deg));
              }
            }
          }
        }
      }
      .pi-details-body {
        //.pi-image {
        //    display: flex;
        //    background-color: #fff;
        //    max-width: 210px;
        //}
        .pi-details {
          padding: 20px 15px 20px 30px;
          max-width: calc(100% - 210px);
          position: relative;
          .pi-details-list {
            .pi-detail-item {
              a {
                font-weight: bold;
                text-decoration: underline;
              }
            }
            .pi-view-location-button {
              max-width: 225px;
              width: 100%;
              position: absolute;
              right: 12px;
              top: 12px;
            }
            .pi-map-button {
              display: flex;
              box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
              border-radius: 8px;
              height: 62px;
              padding: 0;
              border: none;
              &-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 62px;
                height: 100%;
                background-color: #fff;
                border-radius: 8px 0 0 8px;
                i {
                  font-size: 18px;
                  color: $color-text;
                }
              }
              &-right {
                width: 160px;
                height: 100%;
                background-size: cover;
                border-radius: 0 8px 8px 0;
                position: relative;
                overflow: hidden;
                img {
                  width: 22px;
                  position: absolute;
                  left: 40px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: 2;
                }
                &:before {
                  display: block;
                  content: '';
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.11);
                  z-index: 1;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
    .pi-impact-info {
      padding: 24px 32px;
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h4 {
          font-weight: 900;
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
          color: #4b5258;
          margin-bottom: 0;
        }
        .pi-btn.pi-outline {
          color: $color-text;
          min-width: 140px;
        }
      }
      &-empty {
        font-weight: 900;
        font-size: 1.15rem;
        line-height: 1.85rem;
        text-transform: uppercase;
        color: #4b5258;
        opacity: 0.5;
        text-align: center;
        max-width: 460px;
        margin: 82px auto;
      }
      .pi-device-chart {
        padding: 15px 15px 0 10px;
      }
      .pi-logs-body {
        padding: 25px 0;
      }

      .pi-graph-loader {
        margin-top: 50px;
      }
    }
    & > .pi-device-details {
      display: block;
      max-width: calc(100% - 20px);
      height: calc(100% - 20px);
      position: absolute;
      right: 10px;
      top: 10px;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
      .pi-device-header {
        & > button.pi-details-close {
          display: none;
        }
      }
    }
  }
  .pi-add-reports {
    max-width: 910px;
    max-height: calc(100% - 20px);
    top: 10px;
    right: 10px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  }
}
.apexcharts-toolbar {
  z-index: 0 !important;
}
.pi-modal-fullMap {
  height: calc(100% - 4rem);

  .modal-content {
    max-width: 90%;
    margin: 0 auto;
    height: 100%;
    min-height: 574px;

    .pi-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 42px 24px !important;
      .pi-close {
        position: static;
      }
    }
    .pi-modal-body {
      padding: 0 24px 16px;
      //padding: 0 0 16px;
      height: calc(100% - 200px);
      border-bottom: 1px solid #e4e4e4;

      .pi-device-location {
        width: 100%;
        height: 100%;
      }

      .pi-device-map {
        height: 100%;
      }
    }
    .pi-modal-footer {
      padding: 16px 24px 24px;
      margin-top: 0;
      border: none;
      .map-marker-mode-button {
        background-color: #fff;
        color: $color-text;
        &.pi-active {
          background-color: $color-text;
          color: #fff;
        }
        img {
          width: 13px;
          margin-right: 13px;
        }
      }
    }
  }
}

.pi-activity-report {
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 810px;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  &-header {
    display: flex;
    align-items: center;
    padding: 25px 30px 15px;
    justify-content: space-between;
    border-bottom: 1px solid $color-border;

    .title {
      display: flex;
      align-items: center;
      .pi-close {
        @include close-button(24px, 10px, 24px);
        position: static;
        transform: rotate(-90deg);
        padding: 0;
        margin: 0 16px 0 0;
      }
      h5 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: bold;
        color: $color-text;
      }
    }

    .pi-expanded-action {
      display: flex;
    }

    .pi-chart-trigger {
      margin-right: 8px;
      color: $color-text;

      &.pi-active {
        background-color: $color-text;

        i {
          color: #fff;
        }
      }

      i {
        font-size: 24px;
      }
    }
  }
}

