// mobile first

.container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .content_container {
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    border-radius: 8px;
    z-index: 1;

    .header {
      background: #f5f8fa;
      height: 60px;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 24px 0px 32px;

      .title {
        font-size: 16px;
        line-height: 19px;
        margin: 0;
        color: rgba(53, 64, 82, 1);
      }

      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 25px;

        &:hover {
          background-color: #f5f8fa;
        }
      }
    }

    .content {
      flex: 1;
      overflow: auto;
      background: #f5f8fa;
      padding: 23px;
    }

    .actions {
      height: 80px;
      border-top: 1px solid #e4e4e4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0px 24px;
      gap: 10px;

      .action {
        width: 120px;
      }

      .actionSubmit {
        display: flex;
        width: 120px;
        background-color: rgba(250, 165, 40, 1);
        color: white;
        align-items: center;
        gap: 5px;
        justify-content: center;

        &[disabled] {
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content_container.mobile_full {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
}
