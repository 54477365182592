.header {
	padding-left: 20px;
	padding-right: 20px;

	.navbar {
		padding: 0;
	}

	.user-image {
		&.show-mobile {
			display: none;
			max-height: 100%;
			max-width: calc(50% - 50px);
			margin: 0 0 0 auto !important;
		}

		&.hide-mobile {
			display: inline-block;
		}

		@include media-breakpoint-down(sm) {
			&.show-mobile {
				display: inline-block;
			}

			&.hide-mobile {
				display: none;
			}
		}
	}
.menu_icon_mobile{
	display: none;
}
	.sidebar-brand {
		@include media-breakpoint-down(sm) {
			padding-right: 10px !important;
		}
		img {
			@include media-breakpoint-down(md) {
				margin-right: 0 !important;
			}
			@include media-breakpoint-down(sm) {
				padding-right: 10px !important;
			}
		}
	}

	.content-left {
		.nav-link {
			color: $gray-600;
			padding: 25px 5px;
			border-bottom: 4px solid transparent;
			margin-right: 25px;
			transition: all 0.2s;

			@include media-breakpoint-down(sm) {
				border-bottom: none;
				margin-right: 0;
				text-align: center;
				padding: 15px 10px;
			}

			&.active {
				color: $orange;
				border-bottom-color: $orange;
			}

			&:hover {
				color: $orange;
			}
		}
	}

	.content-right {
		@include media-breakpoint-down(sm) {
			text-align: center;
			padding-bottom: 20px;

			.dropdown-menu {
				text-align: center;
			}
		}
	}
}
.sidepanel  {
  width: 0;
  position: fixed;
  z-index: 1;
  height:calc(100vh - 77px);
  overflow: scroll;
  top: 78px !important;
  left: 0;
  background-color: #4B5258;
  transition: 0.5s;
  padding-top: 15px;
  display: grid;
}
.sidepanel > div > p{
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 15px;
    padding-left: 61px;
}
.sidepanel > div > p:nth-child(1){
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 15px;
    padding-left: 61px;
}
.sidepanel p {
  padding: 8px 8px 8px 60px;
  text-decoration: none;
  color: #ffffff;
  display: block;
  margin: 0px;
  transition: 0.3s;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0.28px;
}
.sidepanel p:nth-child(1) {
	text-decoration: none;
	color: #ffffff;
	display: block;
	transition: 0.3s;
	font-family: Roboto;
	font-size: 18px;
	letter-spacing: 0.28px;
  }
// .sidepanel p:hover {
// background: #FAA528;
// }

.down_arrow_profile{
	margin-left: 7%;	
}

.profile-logo{
	position: relative;
	left: -14%;
}

.sidepanel p:hover {
  color: #f1f1f1;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}
.sideMenu_mobile{
	align-self: flex-end;
	margin-top: 90px;
}

.openbtn:hover {
  background-color:#444;
}
.new_sidebar_copyright_mobile{
	margin-left: 59px;
	align-self: flex-end;
}

.dropdown-toggle:after{
	color: orange !important;
	margin-left: 10px !important;
}

@media only screen and (max-width: 636px) {
	.drop-down-profile{
		position: relative !important;
		top: -4px;
		left: -6%;
		width: 219px;
		background-color: #4B5258 !important;
		color: #fff !important;
		border: 1px solid #fff !important;
	}
.drop-down-profile{
	.dropdown-item{
		color: #fff !important;
	}
}

	.header {
		height: 78px;
		position: relative;
		.navbar {
			position: static;
		}
		.sidebar-brand {
			width: 120px;
			display: inline !important;
			padding-right: 0 !important;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			img {
				padding-right: 0 !important;
			}
		}
	}
	.navbar-toggler{
		display: none;
	}
	.sidepanel .navbar-toggler{
		//display: inline-block;
		display: none;
	}
	.sidepanel .collapse:not(.show){
		display: inline-block;
	}
	.menu_icon_mobile{
		min-width: 45px;
		padding-right:20px;
		display: inline-block !important;
	}
.sidepanel p:nth-child(1) .collapse > ul:nth-child(2) > li > a {
	color: #FAA528 !important;
}
.sidepanel p:nth-child(1) .collapse > ul:nth-child(2) > li > a > span {
	color: #ffffff !important;
}
  }
