@media (max-width: 1200px) {
    .pi-device-details-workZone {
        max-width: 100%;
    }
}
@media (max-width: 767px) {
    .pi-device-details-workZone {
        .pi-info-tabs {
            .pi-tab-navigation {
                padding: 0;
            }
            .tab-content {
                .pi-information-team {
                    .pi-view-team-button {
                        position: static;
                        margin: 16px 0 0 12px;
                    }
                }
            }
        }
    }
}
@media (max-width: 520px) {
    .pi-device-details-workZone {
        .pi-device-info {
            &-top {
                align-items: flex-start;
                .flex {
                    display: block;
                }
                .pi-device-info-switch {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                p {
                    padding-left: 0;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .pi-info-tabs {
            .tab-content {
                .pi-information-team {
                    .pi-teams-group-list {
                        li {
                            &:not(:last-of-type) {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}