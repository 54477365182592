.pi-sidebar {
    width: 100%;
    display: flex;
    max-width: 250px;
    overflow-y: auto;
    background: #4B5258;
    flex-direction: column;
    height: var(--app-height);

    .pi-logo {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        padding: 35px 20px 30px 24px;
        justify-content: space-between;
        border-bottom: 1px solid rgba(221, 238, 238, 0.1);

        a {
            @include transition(all 0.2s);

            &:hover {
                opacity: 0.8;
            }
        }

        p {
            margin: 0;
        }
    }

    .pi-sidebar-close {
        border: 0;
        padding: 0;
        color: #fff;
        font-size: 24px;
        @include transition(all 0.2s);
        background-color: transparent;

        &:hover {
            color: $color-text;
        }
    }
    
    .pi-sidebar-trigger {
        width: 34px;
        height: 34px;
        border: 0;
        padding: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-sm;
        @include transition(all 0.2s);
        background-color: rgba(#000, 0.3);

        i {
            font-size: 18px;
            @include transform(rotate(90deg));
        }

        &:hover {
            background-color: rgba(#000, 0.5);
        }
    }

    .pi-nav-items {
        padding-left: 0;
        list-style: none;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 20px 15px 20px 24px;
            @include transition(all 0.3s);

            i {
                font-size: 24px;
                color: $color-secondary;
                @include transition(all 0.3s);
            }

            span {
                color: #fff;
                font-size: $md;
                font-weight: 500;
                padding-left: 24px;
            }

            &.pi-active {
                cursor: default;
                background-color: $color-secondary;

                i {
                    color: #fff;
                }
            }

            &:hover:not(.pi-active) {
                background-color: rgba($color-secondary, 0.3);

                i {
                    color: #fff;
                }
            }
        }
    }

    .pi-account-trigger {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid rgba(221, 238, 238, 0.1);
    }

    .new-sidebar-copyright {
        display: flex;
        font-size: 12px;
        margin-top: auto;
        align-items: flex-end;
        color: rgba(#fff, 0.5);
        padding: 25px 0 15px 16px;
    }

    .new-sidebar-copyright-mobile {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.25px;
        color: rgba(#fff, 0.5);
    }
}
