// Location page side details

.pi-device-details {
    width: 100%;
    height: 100%;
    position: fixed;
    max-width: 810px;
    overflow-y: auto;
    top: 0;
    right: 0;
    z-index: 5;
    background-color: #fff;
    border: 1px solid $color-border;

    .pi-device-info {
        padding: 15px;
    }

    .pi-info-tabs {
        padding: 5px 15px 15px;
    }

    .pi-device-header {
        padding: 0 15px;
        position: relative;

        h2 {
            margin-bottom: 0;
            padding-right: 20px;
        }

        .pi-details-close {
            @include close-button(0, 0, 24px);
        }
    }

    .pi-details-body {
        margin-top: 20px;
        border-radius: $radius-md;
        border: 1px solid $color-border;

        .pi-details-overview {
            display: flex;
        }

        .pi-image {
            width: 100%;
            padding: 10px;
            max-width: 260px;
            text-align: center;
            position: relative;
            border-top-left-radius: $radius-md;
            border-bottom-left-radius: $radius-md;
            border-right: 1px solid $color-border;

            img {
                max-width: 100%;
            }

            .pi-image-box {
                width: 100%;
                padding-top: 100%;
                border-radius: $radius-md;
                background: no-repeat center;
                background-size: contain;
            }

            .pi-btn {
                margin: 15px auto 0;
            }
        }

        .pi-action-btns {
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            align-items: center;
            background-color: #fff;
            border-radius: $radius-sm;
            border: 1px solid $color-border;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

            li {
                width: 50%;
                position: relative;
                text-align: center;

                &:last-child {
                    &::before {
                        left: 0;
                        top: 50%;
                        width: 1px;
                        content: '';
                        height: 100%;
                        max-height: 22px;
                        position: absolute;
                        display: inline-block;
                        background-color: $color-border;
                        @include transform(translateY(-50%));
                    }
                }

                .pi-icon-btn {
                    border: 0;
                    padding: 10px;
                    cursor: pointer;
                    margin-bottom: 0;
                    @include transition(all 0.2s);
                    background-color: transparent;

                    &:hover {
                        opacity: 0.8;
                    }

                    i {
                        font-size: 19px;
                    }
                }

                .pi-file-field {
                    display: none;
                }
            }
        }

        .pi-details {
            width: 100%;
            position: relative;
            padding: 35px 15px 25px 30px;
            max-width: calc(100% - 260px);

            .pi-menu-expandable  {
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 1;
            }

            .pi-details-list {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    width: 100%;
                    display: flex;
                    padding: 15px 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid $color-border;
                    }
                }
            }
        }
    }

    .pi-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.pi-locations-wrapper {
    .pi-device-details {
        .pi-device-header {
            h2 {
                button {
                    display: none;
                }
            }
        }
    }
}

// Edit device image
.pi-edit-location-wrapper {
    display: flex;

    .pi-image-wrapper {
        width: 100%;
        max-width: 280px;
        position: relative;
        padding: 20px 20px 40px;
        border-radius: $radius-md;
        border: 1px solid $color-border;

        .pi-image {
            width: 100%;
            max-width: 280px;
            padding-top: 100%;
            background: no-repeat center;
            background-size: contain;
        }
    }

    .pi-edit-form {
        width: 100%;
        padding: 0 20px;
        max-width: 400px;
    }
}

// Team overview
.pi-team-overview {
    display: flex;
    margin-top: 20px;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

    .pi-details-label {
        width: 100%;
        max-width: 240px;
        padding: 47px 25px;
        border-right: 1px solid $color-border;
    }

    .pi-details-part {
        width: 100%;
        padding: 20px 0 12px;

        .pi-details-info {
            width: 100%;
            display: flex;
            padding: 0 20px;
            align-items: center;
            justify-content: space-between;

            p {
                display: flex;
                margin-bottom: 0;
                align-items: center;
                max-width: calc(100% - 120px);
                color: rgba($color-text, 0.6);

                i {
                    font-size: 22px;
                    margin-right: 10px;
                }

                span {
                    font-size: $xs;
                    font-weight: 400;
                }
            }
        }

        .pi-details-group {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;

            .pi-detail-item {
                margin-bottom: 8px;

                &:first-of-type {
                    margin-left: 20px;
                }
            }
        }
    }
}

// Device orientation
.pi-device-orientation {
    display: flex;
    padding: 15px 0;
    margin-top: 20px;
    align-items: center;
    background-color: #fff;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

    h3 {
        padding: 40px;
        font-size: $md;
        font-weight: 700;
        margin-bottom: 0;
        color: $color-text;
        text-transform: uppercase;
    }

    ul {
        display: flex;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        align-items: center;

        li {
            width: 155px;
            padding: 10px 0;
            text-align: center;
            height: calc(100% - 20px);
            border-radius: $radius-md;
            border: 1px solid $color-border;

            &:not(:first-child) {
                margin-left: 15px;
            }

            &:not(.pi-active) {
                img, span {
                    opacity: 0.6;
                }
            }

            .pi-device-type {
                display: block;
                height: 120px;
                background: no-repeat center;
                background-size: contain;
            }

            span {
                width: 100%;
                font-size: $sm;
                display: block;
                font-weight: 500;
                padding-top: 10px;
                color: $color-text;
            }
        }
    }
}

// Team members popup
.pi-team-members-modal {
    .pi-search-bar {
        display: flex;
        padding: 15px 25px;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        .pi-text-field {
            width: 100%;
            max-width: calc(100% - 50px);
        }
    }

    .pi-table-wrapper {
        padding: 5px 25px 25px;
    }

    .pi-side-panel {
        width: 100%;
        max-width: 290px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        display: flex;
        padding-top: 25px;
        flex-direction: column;
        background-color: $color-bg;
        border-top-right-radius: $radius-md;
        border-bottom-right-radius: $radius-md;
        border-left: 1px solid $color-border;
        z-index: 5;

        .pi-side-body {
            flex: 1;
            padding: 0 25px;
            height: calc(100% - 100px);

            .pi-team-members {
                overflow-y: auto;
                margin-top: 30px;
                max-height: calc(100% - 140px);
            }

            .pi-team-fields {
                overflow-y: auto;
                margin-top: 5px;
                max-height: calc(100% - 45px);
            }
        }

        .pi-side-footer {
            display: flex;
            position: relative;
            justify-content: center;
            background-color: $color-bg;
            padding: 20px 32px 32px 32px;
            border-top: 1px solid $color-border;
            border-bottom-left-radius: $radius-md;
            border-bottom-right-radius: $radius-md;

            .pi-btn:first-of-type {
                margin-right: 8px;
            }
        }
    }
    &.pi-modal {
        .pi-modal-footer {
            margin-top: 0;
        }
    }
}

.pi-title-section {
    display: flex;
    align-items: center;

    h4 {
        font-weight: 500;
        margin-bottom: 0;
    }

    .pi-back-btn {
        border: 0;
        padding: 0;
        font-size: 20px;
        margin-right: 12px;
        color: $color-text;
        background-color: transparent;

        &:not(.pi-displayed) {
            display: none;
        }

        i {
            @include transform(rotate(90deg));
        }
    }
}

// History tab components
.pi-history-search {
    padding: 38px 32px 15px;
    background-color: $color-bg;
    border: 1px solid $color-border;
    border-top: 0;
}

// History log components
.pi-history-logs {
    padding-left: 20px;
    position: relative;

    // two headers merged state
    .pi-collapse {
        &.pi-merged {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            & + .pi-merged {
                box-shadow: none;
                margin-bottom: 0;
                border-radius: 0;
            }

            & + .pi-collapse:not(.pi-merged) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .pi-logs-header {
        display: flex;
        cursor: pointer;
        position: relative;
        padding: 11px 10px;
        align-items: center;

        .pi-border-left {
            position: relative;

            &::after {
                right: 0;
                top: 50%;
                width: 1px;
                content: '';
                height: 42px;
                position: absolute;
                display: inline-block;
                background-color: $color-border;
                @include transform(translateY(-50%));
            }
        }

        .pi-datetime {
            span, strong {
                display: block;
            }

            span {
                font-size: $sm;
                color: rgba($color-text, 0.6);
            }

            strong {
                font-size: $md;
                font-weight: 500;
                color: $color-text;
            }
        }

        &::after {
            top: 50%;
            right: 32px;
            font-size: 24px;
            position: absolute;
            color: $color-text;
            content: $icon-expand-right;
            @include transition(all 0.2s);
            font-family: $font-family-icons;
            @include transform(translateY(-50%) rotate(90deg));
        }

        &::before {
            top: 50%;
            width: 1px;
            right: 88px;
            content: '';
            height: 42px;
            position: absolute;
            display: inline-block;
            background-color: $color-border;
            @include transform(translateY(-50%));
        }
    }

    .pi-collapse {
        margin-bottom: 6px;
        position: relative;
        background-color: #fff;
        border-radius: $radius-sm;
        @include transition(all 0.2s);
        border: 1px solid $color-border;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        &::before {
            content: '';
            width: 9px;
            height: 9px;
            display: block;
            border-radius: 100%;
            position: absolute;
            left: -19px;
            top: 31px;
            z-index: 1;
            background-color: #fff;
            border: 1px solid $color-border;
        }

        &::after {
            content: '';
            width: 1px;
            position: absolute;
            left: -15px;
            z-index: 0;
            top: 35px;
            height: calc(100% + 6px);
            background-color: $color-border;
        }

        &:last-of-type {
            &::after {
                display: none;
            }
        }

        &:hover {
            .pi-logs-header {
                background-color: rgba($color-bg, 0.5);
            }
        }

        &.show {
            &::before {
                border-color: $color-text;
                background-color: $color-text;
            }

            &::after {
                background-color: $color-text;
            }

            & + .pi-collapse {
                &::before {
                    border-color: $color-text;
                }
            }

            .pi-logs-header {
                &::after {
                    @include transform(translateY(-50%) rotate(270deg));
                }
            }

            .pi-collapse-header {
                position: relative;

                &::before {
                    content: ' ';
                    height: 1px;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: calc(100% - 40px);
                    border-top: 1px solid $color-border;
                }
            }
        }
    }
}

// History collapse body
.pi-logs-body {
    padding: 25px 30px 25px;

    .pi-log-title {
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: 30px;

        &::before {
            content: ' ';
            height: 1px;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            @include transform(translateY(-50%));
            border-top: 1px dashed rgba($color-text, 0.5);
        }

        h5 {
            z-index: 1;
            padding: 0 20px;
            margin-bottom: 0;
            font-weight: 500;
            position: relative;
            display: inline-block;
            background-color: #fff;
            text-transform: uppercase;
            color: rgba($color-text, 0.6);
        }
    }

    .pi-log-info-list:not(:last-of-type) {
        margin-bottom: 30px;

        li:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .pi-log-gallery {
        display: grid;
        grid-gap: 15px;
        margin-bottom: 30px;
        grid-template: unset;
        grid-template-columns: repeat(auto-fit, 120px);

        li {
            width: 120px;
            height: 120px;
            cursor: pointer;
            border-radius: $radius-md;
            background: no-repeat center;
            background-size: cover;
            @include transition(all 0.2s);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .pi-log-image-change {
        .pi-image-states {
            display: flex;
            margin-top: 20px;
            align-items: center;

            li {
                width: 120px;
                height: 120px;
                position: relative;
                border-radius: $radius-md;
                background: no-repeat center;
                background-size: contain;
                @include transition(all 0.2s);
                border: 1px solid $color-border;

                &:not(:first-child) {
                    margin-left: 38px;

                    &::before {
                        font-size: 20px;
                        position: absolute;
                        left: -28px;
                        top: 50%;
                        display: inline-block;
                        content: $icon-arrow-down;
                        color: rgba($color-text, 0.6);
                        font-family: $font-family-icons;
                        @include transform(translateY(-50%) rotate(-90deg));
                    }
                }
            }
        }
    }
}

// History image popup
.pi-image-gallery {
    .carousel-indicators {
        display: none;
    }
    .carousel-control-next,
    .carousel-control-prev {
        top: auto;
        width: 41px;
        height: 41px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 6px;
        transition: all 0.2s;
        padding: 0 8px;
        line-height: 39px;
        color: #354052;
        background-color: #fff;
        border: 1px solid #E4E4E4;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 100%;
            height: 100%;
            font-size: 24px;
            background: none;
            font-family: $font-family-icons;

            &::before {
                content: $icon-expand-right;
            }
        }

        .carousel-control-prev-icon {
            @include transform(rotate(-180deg));
        }

        &:hover {
            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                color: $color-text;
            }
        }
    }

    .carousel-control-prev {
        right: 60px;
        left: auto;
        bottom: -102px;
    }

    .carousel-control-next {
        right: 11px;
        bottom: -102px;
    }

    .pi-modal-footer {
        height: 94px;
    }

    .pi-image-slider {
        .carousel-item {
            overflow: hidden;
            border-radius: $radius-sm;
            background-color: $color-text;
        }

        img {
            max-height: 440px;
            object-fit: contain;
        }
    }
}

// History map
.pi-device-location {
    overflow: hidden;
    margin-top: 10px;
    position: relative;
    border-radius: $radius-md;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

    .pi-device-map {
        width: 100%;
        height: 320px;
    }

    .pi-map-marker {
        position: absolute;
        z-index: 3;

        &.pi-right-top {
            top: 10px;
            right: 10px;
        }
    }
}

// Accelerometer Sensitivity
.pi-device-sensitivity {
    padding: 40px;
    margin-top: 20px;
    position: relative;
    background-color: #fff;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

    .pi-info-tooltip {
        position: absolute;
        top: 8px;
        right: 12px;
        font-size: 22px;
        color: $color-text;
    }
}

// Device line chart
.pi-device-chart {
    margin-top: 20px;
    position: relative;
    padding: 20px 0 0 10px;
    background-color: #fff;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}

.pi-line-chart {
    position: relative;

    .apexcharts-legend {
        display: none !important;
    }

    .pi-chart-legends {
        display: flex;
        padding-left: 10px;
        align-items: center;
        padding-right: 20px;
        justify-content: space-between;

        span {
            font-size: $xs;
            font-weight: 700;
            color: $color-text;
            position: relative;
            padding-left: 15px;
            text-transform: uppercase;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                border-radius: $radius-circle;
                @include transform(translateY(-50%));
            }

            &.pi-red {
                &::before {
                    background-color: $color-red;
                }
            }

            &.pi-primary {
                &::before {
                    background-color: $color-primary;
                }
            }

            &.pi-secondary {
                &::before {
                    background-color: $color-secondary;
                }
            }
        }
    }
}