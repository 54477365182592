// Statistic cards

.pi-statistics {

    ul {
        width: 100%;
        display: flex;
        padding-left: 0;
        list-style: none;
        overflow-x: auto;
        align-items: center;
        padding-bottom: 5px;

        li {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    &.pi-column-6 {
        ul {
            li {
                min-width: 240px;
                width: calc(100%/6);
            }
        }
    }

    &.pi-column-3 {
        ul {
            li {
                min-width: 210px;
                width: calc(100%/3);
            }
        }
    }
    &.pi-column-2 {
        padding: 15px 0 15px 20px;
        ul {
            li {
                min-width: 265px;
                width: calc(100%/2);
                .pi-statistic-item {
                    display: flex;
                    align-items: center;
                    .pi-count {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

// Single Statistic item
.pi-statistic-item {
    width: 100%;
    min-height: 95px;
    position: relative;
    border-radius: $radius-md;

    &.pi-size-lg {
        padding: 28px 20px 20px;
    }

    &.pi-inline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pi-count {
            margin-top: 0;
        }
    }

    span {
        font-size: $sm;
        display: block;
        font-weight: 500;
        color: rgba($color-text, 0.7);
    }

    .pi-count {
        display: block;
        margin-top: 5px;
        font-weight: 700;
        color: $color-text;
        font-size: $lg + 4px;
    }

    .pi-info-tooltip {
        position: absolute;
        top: 8px;
        right: 12px;

        i {
            font-size: $lg;
            color: $color-text;
        }
    }

    @each $key, $val in $typography-colors {
        &.pi-#{$key} {
            background-color: rgba($val, 0.2);
        }
    }
}