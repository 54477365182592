@media (max-width: 1200px) {
    .pi-profile-wrapper {
        .pi-page-body {
            overflow: auto;
            max-height: calc(var(--app-height) - 80px);
        }

        .pi-profile-fields {
            padding-left: 0;
            padding-right: 0;
        }
    }
}