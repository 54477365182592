@media (max-width: 1365px) {
  .pi-WorkZone-wrapper {
    .pi-page-body {
      height: calc(100% - 150px);
    }
    .pi-WorkZone-table,
    .pi-detailDevice-workzone-table {
      padding: 0;
      &.pi-table.pi-list-resp {
        tbody {
          tr {
            overflow: visible;
            td {
              width: 100%;
              &:first-child {
                &:not(.pi-empty-td) {
                  width: calc(100% - 50px);
                  .pi-td {
                    padding-bottom: 10px;
                  }
                }
              }
              &:last-child {
                &:not(.pi-empty-td) {
                  position: absolute;
                  right: 2px;
                  top: 2px;
                  width: 40px;
                  .pi-td {
                    padding: 0;
                  }
                }
              }
              &.pi-empty-td {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .pi-wz-devices {
    .pi-wz-device-table.pi-list-resp {
      tbody {
        tr {
          overflow: visible;
          td {
            width: 100%;
            &:first-child {
              &:not(.pi-empty-td) {
                width: calc(100% - 50px);
                .pi-td {
                  padding-bottom: 10px;
                }
              }
            }
            &:last-child {
              &:not(.pi-empty-td) {
                position: absolute;
                right: 2px;
                top: 2px;
                width: 40px;
                .pi-td {
                  padding: 0;
                }
              }
            }
            &.pi-empty-td {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .pi-workZone-device-info {
    .pi-details-body {
      .pi-details {
        .pi-details-list {
          li {
            display: block;
            border: none !important;
            padding: 0;
            .pi-detail-item {
              display: flex;
              align-items: center;
              padding: 0;
              margin-bottom: 10px;
              max-width: 100% !important;
              &:before {
                display: none;
              }
              p {
                margin-bottom: 0;
                margin-right: 10px;
              }

              &.pi-size-md {
                span,
                strong {
                  font-size: 14px;
                }
              }
            }
            .pi-device-info-switch {
              margin: 0;
              max-width: 165px;
            }
          }
        }
      }
      .pi-details-settings-switch {
        display: block;
        .toggle-switch {
          padding: 0;
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .pi-wz-modal {
    .modal-content {
      height: 100%;
      background-color: $color-bg;
    }
    &.pi-wz-newTeam {
      .pi-modal-body {
        .pi-addTeam-options-list {
          display: block;
          li {
            width: 100%;
            margin-right: 0;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  .pi-add-member-modal,
  .pi-add-location-modal {
    .modal-content {
      height: 100%;
      .pi-modal-body {
        display: flex;
        justify-content: center;
        padding-bottom: 0;
      }
    }
  }

  .pi-dynamic-height {
    height: calc(var(--app-height) - 72px - 82px);
  }
}
@media (max-width: 680px) {
  .pi-workZone-device {
    &-info {
      .pi-details-body {
        .pi-details-overview {
          display: block;
        }
        .pi-image {
          max-width: 100%;
          border-right: none;
          border-bottom: 1px solid #e4e4e4;
        }
        .pi-details {
          max-width: 100%;
          padding: 30px 15px;
        }
        .pi-details-settings {
          display: block;
          &-switch {
            margin-bottom: 10px;
          }
        }
        .pi-details-scheduleMode {
          &-body {
            .pi-details-scheduleMode-edited {
              display: block;
              .pi-info-string {
                padding: 0;
                margin-bottom: 10px;
                &:before {
                  display: none;
                }
              }
              .pi-details-scheduleMode-weekdays {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .pi-add-member-modal,
  .pi-add-location-modal {
    .pi-new-member-form {
      display: block;
      .pi-text-field {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .pi-workZone-device-info {
    .pi-details-body {
      .pi-details-scheduleMode-body {
        .pi-details-scheduleMode-editable {
          .pi-timepicker-body {
            display: block;
            .pi-timepicker {
              max-width: 100%;
              margin-right: 0;
              margin-bottom: 25px;
            }
          }

          .pi-detail-item {
            &.pi-child-aligned {
              strong {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
