.pi-workspace-switch {
    width: 100%;
    cursor: pointer;
    max-width: 250px;
    min-height: 186px;
    position: relative;
    border-radius: $radius-sm;
    padding: 25px 29px 25px 20px;
    @include transition(all 0.2s);
    box-shadow: 0 2px 4px rgba(41, 49, 63, 0.15);

    &.pi-active {
        background-color: $color-secondary;

        &:hover {
            background-color: darken($color-secondary, 5);
        }
    }

    &:not(.pi-active) {
        background-color: $color-text;

        &:hover {
            background-color: darken($color-text, 5);
        }
    }

    .pi-inner {
        display: flex;
        color: #FFFFFF;
        justify-content: space-between;

        .pi-info {
            margin-top: 27px;

            span {
                opacity: 0.6;
                font-size: $lg;
                display: block;
                font-weight: 500;
            }

            strong {
                display: block;
                font-size: $md;
                font-weight: 700;
                margin-top: 12px;
                letter-spacing: .4px;
                text-transform: uppercase;
            }
        }

        .pi-image {
            right: 35px;
            height: 23px;
            max-width: 50px;
            position: absolute;

            img {
                max-width: 100%;
            }

            small {
                position: absolute;
                bottom: -13px;
                font-size: 9px;
                right: -17px;
                opacity: 0.6;
                font-style: italic;
                letter-spacing: 1px;
                white-space: nowrap;

                i {
                    position: relative;
                    width: 2px;
                    top: -1px;
                    height: 2px;
                    border-radius: 100%;
                    display: inline-block;
                    background-color: $color-secondary;
                }
            }
        }
    }

    p {
        font-size: $sm;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 0;
        color: rgba(#fff, 0.7);
    }

    .pi-default {
        &-mode {

        }
    }

}

.pi-switcher-wrapper {
    .pi-inner {
        label {
            position: relative;
            margin-bottom: 40px;
            .pi-workspace-switch {
                z-index: 2;
                margin-bottom: 0 !important;
            }
            .pi-default-mode {
                left: 0;
                bottom: -30px;
                width: calc(100% - 10px);
                z-index: 1;
                // z-index: -1;
                display: flex;
                position: absolute;
                align-items: center;
                padding: 8px 12px 6px;
                background-color: #fff;
                justify-content: flex-end;
                border: 1px solid $color-border;
                border-bottom-left-radius: $radius-sm;
                border-bottom-right-radius: $radius-sm;

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: $xs;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: rgba($color-text, 0.7);
                }

                i {
                    font-size: $md + 2px;
                    padding-right: 5px;
                }

                span {
                    position: relative;
                    top: 1px;
                    display: flex;
                    font-size: $xs;
                    font-weight: 500;
                    color: $color-text;
                    align-items: center;
                    cursor: pointer;

                    &::before {
                        content: '';
                        width: 13px;
                        height: 13px;
                        display: block;
                        position: relative;
                        left: -5px;
                        top: -1px;
                        border-radius: 100%;
                        border: 1px solid $color-text;
                    }
                }
            }
        }
    }
}