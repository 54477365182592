.container {
  position: relative;
  display: flex;
  flex: 1;
  .icon {
    margin-left: 8px;
  }
  .selectBox {
    & *:hover {
      border-color: unset;
    }
  }
}
