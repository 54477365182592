.toggle-switch {
    display: flex;
    align-items: center;
    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.75rem;
        color: $color-text;
        margin-right: 8px;
        span {
            display: inline-block;
            width: 30px;
            color: $color-text;
            text-align: center;
            margin-left: 8px;
        }
    }
    &-label {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 14px;
        margin-bottom: 0;
        input[type="checkbox"] {
            display: none;
        }
        .switch {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($color-text, .38);
            border-radius: 7px;
            transition: background-color 0.2s ease;
            width: 34px;
            height: 14px;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: -3px;
                width: 20px;
                height: 20px;
                background-color: $color-text;
                border-radius: 50%;
                transition: transform 0.3s ease;
            }
        }
        input[type="checkbox"]:checked + .switch::before {
            transform: translateX(15px);
            background-color: $color-green;
        }
        input[type="checkbox"]:checked + .switch {
            background-color: rgba($color-green, .38);
        }

        input:disabled + .switch {
            cursor: default;
            opacity: 0.5;
        }
    }
}