// User avatar with text

.pi-user-avatar {
    display: flex;
    align-items: center;

    @each $key, $val in $typography-colors {
        &.pi-#{$key} {
            span {
                background-color: rgba($val, 0.5);
            }
        }
    }

    span {
        width: 32px;
        height: 32px;
        font-size: $xs;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        color: rgba(#fff, 0.7);
        border-radius: $radius-circle;
    }

    i {
        font-size: 24px;
        line-height: inherit;

        &:not([class*="pi-color-"]) {
            color: rgba(#fff, 0.7);
        }
    }

    strong {
        font-size: $md;
        font-weight: 500;
        padding-left: 8px;
        color: $color-text;
    }
}