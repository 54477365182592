@media (max-width: 659px) {
  .pi-sidebar {
    width: 100%;
    position: absolute;
    @include transform(translateX(-100%));

    .pi-sidebar-trigger {
      display: none;
    }

    &.pi-small {
      overflow: hidden;
    }
  }

  .pi-sidebar-state {
    overflow: hidden;

    .pi-small {
      overflow: auto !important;
    }

        .pi-sidebar {
            height: 100dvh;
            z-index: 50;
            max-width: 100%;
            @include transform(translateX(0));

        }

    .pi-page-title {
      .pi-sidebar-trigger {
        right: 0;
        top: 38px;
        color: #fff;
        //z-index: 11;
        position: absolute;

        i::before {
          content: $icon-close;
        }
      }
    }
  }
}

@media (min-width: 660px) {
  .pi-sidebar {
    .pi-sidebar-close {
      display: none;
    }

    // Sidebar: only icons
    &.pi-small {
      max-width: 72px;
      overflow: hidden;

      .pi-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;

        a {
          display: none;
        }
      }

      .pi-sidebar-trigger {
        @include transform(rotate(180deg));
      }

      .pi-nav-items {
        a {
          span {
            display: none;
          }
        }
      }

      .new-sidebar-copyright {
        display: none;
      }
    }
  }
}
