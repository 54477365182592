.pi-devices-details {
    .pi-device-info {
        background: $color-bg;
        padding: 12px;
        .pi-devices-header {
            display: flex;
            align-items: center;
            margin: 8px 0 16px;
            .pi-close {
                color: rgba($color-text, 0.7);
                background-color: transparent;
                border: none;
                transition: all 0.2s;
                transform: rotate(-90deg);
                padding: 0;
                margin: 0 16px;
                i {
                    font-size: 20px;
                }
                &:hover {
                    color: $color-text;
                }
            }
            h2 {
                margin-bottom: 0;
            }
        }
        .pi-details-body {
            .pi-image {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 200px;
                background-color: #fff;
            }
            .pi-details {
                max-width: calc(100% - 200px);
                padding: 15px 15px 15px 30px;
                .pi-details-list {
                    li {
                        .pi-device-info-switch {
                            border: 1px solid rgba(53, 64, 82, 0.3);
                            box-sizing: border-box;
                            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                            border-radius: 6px;
                            padding: 10px 16px;
                            margin: 0 20px;
                            background-color: #fff;
                            .toggle-switch {
                                &-title {
                                    color: rgba($text-color, .7);
                                }
                            }
                        }
                    }
                }
            }
            .pi-details-settings {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 16px;
                border-top: 1px solid $color-border;
                &-switch {
                    display: flex;
                    align-items: center;
                    .toggle-switch {
                        padding: 0 16px;
                        position: relative;
                        &:before {
                            content: '';
                            width: 1px;
                            height: 100%;
                            display: block;
                            max-height: 41px;
                            position: absolute;
                            background-color: #E4E4E4;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                        &-title {
                            font-size: 0.9375rem;
                        }
                        &:first-child {
                            padding-left: 0;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
                .pi-select {
                    max-width: 170px;
                    width: 100%;
                }
            }
            .pi-details-scheduleMode {
                padding: 18px 16px;
                border-top: 1px solid $color-border;
                &-top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    //margin-bottom: 20px;
                    .pi-checkbox {
                        font-weight: 500;
                        font-size: 0.9375rem;
                        line-height: 1.3125rem;
                        color: $color-text;
                        margin: 5px 0;
                        input[type="checkbox"] {
                            display: block;
                            position: static;
                            width: 17px;
                            height: 17px;
                            border: 1px solid $color-text;
                            margin-right: 8px;
                        }
                    }
                    .pi-edit-button {
                        font-size: 18px;
                        color: $color-secondary;
                        background-color: transparent;
                        border: none;
                    }
                    .pi-schedule-actions {
                        display: block;
                        .pi-cancel {
                            border: 1px solid rgba($color-text, 0.3);
                            margin-right: 4px;
                        }
                    }
                }
                &-body {
                    .pi-details-scheduleMode-edited {
                        display: flex;
                        align-items: center;
                        .pi-info-string {
                            padding: 2px 15px;
                            position: relative;
                            &:before {
                                content: '';
                                width: 1px;
                                height: 30px;
                                display: block;
                                max-height: 41px;
                                position: absolute;
                                background-color: #E4E4E4;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                            }
                            &:first-child {
                                padding-left: 0;
                            }
                        }
                        .pi-details-scheduleMode-weekdays {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            padding-left: 15px;
                            .pi-info-string {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                        .pi-tags {
                            flex-wrap: wrap;
                            li {
                                background-color: rgba($color-text, .1);
                                &:first-child {
                                   margin-left: 0;
                                }
                                span {
                                    color: $color-text;
                                }
                            }
                        }
                    }
                    .pi-details-scheduleMode-editable {
                        .pi-timepicker-body {
                            display: flex;
                            margin-bottom: 50px;
                            .pi-timepicker {
                                max-width: 50%;
                                margin-right: 16px;
                                span {
                                    display: block;
                                    font-weight: 500;
                                    font-size: 0.875rem;
                                    line-height: 1rem;
                                    color: #4B5258;
                                    margin-bottom: 8px;
                                }
                                .MuiFormControl-root {
                                    width: 100%;
                                    .MuiInputBase-root {
                                        border: none;
                                        .MuiInputBase-input {
                                            height: 40px;
                                            border: 1px solid $color-border;
                                            box-sizing: border-box;
                                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                                            border-radius: 4px;
                                            margin: 0 !important;
                                            padding: 0 15px;
                                            background-color: #fff;
                                        }
                                    }
                                }
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                        label {
                            width: 100%;
                            span {
                                display: block;
                                font-weight: 500;
                                font-size: 0.875rem;
                                line-height: 1rem;
                                color: #4B5258;
                                margin-bottom: 8px;
                            }
                        }
                        .pi-multiSelect {
                            .css-bg1rzq-control {
                                border: 1px solid $color-border;
                                box-sizing: border-box;
                                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                                border-radius: 4px;
                            }
                            .select__indicators {
                                display: none;
                            }
                            .css-151xaom-placeholder {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .pi-info-tabs {
        padding: 0;
        .pi-tab-navigation {
            padding: 0 12px;
            border: none;
            border-radius: 0;
            .pi-tab-nav-item {
                .pi-tab-nav-link {
                    padding: 8px 15px 12px;
                }
            }
        }
        .tab-content {
            padding: 12px;
            .pi-device-location {
                margin-top: 0;
            }
            .pi-search-bar {
                border: none;
                border-bottom: 1px solid $color-border;
            }
        }
    }
    .pi-devices-workzone-details {
        padding: 6px 12px;
        &-info {
            padding: 20px;
            position: relative;
            background-color: #fff;
            border-radius: $radius-md;
            border: 1px solid $color-border;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
            .pi-devices-workzone-details-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 12px;
                .pi-information-team-title {
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: 0.21875px;
                        text-transform: uppercase;
                        color: rgba($color-text, .7);
                        margin-bottom: 0;
                        button {
                            border: none;
                            cursor: pointer;
                            background-color: transparent;
                            padding: 0;
                            text-decoration: underline;
                            color: $color-primary;
                        }
                    }
                }
            }
            .pi-devices-workzone-details-bottom {
                padding-top: 12px;
                display: flex;
                border-top: 1px solid #E4E4E4;
                .toggle-switch-title {
                    span {
                        margin-left: 0;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.pi-devices-details-workzone {
    position: fixed;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    max-width: 784px;
    background-color: #fff;
    top: 12px;
    right: 12px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    overflow: auto;
    padding: 15px;
    z-index: 2;
    .pi-search-bar {
        padding: 13px;
        background: #F5F8FA;
        border: 1px solid #E4E4E4;
        border-top: none;
    }
    .pi-table {
        table {
            &.pi-white-row {
                background-color: transparent;
            }
        }
    }
    .pi-device-info {
        background-color: #fff;
        padding: 0;
        .pi-device-header {
            display: flex;
            align-items: center;
            margin: 8px 0 16px;
            padding: 0;
            .pi-close {
                color: rgba($color-text, 0.7);
                background-color: transparent;
                border: none;
                transition: all 0.2s;
                transform: rotate(-90deg);
                padding: 0;
                margin-right: 16px;
                i {
                    font-size: 20px;
                }
                &:hover {
                    color: $color-text;
                }
            }
            h2 {
                margin-bottom: 0;
                padding-right: 60px;
            }
            .pi-menu-expandable {
                position: absolute;
                right: 0;
                top: 0;
                button {
                    i {
                        color: $color-secondary;
                    }
                }
            }
        }
        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px 0 15px;
            .flex {
                display: flex;
                align-items: center;
            }
            .pi-device-info-switch {
                border: 1px solid rgba(53, 64, 82, 0.3);
                box-sizing: border-box;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                padding: 10px 16px;
                margin-right: 20px;
                .toggle-switch {
                    &-title {
                        color: rgba($text-color, .7);
                    }
                }
            }
            p {
                font-size: 1rem;
                line-height: 1.75rem;
                font-weight: 500;
                color: rgba($color-text, .7);
                margin-bottom: 0;
                padding-left: 20px;
                position: relative;
                strong {
                    color: $color-text;
                }
                &:before {
                    content: '';
                    width: 1px;
                    height: 100%;
                    display: block;
                    max-height: 21px;
                    position: absolute;
                    background-color: #E4E4E4;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .pi-info-tabs {
        .pi-tab-navigation {
            border: 1px solid #E7E7E7;
            box-sizing: border-box;
            border-radius: 8px 8px 0 0;
        }
        .tab-content {
            padding: 0;
            .pi-information-team {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #E7E7E7;
                border-radius: 8px;
                padding: 20px 8px;
                margin-top: 8px;
                position: relative;
                margin-bottom: 12px;
                &-title {
                    margin-left: 12px;
                    p {
                        font-weight: bold;
                        font-size: 0.9375rem;
                        line-height: 25px;
                        text-transform: uppercase;
                        color: rgba($color-text, .7);
                        margin-bottom: 0;
                        strong {
                            color: $color-text;
                        }
                    }
                }
            }
        }
    }
}
.pi-device-details-workZone {
    .pi-devices-details-workzone {
        padding: 0;
        .pi-devices-workzone-details {
            padding: 15px;
        }
    }
}


.pi-devices-table {
    table {
        tbody {
            tr {
                td {
                    &:last-child {
                        p {
                            overflow: visible !important;
                        }
                    }
                }
            }
            .pi-menu-expandable {
                text-align: center;
                button {
                    border: none;
                    box-shadow: none;
                    i {
                        color: $color-text;
                    }
                }
            }
        }
    }
}

.pi-device-modal, .pi-file-upload-modal {
    .pi-modal-header {
        padding: 40px 32px 25px !important;
    }
    .pi-modal-body {
        padding: 0 32px !important;
        .pi-text-field {
            &:not(:last-of-type) {
                margin-bottom: 36px;
            }
        }
        .pi-upload-csv {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 0 30px;

            label {
                cursor: pointer;
            }

            input {
                display: none;
                visibility: hidden;
            }

            .pi-upload-progress {
                display: flex;
                align-items: center;
                background-color: rgba($color-text, 0.07);
                border-radius: 7px;
                padding: 5px 10px;
                max-width: 140px;

                span {
                    margin-right: 12px;
                    color: rgba($color-text, 0.5);
                }
                .pi-loader {
                    display: inline-block;
                    font-size: 13px;
                    width: 14px;
                    height: 14px;
                    border: 0.1em solid;
                    border-right-color: transparent;
                }
            }
            .pi-uploaded-file {
                display: inline-block;
                & > div {
                    display: flex;
                    font-size: 13px;
                    padding: 5px 10px;
                    align-items: center;
                    border-radius: $radius-sm;
                    justify-content: space-between;
                    background-color: rgba($color-text, 0.07);

                    & > i {
                        font-size: 20px;

                        &.pi-color-red {
                            opacity: 0.7;
                        }
                    }
                }
                span {
                    font-weight: 400;
                    padding-right: 8px;
                    white-space: nowrap;
                    color: rgba($color-text, 0.5);
                }

                strong {
                    font-weight: 500;
                    color: $color-text;
                    padding-right: 2px;
                    word-break: break-word;
                }

                .pi-remove {
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                    background-color: transparent;

                    &:hover {
                        i {
                            color: $color-red;
                        }
                    }

                    i {
                        font-size: 17px;
                        @include transition(all 0.2s);
                        color: rgba($color-text, 0.7);
                    }
                }
            }
        }
    }
}