.tab {
  margin-bottom: $spacer*2;

  .nav-tabs {
    border: 0;
  }

  .nav-tabs .nav-link {
    background: $tab-link-bg;
    color: $tab-link-color;
    padding: $tab-link-padding;
    border: 0;

    &.active {
      background: $tab-link-active-bg;
      color: $tab-link-active-color;
    }

    &:hover:not(.active) {
      color: $link-color;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .tab-content {
    background: $white;
    padding: $tab-content-padding;
    box-shadow: $box-shadow;
    border-radius: $tab-content-border-radius;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@each $color, $value in $theme-colors {
  .tab-#{$color} {
    @include tab-variant($value);
  }
}

.tab-title {
  font-size: $font-size-lg;
}

.tab-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  .nav-link {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
  }
}

.tab-vertical .tab-content {
  overflow: auto;
}


.tab-button-group {
  button {
    border: none;
    background-color: transparent;
    color: $gray-500;
    &:hover {
      background-color: $gray-300;
    }
    &.active {
      color: $black;
    }
  }
}

.custom-tabs {
  margin-bottom: 0;

  .card {
    margin-bottom: 0;
  }

  .tab-content {
    background: none;
    box-shadow: none;
    padding: 0;

    .card {
      box-shadow: none;
      border: none;
    }

    .card-body {
      padding: 0;
    }
  }

  .card-header {
    padding-bottom: 0;
  }
  
  .nav-tabs {

    .nav-item {
      margin-bottom: 0;
    }
    
    .nav-link {
      color: $gray-600;
      padding: 10px;
      border-bottom: 4px solid transparent;
      transition: all 0.2s;

      &.active {
        color: $orange;
        border-bottom-color: $orange;
        background: none;
      }

      &:hover:not(.active) {
        color: $orange;
      }
    }
  }
}

.nav-tabs {
  .nav-link {
    &.disabled {
      opacity: 0.4;
    }
  }
}