.table {
  overflow: hidden;
  min-height: 400px;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;

  .search {
    flex: 1;
    min-width: 300px;
  }

  .button {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .modal {
    border-radius: 8px;
    height: 80%;
    width: 70%;
  }

  .button {
    font-size: 16px;
  }
}
