// Impact summary line chart

.pi-analytics-chart {
    .pi-line-chart {
        .pi-chart-legends {
            justify-content: flex-start;

            span {
                padding-right: 15px;
            }
        }
    }

    .pi-meta {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pi-chart-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pi-checkbox {
            padding-left: 10px;
            padding-right: 5px;
        }
    }
}