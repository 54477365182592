@media (min-width: 801px) {
    .pi-modal {
        &.pi-full-page {
            .pi-modal-header {
                padding-left: 25px;
                padding-right: 25px;

                .pi-back-btn {
                    display: none;
                    &.pi-fullPage-back-btn {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .pi-modal {
        .pi-modal-header {
            h4 {
                font-size: 14px;
            }
        }

        .pi-modal-footer {
            padding-left: 15px;
            padding-right: 15px;
            justify-content: center;

            &.pi-fixed {
                position: fixed;
                bottom: 0;
                width: 100%;
                z-index: 4;
            }
        }

        &.pi-full-page {
            margin: 0;
            height: 100%;
            background-color: #fff;

            .pi-close {
                display: none;
            }

            form {
                height: 100%;
                overflow: auto;
            }

            .pi-modal-body {
                overflow: auto;

                &:not(.no-padding) {
                    padding: 40px 30px 120px !important;
                }
            }
            .pi-teams-groups-modal {
                height: 100%;
                &.pi-modal-body {
                    padding: 40px 30px 0 !important;
                }
            }
            .pi-admins-table {
                height: 100%;
            }
        }
        &.pi-full-height-resp {
            .pi-modal-body {
                overflow: auto;

                &:not(.no-padding) {
                    padding: 0 0 120px 0 !important;
                }

                .pi-add-location-table {
                    //height: 100%;
                }
            }
        }

        &.pi-two-column-form {
            .pi-columns {
                flex-wrap: wrap;
            }

            .pi-column {
                width: 100%;
                &:not(:last-of-type) {
                    margin-bottom: 20px;
                }
            }
        }

        &.pi-add-device-modal {
            .modal-content {
                height: 100%;
                .pi-modal-body {
                    min-height: calc(var(--app-height) - 160px);
                    overflow: auto;
                    padding-bottom: 120px;
                }
            }
        }
    }

    .pi-inner-scroll {
        max-height: calc(100% - 82px);
    }
}