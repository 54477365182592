.pi-image-input {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid $color-border;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: $color-light-gray;
  margin-bottom: 0px !important;
  & * {
    cursor: pointer;
  }

  & .pi-image-input-element {
    display: none;
  }

  &-title {
    border: 1px solid $border-light-gray;
    border-radius: 4px;
    margin-top: 18px;
    padding: 8px 12px;
  }

  & label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0px !important;
    & i {
      font-size: 20px;
    }

    & p {
      margin-bottom: -10px;
    }
  }

  & .image-background {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 316px;
    overflow: hidden;
  }

  & .pi-cancel {
    position: absolute;
    top: 5px;
    border: 0;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    & i {
      color: $color-red;
      font-size: 18px;
    }
  }
}
