// Verfification code input field

.pi-verification-field {
    margin: auto;
    width: auto !important;

    & > div {
        display: flex;
        justify-content: center;
    }

    input {
        height: 41px;
        margin: 0 5px;
        font-size: 16px;
        line-height: 39px;
        color: $color-text;
        border-radius: $radius-sm;
        @include transition(all 0.2s);
        font-family: $font-family-base;
        caret-color: $color-text !important;
        border: 1px solid $color-border !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        text-transform: uppercase;

        &:focus {
            outline: none;
            border-color: $color-text !important;
        }
    }

    &.pi-invalid {
        input {
            border-color: $color-red !important;
        }
    }
}

body {
    &>iframe {
        display: none;
    }
}
