.timeline {
    list-style-type: none;
    position: absolute;
    top: 15px;
    left: 15px;
    width: calc(100% - 15px);
    right: 0;

    &:before {
      content: ' ';
      background: $gray-300;
      display: inline-block;
      position: absolute;
      left: 9px;
      width: 2px;
      height: calc(100% - 10px);
      z-index: 1;
    }
}
.timeline-item {
    &:before {
      content: ' ';
      background: $white;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid $primary;
      left: 0;
      width: 20px;
      height: 20px;
      z-index: 1;
    }
}

.historyBody{
  overflow-y: auto;
  height: 370px;
  position: relative;
}