.pi-detail-item {
    position: relative;

    &:not(.pi-layout-full) {
        max-width: 50%;
    }

    &:not(.pi-center):first-of-type {
        padding-right: 20px;
    }

    &:not(:first-of-type):not(.pi-no-p) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.pr-0 {
        padding-right: 0 !important;
    }

    &.pi-sp-sm {
        padding-right: 10px !important;

        &:not(:first-of-type) {
            padding-left: 10px !important;
        }
    }

    &:last-of-type {
        &::before {
            display: none;
        }
    }

    &::before {
        content: '';
        width: 1px;
        height: 100%;
        display: block;
        max-height: 41px;
        position: absolute;
        background-color: $color-border;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
    }

    &:not(.pi-size-lg) {
        strong, p {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &.pi-center {
        strong {
            justify-content: center;
        }
    }

    &.pi-size-lg {
        strong {
            font-size: $md;
        }

        p {
            font-size: $lg + 4px;
        }
    }

    &.pi-size-sm {
        strong {
            font-size: $sm;
        }

        p {
            font-size: $sm;
        }

        &.pi-type-block {
            p {
                margin-bottom: 0;
            }
        }
    }

    &.pi-type-block {
        p {
            display: block;
            margin-bottom: 4px;
        }
    }

    &.pi-type-inline {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            padding-right: 8px;
            display: inline-block;
        }
    }

    // Custom alignment
    &.pi-child-aligned {
        strong {
            padding-top: 6px;
        }
    }

    strong, p {
        font-weight: 500;
        line-height: 25px;
        color: $color-text;
    }

    p {
        opacity: 0.7;
    }

    strong {
        display: flex;
        position: relative;
        align-items: center;

        i {
            top: -1px;
            font-size: 22px;
            padding-left: 5px;
            position: relative;
            display: inline-block;
        }
    }

    .toggle-switch {
        span {
            margin-left: 0;
            text-align: left;
        }
    }
}

.pi-signal-label {
    display: flex;
    margin-left: 10px;
    position: relative;
    padding-left: 10px;

    &::before {
        content: '';
        width: 1px;
        height: 100%;
        display: block;
        max-height: 14px;
        position: absolute;
        background-color: $color-border;
        top: 5px;
        left: 0;
    }

    .pi-signal-icon {
        position: relative;
        margin-left: 10px;
        top: -4px;
    }
}

.pi-item-baseline {
    strong, .pi-signal-label {
        float: left;
    }
}
