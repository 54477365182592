body {
  max-height: 100vh;
  overflow: hidden;
}

.main {
  width: 100%;
  min-height: 100%;
  min-width: 0;
  transition: $sidebar-transition;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(md) {
    overflow-y: hidden;
  }
}

#ie-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
