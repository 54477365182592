// Global buttons styles

.pi-btn {
  font-weight: 500;
  text-transform: uppercase;
  @include transition(all 0.2s);

  // Buttons size modifications
  &:not(.pi-size) {
    height: 41px;
    border-radius: $radius-sm;
  }

  &.pi-size {
    &-sm {
      height: 30px;
      font-weight: 400;
      line-height: 30px;
      font-size: $sm - 1;
      text-transform: none;
      border-radius: $radius-xs;
    }
  }

  &:not(.pi-icon) {
    padding: 0 20px;
  }

  &.pi-icon {
    &:not(.pi-size-sm) {
      padding: 0 8px;
    }

    img {
      display: block;
      backface-visibility: hidden;
    }

    i {
      font-size: 24px;
    }

    &.pi-size-sm {
      padding-left: 5px;
      padding-right: 5px;

      i {
        font-size: 18px;
      }
    }
  }

  &.pi-filled {
    border: 0;

    @each $key, $val in $button-colors {
      &.pi-#{$key} {
        color: #fff;
        background-color: $val;
      }
    }

    &.pi-white {
      color: $color-text;
      background-color: #fff;
    }

    &.pi-dark {
      color: #fff;
      background-color: $color-text;
    }

    &.pi-transparent {
      background-color: transparent;
    }

    &.pi-filled {
      &[disabled],
      &.pi-disabled {
        opacity: 0.5;
      }
    }
  }

  &.pi-outline {
    color: $color-text;
    background-color: #fff;
    border: 1px solid $color-border;

    @each $key, $val in $button-colors {
      &.pi-#{$key} {
        border-color: $val;
      }
    }

    &:not(.pi-size-sm) {
      line-height: 39px;
    }

    &.pi-white {
      &.pi-active {
        color: #fff;
        border-color: $color-text;
        background-color: $color-text;

        i {
          color: rgba(#fff, 0.6);
        }
      }
    }

    &[disabled],
    &.pi-disabled {
      opacity: 0.5;
    }
  }

  &.pi-link {
    border: 0;
    background-color: transparent;

    @each $key, $val in $button-colors {
      &.pi-#{$key} {
        color: $val;
      }
    }
  }

  &.pi-shadow {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &.pi-with-arrow {
    display: flex;
    align-items: center;

    &:not(.pi-width-full) {
      justify-content: space-between;
    }

    &.pi-width-full {
      justify-content: center;
    }

    i {
      font-size: 22px;
    }
  }

  &.pi-left-icon {
    white-space: nowrap;
    i {
      padding-right: 8px;
    }
  }

  &.pi-right-icon {
    i {
      padding-left: 8px;
    }
  }

  &.pi-width-xs {
    min-width: 60px;
  }

  &.pi-width-sm {
    min-width: 120px;
  }

  &.pi-width-lg {
    min-width: 250px;
  }

  &.pi-width-full {
    min-width: 100%;
  }
}

.pi-btn-outline {
  width: 100%;
  height: 41px;
  display: flex;
  font-weight: 400;
  line-height: 39px;
  color: $color-text;
  align-items: center;
  padding: 0 15px 0 22px;
  border-radius: $radius-sm;
  background-color: transparent;
  @include transition(all 0.2s);
  justify-content: space-between;
  border: 1px solid $color-border;

  &:not([disabled]):hover,
  &.active {
    color: $color-text;
    border-color: $color-text;
  }

  i {
    font-size: 20px;
  }

  &[disabled],
  &.pi-disabled {
    opacity: 0.5;
  }
}

.pi-btn-icon-clean {
  border: 0;
  padding: 0;
  background-color: transparent;

  &.pi-size-md {
    font-size: 24px;
  }
}

@media (hover: hover) {
  .pi-btn {
    &:hover {
      opacity: 0.9;
    }
  }
}

