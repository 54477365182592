// React Tooltip styles

.pi-tooltip {
    padding: 0 25px 20px;
    opacity: 1 !important;
    border-radius: $radius-md !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::before, &::after {
        display: none;
    }

    &.pi-size-lg {
        max-width: 300px;
    }

    &:not(.pi-size-lg) {
        max-width: 245px;
    }

    h5 {
        font-size: $sm;
        font-weight: 500;
        margin-top: 10px;
    }

    p {
        font-size:  $sm;
        font-weight: 400;
        margin-bottom: 5px;
        padding-bottom: 0;

        strong {
            font-size: $sm;
        }
    }
}