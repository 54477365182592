.content {
  padding: 0;
  //font-family: $font-body;
  flex-grow: 1;
  //margin-top:75px;
  //min-height: calc(100vh - 136px);

  @include media-breakpoint-down(md) {
    width: 100vw;
    max-width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer*1.5;
  }
}
