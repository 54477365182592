@media (max-width: 768px) {
    .pi-title-container {
        .pi-step-indicator {
            margin-top: 15px;
        }

        .pi-title {
            padding-top: 15px;

            h5 {
                padding-right: 0;
            }

            &::before {
                top: 0;
                left: -41px;
                width: calc(100% + 41px);
            }
        }
    }
}