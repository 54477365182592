.pi-teams-wrapper {
  height: 100dvh;
  display: flex !important;
  flex-direction: column !important;
  background-color: #fff;

  &.pi-state-group {
    .pi-teams-sidebar {
      display: none;
      flex: 1;
    }

    .pi-team-content {
      max-width: 100%;
    }
  }

  .pi-page-title {
    width: 100%;
  }

  .pi-team-content {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    flex: 1;

    & > .pi-content-tabs {
      overflow: unset !important;
    }

    &:not(.pi-search-mode) {
      .pi-content-tabs {
        .pi-tab-navigation {
          .pi-tab-nav-item {
            width: 30%;
          }
        }
      }
    }

    &.pi-search-mode {
      .pi-content-tabs {
        .pi-tab-navigation {
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: unset !important;

          .pi-tab-nav-item {
            width: 25%;
            min-width: 90px;
          }
        }
      }
    }
  }

  .pi-content-tabs {
    .pi-tab-navigation {
      border: 0;
      padding: 0 50px;
      border-radius: 0;
      border-bottom: 1px solid #dee2e6;

      .pi-tab-nav-item {
        a {
          text-align: left;
        }
      }
    }
  }

  .pi-search-bar {
    display: flex;
    padding: 15px 25px;
    align-items: center;
    background-color: $color-bg;
    justify-content: space-between;
    border-bottom: 1px solid $color-border;

    .pi-text-field {
      padding-right: 10px;
    }

    .pi-btn span {
      white-space: nowrap;
    }

    &.pi-subgroup {
      .pi-text-field {
        width: calc(100% - 90px);
      }
    }

    &:not(.pi-subgroup) {
      .pi-text-field {
        width: 100%;
      }
    }

    .pi-row-count {
      max-width: 90px;
      font-weight: 500;
      text-align: right;

      strong {
        padding-left: 3px;
      }

      span {
        color: rgba($color-text, 0.7);
      }
    }
  }

  .pi-table-wrapper {
    padding: 0 25px 25px;
    background-color: $color-bg;
  }
}

.pi-groups-tab {
  > ul {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;

    > li {
      width: 25%;
      padding: 10px;
    }
  }
}

.pi-subgroup-wrapper {
  padding: 10px 20px;

  .pi-subgroup-body {
    border: 1px solid $color-border;
    border-top: 0;
    margin-bottom: 6px;
    background-color: $color-bg;
    border-bottom-left-radius: $radius-md;
    border-bottom-right-radius: $radius-md;

    .pi-tab-navigation {
      padding: 0 25px;
    }
  }

  .pi-active-action {
    .pi-collapse-header {
      z-index: 3;
    }
  }

  .pi-collapse-header {
    position: relative;

    .pi-header-action {
      position: absolute;
      right: 95px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.pi-collapse.show {
  visibility: visible !important;
  & .collapse.show {
    visibility: visible !important;
  }
}

@media screen and (min-width: 768px) {
  .pi-team-content {
    overflow: auto;
  }
}
