.messagesAndTypes {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.selectMessageHelper {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(53, 64, 82, 0.54);
  margin-top: 50px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px;
  background-color: #f5f8fa;
  border-top: 1px solid #e4e4e4;
  .actionBar {
    display: flex;
    width: 100%;
    max-width: 930px;
    margin: auto;
    gap: 10px;

    .submit {
      background-color: #faa528 !important;
      color: white !important;
    }
    .clear {
      padding: 0px 25px !important;
    }
  }
}
