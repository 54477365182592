.wrapper{
  align-items: stretch;
  display: flex;
  width: 100%;
}

.wrapper-boxed {
  max-width: 1440px;
  margin: 0 auto;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.schedule{
  .time-input{
    &:focus{
      outline: none;
    }
    background-color: white;
    display: inline-flex;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 4px;
    color: hsl(0,0%,50%);
    input {
      &:focus{
        outline: none;
      }
      border: none;
      color: hsl(0,0%,50%);
      text-align: center;
    }
  }
  .day{
    width: 100%;
    label{
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .day-input{
    select{
      &:focus{
        outline: none;
      }
      background-color: white;
      border: 1px solid hsl(0,0%,80%);
      border-radius: 4px;
      color: hsl(0,0%,50%);
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.date, .location, .severity, .graph, .upload, .confirmed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //width: 500px;
  span{
    &:first-child{
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
.location, .graph{
  display: block;
}
.location{
  height: 500px;
  .mapContainer{
    padding-bottom: 20px;
    height: 100%;
  }
}
.graph, .location{
  width: auto;
}
.upload{
  input{
    width: 188px;
  }
}
.graph{
  span{
    &:first-child{
      display: block;
    }
  }
}
.uploadedImagesTitle{
  display: flex;
  justify-content: center;
  span{
    font-size: 1rem;
    font-weight: 600;
  }
}
.uploadedImagesContainer{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .img{
    width: 200px;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
  .dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    //left: 20%;
    top: 37%;
    .image {
      width: 300px;
    }
  }
}
td{
  text-align: center;
}
.activity{
  .sortable{
    .sort-wrap {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 1199px) {
    .forMobile{
      margin-bottom: 10px
    }
  }
}
.activity{
  .sortable{
    .sort-wrap {
      justify-content: center;
    }
}}

//@media only screen and (max-width: 856px) {
//  table{
//    thead{
//      tr {
//        th {
//          div, span{
//            white-space: nowrap;
//            overflow: hidden;
//            text-overflow: ellipsis;
//            width: 50px;
//            display: inline-block !important;
//          }
//        }
//      }
//    }
//    tbody{
//      tr{
//        td{
//          white-space: nowrap;
//          overflow: hidden;
//          text-overflow: ellipsis;
//          width: 50px;
//        }
//      }
//    }
//  }
//}
//@media only screen and (max-width: 380px) {
//  table{
//    thead{
//      tr {
//        th {
//          div, span{
//            white-space: nowrap;
//            overflow: hidden;
//            text-overflow: ellipsis;
//            width: 30px;
//          }
//        }
//      }
//    }
//  }
//}

