// Info paragraph styles

.pi-info-string {
    display: flex;
    align-items: flex-start;

    &:not(.pi-note) {
        span {
            font-size: $md;
            font-weight: 500;
            color: $color-text;
        }
    }

    &.pi-note {
        span {
            font-weight: 400;
            font-style: italic;
            font-size: $md - 1px;
            max-width: 700px;
        }
    }

    &.pi-size {
        &-md {
            strong, span, a {
                font-size: $md;
            }
        }
        &-sm {
            strong, span, a {
                font-size: $sm;
            }
        }
    }

    i {
        font-size: 22px;
        padding-right: 12px;
        color: rgba($color-text, 0.6);
    }

    strong {
        font-weight: 500;
        padding-right: 12px;
        color: rgba($color-text, 0.7);
    }

    span {
        font-weight: 500;
        color: $color-text;
        text-align: left;
    }

    a {
        font-weight: 500;
        color: $color-primary;
        text-align: left;
        text-decoration-line: underline;
    }

    .pi-info-string-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .pi-info-label {
            padding-right: 0;
            margin-bottom: 4px;
        }
    }
}