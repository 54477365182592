@media (min-width: 1001px) {
    .pi-team-table {
        overflow-y: auto;
        min-height: 300px;
        max-height: calc(100vh - 300px);

        .pi-cell-holder {
            display: none;
        }
    }
}
@media (max-width: 1000px) {
    .pi-team-members-modal {
        .pi-table {
            .pi-list-resp {
                thead {
                    display: none;
                }

                tbody {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    tr {
                        display: block;
                        overflow: hidden;
                        padding-top: 10px;
                        position: relative;
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        background-color: #fff;
                        width: calc(50% - 5px);
                        border-radius: $radius-md;
                        border: 1px solid $color-border;
                        box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.04);

                        td {
                            border: 0;
                            width: 100%;
                            display: block;

                            &.pi-type-icon-btns {
                                position: absolute;
                                top: 11px;
                                right: 0;
                                width: 90px;

                                .pi-td {
                                    padding-left: 0;
                                    padding-right: 0;
                                }

                                .pi-icon-btn {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }

                            .pi-td {
                                padding-top: 3px;
                                padding-bottom: 3px;

                                p {
                                    font-weight: 500;
                                }

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pi-side-panel {
            position: fixed;
            max-width: 100%;
            padding-top: 30px;

            .pi-side-body {
                overflow-y: auto;
                padding-bottom: 40px;

                .pi-title-section {
                    .pi-back-btn {
                        display: block;
                    }
                }
            }
        }

        .modal-content {
            padding-bottom: 70px;
        }

        .pi-modal-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 4;
        }
    }
}

@media (max-width: 700px) {
    .pi-team-members-modal {
        .pi-table {
            .pi-list-resp {
                tbody {
                    tr {
                        width: 100%;
                    }
                }
            }
        }
    }
}