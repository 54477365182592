.aspect-container {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
}

.aspect-container_2_1 {
    width: 100%;
    padding-top: 50%;
    position: relative;
}

.aspect-container,
.aspect-container_2_1 {
    .content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0;
        margin: 0;
    }
}

.file-input-wrapper {
    color: white;
    font-family: "Gotham-Bold";
    cursor: pointer;
    position: relative;
    text-align: center;
    width: 100%;

    > input[type="file"] {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
    }
}

.avatar-container {
    width: 128px;
    height: 128px;
    margin: auto;

}

.avatar {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    background-color: aliceblue;
}

.relative {
    position: relative;
}

.absolute-top-right {
    position: absolute;
    top: 8px;
    right: 8px;
}

.float-right {
    float: right;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.horz-container {
    display: flex;
    flex-direction: row;
}

.vert-container {
    display: flex;
    flex-direction: column;
}

.horz-container, .vert-container {
    &.space-between {
        justify-content: space-between;
    }
    &.full {
        flex: 1;
    }
    &.start {
        align-items: flex-start;
    }
    &.center {
        align-items: center;
    }
    &.justify-center {
        justify-content: center;
    }
}

.hide {
    visibility: hidden;
}

.device-map-container {
    & > div {
        flex: 1;
        height: 100%;
    }
}

.auth-container {
    background-image: url('../../../../public/img/login_back.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.h-100 {
    align-items: center !important;
    justify-content: center !important;
}

.teamlist {
    display: grid;
    grid-template-columns: 25% 73%;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.teamlist_search {
    height: 40px;
    position: relative;
    padding-left: 20px;
    font-weight: 600;
    border: 1px solid #E4E4E4;
    color: black;
    font-size: 14px;
    border-radius: 3px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.teamlist_search:focus {
    outline: 0px;
}

.search_icon {
    height: 15px;
    width: 15px;
    //position: absolute;
    left: 19%;
    font-weight: normal;
}

.teams_search {
    border: 1px solid #E7E7E7;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    height: calc(100vh - 78px);
    //min-height:974px;
}

.search_border {
    border-bottom: 1px solid #E7E7E7;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;
    padding-left: 10px;
    padding-right: 10px;
}

/*.search_border_con:nth-child(1){
    border-left: 1px solid #b9b3b3;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 0 #D8D8D8, inset 0 1px 0 0 #D8D8D8;
}*/
.search_border_con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 1px solid #b9b3b3;
    //border-bottom: 1px solid #b9b3b3;
    height: 64px;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 0 #D8D8D8, inset 0 1px 0 0 #D8D8D8;
    cursor: pointer;
}

.teamlistIcon {
    height: 21px;
    width: 21px;
    border: 1px solid #979;
    border-radius: 50%;
    background-color: #979797;
}

.plus_icon {
    height: 40px;
    width: 46px;
    border: 1px solid #E4E4E4;
    font-size: 17px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_bg {
    background-color: rgba(255, 255, 255, 0);
}

.team_heading {
    color: #4B5258;
    font-size: 17px;
    font-weight: 700;
}

.team_heading_second {
    color: #4B5258;
    //color:  rgb(185, 179, 179);
    font-size: 12px;
}

.group_heading {
    display: grid;
    grid-template-columns: 12% 63% 25%;
    background: #F7F7F7;
    height: 127px;
}

.group_content {
    position: relative;
}

.group_name {
    color: #4B5258;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 20px;
    padding-left: 0%;
    position: relative;
    padding-top: 35px;
}

.sub_group_name {
    color: #FFFFFF;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 20px;
    padding-left: 0%;
    position: absolute;
    padding-top: 35px;
    top: -25px;
    left: 33px;
}

.grp_details {
    color: #4B5258;;
    font-weight: bold;
    padding-left: 0%;
    font-size: 16px;
}

.det_pad {
    padding: 0px 10px;
}

.pencil_svg {
    width: 15px;
    height: 16px;
    margin-left: 6px;
    margin-bottom: 5px;
    cursor: pointer;
}

.save_svg {
    width: 15px;
    height: 16px;
    margin-left: 6px;
    cursor: pointer;
}

.round_ball {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: black;
    justify-self: center;
    align-self: center;
    margin-bottom: 0;
}

.member_prof {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: black;
    justify-self: center;
    align-self: center;
    margin-bottom: 0;
}

.newgrp_btn {
    border: 1px solid #b9b3b3;
    padding: 10px;
    align-self: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    justify-self: center;
    height: 40px;
    width: 193px;
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    background-color: #FAA528;
}

.bor_bot {
    width: 98%;
    height: 1px;
    background-color: #E9E9E9;
    margin-left: 20px;
}

.card_grp {
    margin: 12px;
    height: 165px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.crd_grp_cont {
    text-align: center;
    font-weight: bold;
    color: #4B5258;
    margin-top: 20px;
    font-size: 18px;
}

.card_grp_grid {
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 25% 25% 25% 25%;
    background: #F7F7F7;
}

.grp_mem {
    display: grid;
    grid-template-columns: 50% 50%;
}

.cont_cent {
    text-align: center;
    margin-top: 18px;
}

.cont_cent p {
    margin: 0px;
}

.cont_cent p:nth-child(1) {
    font-size: 40px;
    font-weight: 300;
    color: #FAA528;;
}

.cont_cent p:nth-child(2) {
    font-size: 16px;
    color: #4B5258;
}

.modal-content-teams > .modal-content {
    border-radius: 0px;
}

.modal-content-teams > .modal-content > .modal-header {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.modal-content-teams > .modal-content > .modal-header > .modal-title {
    font-weight: bold;
    font-size: 20px;
}

.modalMainDimensions {
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 62px 63px 0 rgba(0, 0, 0, 0.26);
}

.new-grp-label {
    color: #4B5258;
    text-align: left;
    font-size: 14px;
}

.modalInput {
    box-sizing: border-box;
    height: 40px;
    width: 259px;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.team-input {
    border: 2px solid rgb(185, 179, 179);
}

.team-input:focus {
    outline: 0px;
}

.grp-create {
    height: 40px;
    width: 259px;
    border-radius: 4px;
    background-color: #FAA528;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    &[disabled] {
        opacity: 0.6;
    }
}

.grp-create:focus {
    outline: 0px;
}

.grp-create:hover {
    color: #ffffff !important;
    text-decoration: none !important;
}

.cls-btn-teams {
    text-align: end;
    padding-top: 5px;
    padding-right: 15px;
    font-size: 18px;
    cursor: pointer;
}

.left_svg {
    width: 8px;
    margin-right: 20px;
    margin-left: 7px;
}

.bck_lst {
    color: #4B5258;
    padding: 12px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.bck_lst_table {
    color: #4B5258;
    padding: 0px;
    margin: 0px;
    background-color: #F7F7F7;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.sub_group_heading {
    //margin-left: 15px;
    //border-top: 1px solid  rgb(185, 179, 179);
    background: #F7F7F7;
}

.sub_grp_title {
    margin-left: 6px;
    font-size: 20px;
    margin-top: 5px;
}

.sub_grp_edit {
    color: #ffffff;
    background-color: #32393E;
    height: 50px;
    padding-left: 20px;
    font-size: 20px;
    position: relative;
    margin-left: 15px;
    padding-top: 10px;
}

.pencil_svg_sub {
    width: 15px;
    height: 16px;
    position: absolute;
    left: 19%;
    top: 32%;
}

.tabs_comp {
    margin-left: 15px;
    display: grid;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
    height: 60px;
    background-color: #F2F2F2;
    grid-template-columns: 15% 15% 15% 55%;
}

.members_tab {
    background-color: #ffffff;
    font-weight: bold;
}

.tabs_comp > p {
    margin-bottom: 0px;
    padding-top: 16px;
}

.search_border_sub {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    position: relative;
    display: grid;
    grid-template-columns: 21% 51% 5% 17% 18%;
    grid-gap: 7px;
    margin-left: 15px;
    background-color: #ffffff;
}

.search_icon_tabs {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 40%;
    left: 19%;
    font-weight: normal;
}

.teamlist_search_tabs {
    height: 40px;
    position: relative;
    padding-left: 16px;
    width: 194px;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.del_btn_teams {
    padding: 8px 10px;
    color: rgb(185, 179, 179);
    text-align: center;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.fil_btn_teams {
    padding: 8px 5px;
    text-align: center;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.add_teams_sub {
    background-color: #717171;
    text-align: center;
    border: 1px solid rgb(185, 179, 179);
    border-radius: 4px;
    background-color: #FAA528;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 0px;
}

.teams_table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
}

.teams_th {
    border-bottom: 1px solid rgb(185, 179, 179);
    border-top: 1px solid rgb(185, 179, 179);
    text-align: center;
    height: 40px;
}

.tableDropdown:active, .tableDropdown:focus {
    background-color: #FAA528;
}

.down_arrow {
    width: 8px;
    margin-left: 10px;
}

.add_sub_grp {
    border: 1px dashed #FAA528;
    padding: 8px 10px;
    border-radius: 3px;
    color: #FAA528;
    margin-top: 10px;
    margin-left: 15px;
    cursor: pointer;
    text-align: center;
}

.modal-content {
    border-radius: 8px !important;
    overflow: hidden;
}

.tableCheckboxCustom {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    > span {
        color: #34495e;
        padding: 0.5rem 0.25rem;
    }
    > input {
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #e4e4e4;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 4px;
        outline: none;
        //Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
        transition-duration: 0.3s;
        background-color: $white;
        cursor: pointer;
        &:checked {
            border: 1px solid #e4e4e4;
            background-color: #faa528;
            + span::before {
                content: '\2713';
                display: block;
                text-align: center;
                color: $white;
                position: absolute;
                left: 0.35rem;
                top: 0.075rem;
            }
        }
        &:active {
            border: 2px solid #e4e4e4;
        }
    }
}

.selection-cell {
    position: relative;
    width: 36px;
    height: 36px;
    .tableCheckbox {
        display: flex;
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        span {
            color: #34495e;
            padding: 0.5rem 0.25rem;
            height: 20px;
            width: 20px;
            border: 1px solid #e4e4e4;
            border-radius: 4px;
            transition-duration: 0.3s;
            background-color: $white;
            cursor: pointer;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
            &::before {
                content: none;
                display: block;
                text-align: center;
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                line-height: 18px;
            }
        }
        input {
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 20;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            &:checked ~ span {
                border: 1px solid #e4e4e4;
                background-color: #faa528;
                &::before {
                    content: '\2713';
                }
            }
        }
    }
}

.btn-secondary {
    color: black;
    background-color: transparent;
    border-color: transparent;
}

.btn-secondary:hover:not(:disabled):not(.disabled) {
    color: black;
    background-color: transparent;
    border-color: transparent;
}

.btn-secondary:focus:not(:disabled):not(.disabled) {
    color: black;
    background-color: transparent;
    border-color: transparent;
    outline: 0px;
}

.btn-secondary:active:not(:disabled):not(.disabled) {
    color: black;
    background-color: transparent;
    border-color: transparent;
    outline: 0px;
}

.show > .btn-secondary.dropdown-toggle {
    color: black;
    background-color: transparent;
    border-color: transparent;
}

.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.show > .btn-secondary.dropdown-toggle:active {
    box-shadow: none;
    outline: none;
}

.new_mem_create {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
}

.for_width > .modal-content > .modal-header > .modal-title {
    width: 70%;
}

.for_width > .modal-content > .modal-header > .modal-title > .modal-body {
    padding: 0px;
    padding-top: 20px;
}

.new-grp-label-add {
    text-align: left;
    font-size: 14px;
    margin-top: 21px;
    margin-bottom: 10px;
    margin-left: 77px;
}

.add_edit_mem {
    font-size: 18px;
}

.for-bor-create {
    margin-bottom: 1px;
    border-bottom: 1px solid rgb(185, 179, 179);;
}

.for_bold {
    font-weight: normal;
}

.members_tab_cursor {
    cursor: pointer;
}

.modalHeader {
    border-bottom: none;
}

.titleModel {
    color: #4B5258;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
}

.navigationTab {
    padding-top: 0;
    padding-bottom: 10px;
    color: #4B5258;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.19px;
    line-height: 14px;
    text-align: center;
    border-bottom: 3px solid transparent;
}

.selectedBorderBottom {
    border-bottom: 2px solid #FAA528;
}

li > a:hover {
    color: #4B5258 !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: none !important;
}

.nav-tabs .nav-link {
    border: none !important;
}

.insideNewTabContent {
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.modalInput:focus {
    outline: none;
}

.mainMemberTableBody > tr > td > div > div > button:focus {
    outline: none;
}

.teamlist_search_tabs:focus {
    outline: none;
}

.insideNewTabContent > div > input {
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 259px;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.insideNewTabContent > div > label {
    color: #4B5258;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}

.modalMemberHeading {
    color: #4B5258;
    font-size: 20px;
    font-weight: bold;
}

.modalSearchInput {
    display: block;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    color: #000000;
    font-size: 14px;
}

.modalTableHead {
    color: #4B5258;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.19px;
    line-height: 14px;
    text-align: center;
}

.modalTableHead tr > th, .modalTableBody tr > td {
    border-top: none;
    border-bottom: 1px solid #E4E4E4;
}

.modalTableBody {
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 16px;
    text-align: center;
}

div.memberTableWrapper table tr > td {
    padding: 0.1rem !important;
}

.mainMemberTableHeader {
    color: #4B5258;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.19px;
    line-height: 14px;
    text-align: center;
}

.mainMemberTableBody {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 16px;
    text-align: center;
}

.mainMemberTableWrapper .table > .mainMemberTableBody td {
    padding: 0.1rem;
}

.mainMemberTableBody button.dropdown-item:hover {
    background-color: #FAA528;
    color: #FFF;
}

th.sortable:focus {
    outline: none !important;
}

.dash_welcome {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-top: 30px;
}

.dash_msg {
    text-align: center;
    font-weight: bold;
}

.dash_card {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 50px;
    grid-gap: 10px;
}

.dash_card_height {
    height: 234px;
    text-align: center;
}

.dash_card_height_ptag {
    padding-top: 50px;
    font-size: 35px;
}

.dash_p_bold {
    font-weight: bold;
    font-size: 18px;
}

.dashboard_maps {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 80px;
}

.dash_card_height_map {
    height: 473px;
    margin: 13px 9px 3px 3px;
    border-radius: 8px;
}

.dash_card_height_map > .card-body {
    padding: 40px 0px;
}

.map_headings {
    display: grid;
    grid-template-columns: 50% 50%;
}

.map_headings > p:nth-child(2) {
    text-align: end;
    padding-right: 15px;
    font-size: 25px;
}

.map_headings > p:nth-child(1) {
    padding-left: 35px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    color: #4B5258;
}

.graph_headings {
    display: grid;
    grid-template-columns: 65% 35%;
}

.graph_headings > p:nth-child(2) {
    text-align: end;
    padding-right: 15px;
    font-size: 25px;
}

.graph_headings > p:nth-child(1) {
    padding-left: 13px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    color: #4B5258;
    margin: 0px;
    padding-top: 7px
}

.activity_impact_grpah {
    margin: 0 !important;
    border: none !important;
}

#rm_underline > .MuiInput-underline:before {
    border: 0px !important;
}

#rm_underline > .Mui-focused::before {
    border: 0px !important;
    outline: 0px;
}

#rm_underline {
    margin: 0px;
}

#rm_underline > .Mui-focused::after {
    border: none !important;
    outline: 0px;
}

#rm_underline > .MuiInput-underline > SVG {
    fill: #FAA528;
}

#rm_underline > .MuiInput-underline > .MuiSelect-select:focus {
    background: none;
}

.dash_graph_index {
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
}

.dash_graph_index_teams {
    display: grid;
    grid-template-columns: -webkit-max-content auto;
    grid-template-columns: max-content auto;
}

.dash_graph_index {
    display: grid;
    grid-template-columns: auto auto;
}

.dash_graph_index_teams {
    display: grid;
    grid-template-columns: auto;
}

.profile_style > tbody > tr > td {
    text-align: left !important;
}

.profile_style > tbody > tr > td:first-child {
    color: #4B5258;
    font-family: Roboto;
    width: 50px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.22px;
    line-height: 25px;
}

.profile_style {
    margin-top: 27px;
}

.profile_style > tbody > tr > td:nth-child(2) {
    color: #4B5258;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 25px;
    padding-left: 30px;
}

.profile_style > tbody > tr > td {
    text-align: left !important;
}

.profile_heading {
    color: #4B5258;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.for-map-mar {
    margin-top: 80px;
}

.dash_maps_index {
    display: grid;
    grid-template-columns: auto;
}

.mar-top-maps {
    height: 100vh !important;
    min-height: 770px;
    margin-bottom: 0;
}

#filterpopup {
    display: flex !important;
}

.arrow_icon_mobile {
    display: none
}

.mobile_toggle_data > tr > td:nth-child(1) {
    color: #4B5258;
    font-family: Roboto;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.22px;
    line-height: 26px;
    width: 43%;
}

.mobile_toggle_data > tr > td:nth-child(2) {
    color: #4B5258;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.22px;
    line-height: 26px;
}

.mobile_view_table_height {
    padding-top: 200px;
}

.css-kpgttc-container {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: auto !important;
}

.css-kpgttc-container:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

// .css-bg1rzq-control:hover{
//     border-radius: 26px !important;
//     outline: none !important;
//     box-shadow: none !important;
// }
.css-1szy77t-control {
    outline: none !important;
    box-shadow: none !important;
}

.css-1ctakec-container {
    border: 0px !important;
}

.teams_mobileView {
    display: none;
}

.mobile_view {
    display: none;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 0px !important;
    margin: 0px !important;
}

.grp_modal > .react-bootstrap-table {
    height: auto !important;
    min-height: auto !important;
}

.grp_modal > .react-bootstrap-table > table > tbody > tr {
    height: 40px;
}

.tableWrapper td {
    white-space: pre-wrap !important
}

.activity-bottom-tab {
    width: 85% !important;
}

.TitleTextBox {
    box-sizing: border-box;
    width: 100%;
    height: auto !important;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.CommentsTextBox {
    box-sizing: border-box;
    width: 100%;
    height: auto !important;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.TotalTextBox {
    box-sizing: border-box;
    height: auto !important;
    width: 100%;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.NewWorkOrder {
    box-sizing: border-box;
    height: 55px !important;
    border: 2px dashed orange;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 15px;
}

.total-label {
    width: 100%;
    position: relative;
    left: 167%;
}

.price-value {
    width: 100%;
    position: relative;
    left: 245%;
}

.work-order-container {
    height: 6vw;
}

.InnerNewWorkOrder {
    color: orange;
    font-size: 12px;
}

.NewCost {
    box-sizing: border-box;
    height: 55px !important;
    border: 2px dashed orange;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 15px;
}

.InnerNewCost {
    color: orange;
    font-size: 12px;
}

.map-screen {
    display: block !important;
}

.activity-gp-modal {
    word-wrap: break-word;
}

.filterpopup-time-input {
    position: relative;
    left: -16%;
}

.on-row-selected {
    background-color: #C0C0C0 !important;
}

.gm-style .gm-style-iw-d {
    overflow: auto !important;
}

.table tr:last-child {
    border-bottom: 1px solid #dee2e6;
}

.create-select {
    width: 160px;
    float: right;
    color: #000;
    [class$="ValueContainer"] {
        min-height: 28px !important;
        max-height: 28px;
    }
    [class$="IndicatorsContainer"] {
        min-height: 28px !important;
        max-height: 28px;
    }
    [class$="-Input"] {
        min-height: 28px !important;
        max-height: 28px;
        padding: 0px;
    }
    [class$="-control"] {
        min-height: 28px !important;
        max-height: 28px;

    }

}

.btn:not(:disabled):not(.disabled), .fc-unthemed .fc-button:not(:disabled):not(.disabled) {
    z-index: 0 !important;
}

.rdtPicker td, .rdtPicker th {
    line-height: 30px !important;
}

@media only screen and (max-width: 636px) {
    .profile_empty_td {
        width: 15%;
    }
    .addCost {
        width: 335px !important;
    }

    .map-screen {
        display: none !important;
    }
    .activity-bottom-tab {
        width: 100% !important;
    }
    .total-label {
        left: 167%;
    }
    .price-value {
        left: 245%;
    }
    .work-order-container {
        height: 23vw;
    }
    .new_sidebar {
        display: none !important;
    }
    .dash_graph_index {
        grid-template-columns: auto;
    }
    .tableContainer {
        // width: 100% !important;
        // margin-top: 150px;
        // border-radius: 20px !important;
        display: none !important;
    }
    .mobile_view {
        display: block !important;
        height: 100vh;
        width: 100%;
        border-radius: 10px;
    }
    .arrow_icon_mobile {
        display: block
    }
    .mobile_views {
        display: block !important;
    }
    .content {
        padding: 5px;
    }
    .container, .container-sm {
        max-width: 635px !important;
    }
    .mobile_topLine {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .mobile_topLine_color {
        height: 4px;
        width: 68px;
        border-radius: 2px;
        background-color: #D8D8D8;
        position: absolute;
        top: 17px;
        margin: 0px;
    }
    .m_nav_item > ul > div > li > a {
        height: 60px;
        padding-top: 20px;
        text-align: center;
        color: black;
        font-size: 16px;
    }
    .m_nav_item > ul {
        display: block;
        height: 60px;
    }
    .inputHeading_mobile {
        position: absolute;
        top: 130%;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        height: 67px;
    }
    .inputGroup {
        box-sizing: border-box;
        height: 42px !important;
        width: 82% !important;
        margin-left: 15px;
        margin-right: 10px !important;
    }
    .settingsIconWrapper {
        box-sizing: border-box;
        height: 42px !important;
        width: 15% !important;
        margin-right: 15px !important;
    }
    .settingsIconWrapperFilterFilled {
        box-sizing: border-box;
        height: 42px !important;
        width: 15% !important;
        margin-right: 15px !important;
    }
    .mobile_table {
        margin-top: 0px;
    }
    .lap_view {
        margin-top: 77px;
    }
    .mobile_tableData {
        border-top: 1px solid #c2bfbf;
        font-size: 14px;
        padding-top: 3%;
        font-family: Roboto;
        padding-bottom: 3%;
    }
    .mobile_tableData > a > span > span {
        color: black !important;
    }
    .mobile_tableData_bg > a > span > span {
        color: black !important;
    }
    .mobile_tableData_bg {
        border-top: 1px solid #c2bfbf;
        font-size: 14px;
        font-family: Roboto;
        padding-top: 6%;
        padding-bottom: 6%;
        background-color: #F8F8F8;
        box-shadow: inset 0 -1px 0 0 #D8D8D8, inset 0 1px 0 0 #D8D8D8;
    }
    .mobile_toggle_data > tr:last-child > td > span {
        color: #FAA528;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.19px;
        line-height: 14px;
        margin: 0px 10px;
    }

    .arrow_icon_mobile {
        // margin-left: 65%;
    }
    .tableMaintanance {
        display: none;
    }
    .lap_view {
        display: none;
    }
    .lifeCycleContainer {
        width: 100% !important;
        z-index: 100 !important;
    }
    .gm-style .gm-style-iw-c {
        max-width: 85vw !important;
        overflow: scroll !important;
        // top: -10px;
        padding: 0px;
        min-height: 500px !important;
    }
    .detailed_image {
        // width: 31% !important;
    }
    .css-1ctakec-container {
        width: 92vw !important;
    }
    .mobile_view_table_height {
        padding-top: 88vh;
    }
    .imageContainer {
        width: 94% !important;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #495057;
    }
    .nav-tabs .nav-link {
        border: none;
        border-top-left-radius: none;
        border-top-right-radius: none
    }
    .popup_mobView {
        display: grid;
        grid-template-columns: 20vw 20vw;
    }
    .device_details_lap {
        width: 22vw !important;
        height: 100% !important;
    }
    .deviceHeading {
        font-size: 14px !important;
        text-align: left;
    }
    .popup_dd {
        width: 22vw !important
    }
    .gm-style img {
        object-fit: cover !important;
    }
    .dragDropContainer {
        width: 90vw !important;
    }
    .headingcircularContent_parent {
        width: 90vw !important;
    }
    .content3InputGroup {
        width: 90vw !important;
        height: 100% !important;
        flex-wrap: inherit !important;
        align-items: center !important;
    }
    .content3InputGroup > input {
        margin: 10px !important;
    }
    .content3InputGroup > textarea {
        margin: 10px !important;
    }
    .imageUploader {
        width: 26vw !important;
        height: 26vw !important;
        margin-right: 10px;
    }
    .imageUploaderBackground {
        width: 26vw !important;
        height: 26vw !important;
        margin-right: 10px;
    }
    .imageUploadGroup {
        width: 90vw !important;
    }
    .content5TextArea {
        width: 90vw !important;
    }
    .mainTab {
        margin-top: 10px !important;
    }
    .circularTabContainer {
        width: 95% !important;
    }
    .image_detail_wid_he {
        height: 125px !important;
        width: 125px !important;
    }
    .css-txbzea-container {
        width: 50vw !important;
    }
    .teamlist {
        grid-template-columns: 96vw !important;
    }
    .teams_search {
        height: auto !important;
    }
    .card_grp_grid {
        grid-template-columns: auto !important;
    }
    .group_heading {
        grid-template-columns: 20% 80% !important;
        height: auto;
    }
    .round_ball {
        height: 12vw;
        width: 59%;
        margin-top: 20%;
    }
    .newgrp_btn {
        justify-self: auto !important;
        width: 90vw !important;
        margin-left: 17%;
    }
    .grp_details {
        font-size: 12px !important;
    }
    .frm_exis > .react-bootstrap-table > .table {
        min-width: 70vw !important;
        width: 70vw !important;
    }
    .lap_view_only {
        display: none !important;
    }
    .search_border_sub {
        grid-template-columns: 60% 14% 14% !important;
        grid-gap: 12px !important;
        margin-left: 0px !important;
    }
    .tabs_comp {
        grid-template-columns: 50% 50% !important;
        margin-left: 0px !important;
    }
    .tabs_comp > p:nth-child(3) {
        display: none !important;
    }
    .teamsTable > .react-bootstrap-table > .table {
        display: none;
    }
    .sub_grp_edit {
        margin-left: 0px !important;
    }
    .add_sub_grp {
        margin-left: 2px !important;
        margin-top: 12px !important;
        background-color: #ffffff !important;
    }
    .teams_mobileView {
        display: block !important;
    }
    .teams_mobile_usrName {
        color: #4B5258;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.22px;
    }
    .teams_edit_delete > div {
        box-sizing: border-box;
        height: 40px;
        border: 1px solid #E4E4E4;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        color: #FAA528;
        font-family: Roboto;
        font-size: 14px;
        display: grid;
        align-items: center;
        text-align: center;
    }
    .mobile_views_act {
        display: block !important;
    }
    .teams_edit_delete > div > a {
        color: #FAA528 !important;
    }
    .css-1nbjcf4-container {
        width: 90vw !important;
    }
    .react-bootstrap-table .table {
        min-width: auto
    }
    .css-1wxo2ea-container {
        width: 90vw !important;
    }
    .tabPanecontent {
        width: 90vw;
    }
    .deviceImageContainerlogo {
        width: auto !important;
        height: 32vw !important;
    }
    .deviceImageContainer {
        margin-left: 15px;
    }
    .new_sidebar {
        display: none !important;
    }
    .dropdown-toggle:after {
        float: right;
        margin-right: 7px;
        margin-top: 7px;
        margin-left: 0px !important;
    }
    .selectBox {
        width: 50% !important;
    }
    .btn:not(:disabled):not(.disabled), .fc-unthemed .fc-button:not(:disabled):not(.disabled) {
        cursor: pointer;
        text-align: center;
        padding-left: 9px;
        font-size: 12px;
        z-index: 0 !important;
    }
    .searchInput {
        width: auto !important;
    }
    .accSliderValue {
        font-size: 25px !important;
    }
    .graph_headings > p:nth-child(1) {
        font-size: 13px;
    }
    .css-bg1rzq-control {
        width: 100%;
    }
    .css-1gfci2o-container {
        width: 100% !important;
    }
}

th {
    &.sortable {
        &:last-child {
            span {
                right: 10px !important;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .css-txbzea-container {
        width: 44vw !important;
    }
    .mobile_view_table_height {
        padding-top: 84vh;
    }
}

@media only screen and (max-width: 350px) {
    .css-txbzea-container {
        width: 44vw !important;
    }
    .mobile_view_table_height {
        padding-top: 84vh;
    }
    dropdown-toggle:after {
        float: right;
        margin-right: 2px;
        margin-left: 0px !important;
    }
}

@-moz-document url-prefix() {
    .dash_graph_index {
        display: flex !important;
    }
}
