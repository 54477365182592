.form-group-mb-0 {

  .form-group {
    margin-bottom: 0;
  }
}

.form-control-no-border {
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.file-upload {
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    width: 23px;
    opacity: 0.5;
    transition: all 0.4s ease;
  }

  &:hover {
    svg {
      color: $primary;
      opacity: 1;
    }
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: url('../../../../public/img/clock.svg') no-repeat center center/15px 15px;
  cursor: pointer;
  visibility: visible;
}

.customDatePicker {
  input {
    color: #BABABA;
    font-size: 16px;
    line-height: 19px;
    border-radius: 0;
  }
  .timepicker {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    overflow: hidden;
  }
}
