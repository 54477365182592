// Material Date picker styles

.pi-date-picker {
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInputBase-root {
    padding: 3.5px 0;
    background-color: #fff;
    border-color: $color-border;

    input {
      height: auto;
      font-size: $sm;
      padding-left: 6px;
    }
  }
  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
}
.pi-time-picker {
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInputBase-root {
    padding: 2px 15px 2px 0px !important;
    background-color: #fff;
    border-color: $color-border;

    input {
      font-size: $sm;
      padding-left: 6px;
    }
  }
  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
}

// Material global color
.MuiIconButton-label {
  color: $color-text !important;
}

.MuiPickersDay-current.MuiPickersDay-daySelected {
  background-color: $color-text;

  p {
    color: #fff;
  }
}
.MuiPickersDay-daySelected {
  background-color: $color-text !important;

  &:hover {
    background-color: $color-text !important;

    .MuiIconButton-label p {
      color: #fff !important;
    }
  }

  .MuiIconButton-label p {
    color: #fff !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -23px;
  white-space: nowrap;
}

