@import "icon-variables";

@include font-face('font-icons', $font-family-icons, $font-family-icons, normal, normal, false);

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: #{$font-family-icons} !important;
    display: block;
    line-height: 1;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-warning-alt {
    &:before {
        content: $icon-warning-alt;
    }
}
.icon-flood {
    &:before {
        content: $icon-flood;
    }
}
.icon-lane-closure {
    &:before {
        content: $icon-lane-closure;
    }
}
.icon-snow-plow {
    &:before {
        content: $icon-snow-plow;
    }
}
.icon-vehicle-stopped {
    &:before {
        content: $icon-vehicle-stopped;
    }
}
.icon-waste-vehicle {
    &:before {
        content: $icon-waste-vehicle;
    }
}
.icon-construction-hard-hat {
    &:before {
        content: $icon-construction-hard-hat;
    }
}
.icon-manage-accounts {
    &:before {
        content: $icon-manage-accounts;
    }
}
.icon-error-circle {
    &:before {
        content: $icon-error-circle;
    }
}
.icon-visibility-on {
    &:before {
        content: $icon-visibility-on;
    }
}
.icon-visibility-off {
    &:before {
        content: $icon-visibility-off;
    }
}
.icon-devices {
    &:before {
        content: $icon-devices;
    }
}
.icon-missing {
    &:before {
        content: $icon-missing;
    }
}
.icon-restart {
    &:before {
        content: $icon-restart;
    }
}
.icon-backup-restore {
    &:before {
        content: $icon-backup-restore;
    }
}
.icon-analytics {
    &:before {
        content: $icon-analytics;
    }
}
.icon-bar-chart {
    &:before {
        content: $icon-bar-chart;
    }
}
.icon-night-light {
    &:before {
        content: $icon-night-light;
    }
}
.icon-sunny {
    &:before {
        content: $icon-sunny;
    }
}
.icon-upload {
    &:before {
        content: $icon-upload;
    }
}
.icon-radio-button-unchecked {
    &:before {
        content: $icon-radio-button-unchecked;
    }
}
.icon-radio-button-checked {
    &:before {
        content: $icon-radio-button-checked;
    }
}
.icon-group {
    &:before {
        content: $icon-group;
    }
}
.icon-remove-circle-outline {
    &:before {
        content: $icon-remove-circle-outline;
    }
}
.icon-devices-old {
    &:before {
        content: $icon-devices-old;
    }
}
.icon-call {
    &:before {
        content: $icon-call;
    }
}
.icon-email {
    &:before {
        content: $icon-email;
    }
}
.icon-logout {
    &:before {
        content: $icon-logout;
    }
}
.icon-notifications {
    &:before {
        content: $icon-notifications;
    }
}
.icon-job {
    &:before {
        content: $icon-job;
    }
}
.icon-add-location {
    &:before {
        content: $icon-add-location;
    }
}
.icon-email-send {
    &:before {
        content: $icon-email-send;
    }
}
.icon-group-add {
    &:before {
        content: $icon-group-add;
    }
}
.icon-add-admin {
    &:before {
        content: $icon-add-admin;
    }
}
.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}
.icon-show-chart {
    &:before {
        content: $icon-show-chart;
    }
}
.icon-workspaces {
    &:before {
        content: $icon-workspaces;
    }
}
.icon-task-black {
    &:before {
        content: $icon-task-black;
    }
}
.icon-drawer-menu {
    &:before {
        content: $icon-drawer-menu;
    }
}
.icon-floating-filter {
    &:before {
        content: $icon-floating-filter;
    }
}
.icon-circle-remove {
    &:before {
        content: $icon-circle-remove;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-attach-money {
    &:before {
        content: $icon-attach-money;
    }
}
.icon-unfold-more {
    &:before {
        content: $icon-unfold-more;
    }
}
.icon-pause {
    &:before {
        content: $icon-pause;
    }
}
.icon-checkbox {
    &:before {
        content: $icon-checkbox;
    }
}
.icon-checkbox-outline {
    &:before {
        content: $icon-checkbox-outline;
    }
}
.icon-battery-alert {
    &:before {
        content: $icon-battery-alert;
    }
}
.icon-build-circle {
    &:before {
        content: $icon-build-circle;
    }
}
.icon-chart-bar {
    &:before {
        content: $icon-chart-bar;
    }
}
.icon-engineering {
    &:before {
        content: $icon-engineering;
    }
}
.icon-circle-plus {
    &:before {
        content: $icon-circle-plus;
    }
}
.icon-file-download {
    &:before {
        content: $icon-file-download;
    }
}
.icon-double-arrow {
    &:before {
        content: $icon-double-arrow;
    }
}
.icon-alert-octagon-o {
    &:before {
        content: $icon-alert-octagon-o;
    }
}
.icon-account-arrow {
    &:before {
        content: $icon-account-arrow;
    }
}
.icon-alert-octagon {
    &:before {
        content: $icon-alert-octagon;
    }
}
.icon-notes {
    &:before {
        content: $icon-notes;
    }
}
.icon-speed {
    &:before {
        content: $icon-speed;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-event {
    &:before {
        content: $icon-event;
    }
}
.icon-account {
    &:before {
        content: $icon-account;
    }
}
.icon-assessment {
    &:before {
        content: $icon-assessment;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-delete {
    &:before {
        content: $icon-delete;
    }
}
.icon-desktop {
    &:before {
        content: $icon-desktop;
    }
}
.icon-exit {
    &:before {
        content: $icon-exit;
    }
}
.icon-expand-right {
    &:before {
        content: $icon-expand-right;
    }
}
.icon-expand-down {
    &:before {
        content: $icon-expand-down;
    }
}
.icon-groups {
    &:before {
        content: $icon-groups;
    }
}
.icon-help {
    &:before {
        content: $icon-help;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-more-vert {
    &:before {
        content: $icon-more-vert;
    }
}
.icon-person-add {
    &:before {
        content: $icon-person-add;
    }
}
.icon-place {
    &:before {
        content: $icon-place;
    }
}
.icon-play-arrow {
    &:before {
        content: $icon-play-arrow;
    }
}
.icon-radio-on .path1 {
    &:before {
        content: $icon-radio-on-path1;
        color: rgb(100, 194, 125);
        opacity: 0.4;
    }
}
.icon-radio-on .path2 {
    &:before {
        content: $icon-radio-on-path2;
        margin-left: -1em;
        color: rgb(100, 194, 125);
    }
}
.icon-radio-off .path1 {
    &:before {
        content: $icon-radio-off-path1;
        color: rgb(53, 64, 82);
        opacity: 0.12;
    }
}
.icon-radio-off .path2 {
    &:before {
        content: $icon-radio-off-path2;
        margin-left: -1em;
        color: rgb(53, 64, 82);
        opacity: 0.6;
    }
}
.icon-schedule {
    &:before {
        content: $icon-schedule;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-signal .path1 {
    &:before {
        content: $icon-signal-path1;
        color: rgb(151, 151, 151);
        opacity: 0.3;
    }
}
.icon-signal .path2 {
    &:before {
        content: $icon-signal-path2;
        margin-left: -1em;
        color: rgb(151, 151, 151);
        opacity: 0.3;
    }
}
.icon-signal .path3 {
    &:before {
        content: $icon-signal-path3;
        margin-left: -1em;
        color: rgb(230, 173, 29);
    }
}
.icon-signal .path4 {
    &:before {
        content: $icon-signal-path4;
        margin-left: -1em;
        color: rgb(230, 173, 29);
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}

.icon-emergency {
  &:before {
    content: $icon-emergency; 
  }
}


