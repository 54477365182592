// Authorization flow styles

// Sign in
.pi-auth-wrapper {
    .pi-error-message {
        display: flex;
        margin: 20px 0;
        align-items: center;
        justify-content: center;

        i {
            font-size: 20px;
            color: $color-red;
        }

        p {
            font-size: $md;
            margin-bottom: 0;
            padding-left: 5px;
            color: #E90F0F;
        }
    }

    .pi-success-message {
        max-width: 335px;
        margin: 0 auto 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid $color-border;

        i {
            font-size: 44px;
            color: $color-green;
        }

        p {
            font-size: $md;
            margin-top: 15px;
            margin-bottom: 0;
            padding-left: 5px;
            line-height: 27px;
            color: rgba($color-text, 0.7);
        }
    }

    .pi-validation-rules {
        display: flex;
        justify-content: center;

        li {
            font-weight: 400;
            position: relative;
            color: rgba($color-text, 0.6);

            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                padding-left: 10px;
            }

            i {
                top: 3px;
                font-size: 18px;
                margin-right: 5px;
                position: relative;
                display: inline-block;
            }

            &.pi-active {
                i {
                    color: $color-green;
                }

                span {
                    color: $color-text;
                }
            }

            &:last-child {
                &::after {
                    width: 1px;
                    content: ' ';
                    height: 18px;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 2px;
                    background-color: $color-border;
                }
            }
        }
    }
}

.social-wrapper {
    text-align: center;

    .social-btn {
        margin: 0 auto 15px;
    }
}

.social-btn {
    display: flex;
    min-width: 250px;
    align-items: center;

    img {
        max-width: 20px;
        margin-right: 8px;
    }
}

.other-options {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    font-weight: 500;
    position: relative;
    color: $color-text;
    text-transform: uppercase;

    &:after, &:before {
        content: "";
        width: 40%;
        height: 1px;
        display: block;
        position: absolute;
        top: 10px;
        border-bottom: 1px solid rgba(#000, 0.2);
    }

    &::after {
        right: 0;
    }

    &::before {
        left: 0;
    }
}