// User card component

.pi-team-card {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;

    .pi-card-info {
        display: flex;
        align-items: center;

        .pi-info-content {
            padding-left: 12px;

            strong {
                font-size: $md;
                display: block;
                font-weight: 500;
                line-height: 21px;
                color: $color-text;
                @include transition(all 0.2s);
            }

            span {
                font-size: $sm;
                font-weight: 500;
                color: rgba($color-text, 0.7);
            }
        }
    }

    &:hover {
        .pi-card-info {
            .pi-info-content {
                strong {
                    color: $color-secondary;
                }
            }
        }
    }

    &.pi-active {
        .pi-card-info {
            .pi-info-content {
                strong {
                    color: $color-secondary;
                }
            }

            .pi-user-avatar {
                span {
                    color: #fff;
                    background-color: $color-secondary;
                }
            }
        }
    }
}