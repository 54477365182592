.pi-filter-bar {
    padding: 14px 40px;
    border-bottom: 1px solid $color-border;
    &-top {
        display: none;
        align-items: center;
        justify-content: space-between;
        padding: 40px 24px 10px;
        border-bottom: 1px solid $color-border;
        h4 {
            font-weight: 500;
            font-size: 21px;
            line-height: 30px;
            color: #29313F;
            margin-bottom: 0;
        }
        button {
            border: 0;
            color: rgba(53, 64, 82, 0.7);
            background-color: transparent;
            transition: all 0.2s;
            i {
                font-size: 24px;
            }
            &:hover {
                color: $text-color;
            }
        }
    }
    &-bottom {
        display: none;
        justify-content: flex-end;
        padding: 20px 32px 30px;
        border-top: 1px solid $color-border;
    }
    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left,
        &-right {
            display: flex;
            align-items: center;
            .pi-group-row {
                display: flex;
                align-items: center;
                .pi-date-picker {
                    background-color: #fff;
                    &:not(:last-of-type) {
                        margin-right: 8px;
                    }

                    p.Mui-error {
                        white-space: nowrap;
                    }
                }
            }
            .pi-info-tooltip {
                font-size: 20px;
                margin-left: 20px;
                color: $color-text;
            }

            .pi-select {
                &:not(.pi-size-sm) {
                    width: 240px;
                }

                &.pi-size-sm {
                    width: 142px;
                }
            }
        }
    }
    &-item {
        position: relative;
        padding: 0 24px;
        .pi-filter-custom {
            display: none;
            margin-left: 8px;
            font-weight: 400;
            background-color: transparent;
            text-transform: none;
            &.active {
                background-color: $text-color;
                color: #fff;
            }
        }
        &:after {
            right: 0;
            top: 50%;
            width: 1px;
            height: 80%;
            content: '';
            max-height: 34px;
            position: absolute;
            display: inline-block;
            background-color: $color-border;
            transform: translateY(-50%);
        }
        &:first-child {
            padding-left: 0;
        }
    }
    .pi-radio-group {
        display: flex;
        li {
            margin: 4px 0;
            white-space: nowrap;
            .pi-radio-btn {
                i {
                    color: $text-color;
                }
                &.right-icon {
                    label {
                        flex-direction: row-reverse;
                    }
                    i {
                        padding-right: 0;
                        padding-left: 8px;
                    }
                }
            }
            label {
                min-width: 70px;
                justify-content: center;
                background-color: transparent;
            }
            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }
}

.pi-activity-wrapper {
    .pi-filter-bar {
        .pi-radio-group {
            li {
                .pi-radio-btn {
                    input:checked + label {
                        background-color: $text-color;
                        color: #fff;
                        strong,
                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}