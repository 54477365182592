// Global textarea styles

.pi-text-area {
    line-height: 0;
    position: relative;

    label {
        line-height: 18px;
    }

    textarea {
        width: 100%;
        min-height: 41px;
        line-height: 18px;
        padding: 6px 48px 6px 12px;
        border-radius: $radius-sm;
        @include transition(all 0.2s);
        border: 1px solid $color-border;

        &:focus {
            outline: none;
            border-color: $color-text;
        }
    }

    &.pi-shadow {
        textarea {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
    }

    .pi-icon-wrapper {
        position: relative;

        .pi-icon {
            top: 8px;
            right: 8px;
            font-size: 24px;
            color: $color-text;
            position: absolute;
        }
    }

    &.pi-invalid {
        .pi-error-message {
            bottom: -19px;
            font-size: $xs;
            margin-bottom: 0;
            color: $color-red;
            position: absolute;
        }
    }
}