.card {
	> .dataTables_wrapper .table.dataTable,
	> .table,
	> .table-responsive .table,
	> .table-responsive-sm .table,
	> .table-responsive-md .table,
	> .table-responsive-lg .table,
	> .table-responsive-xl .table {
		border-right: 0;
		border-bottom: 0;
		border-left: 0;
		margin-bottom: 0;

		td:first-child,
		th:first-child {
			border-left: 0;
			padding-left: $spacer*1.25;
		}

		td:last-child,
		th:last-child {
			border-right: 0;
			padding-right: $spacer*1.25;
		}

		tr:first-child td,
		tr:first-child th {
			border-top: 0;
		}

		tr:last-child td {
			border-bottom: 0;
		}
	}

	.card-header + .table {
		border-top: 0;
	}
}

.table-action {
	a {
		color: $gray-600;

		&:hover {
			color: $gray-900;
		}
	}

	.feather {
		width: 18px;
		height: 18px;
	}
}

.table {

	> tbody > tr > td {
		vertical-align: middle;
	}

	thead {
		th {
			vertical-align: middle;
		}
	} 

	.sortable {
		position: relative;
		vertical-align: middle;

		span {
			display: inline-flex;

			&:after,
			&:before {
				vertical-align: middle;
				display: inline-block;
				margin-top: -7px;
			}
		}

		.sort-wrap {
			display: flex;
			align-items: center;
		}
	}
}

.card > .dataTables_wrapper {
	.table.dataTable {
	  margin-top: 0 !important;
	  margin-bottom: 0 !important;
	}

	.dataTables_info {
		padding: 1rem 1.25rem;
	}

	.dataTables_paginate {
		padding: 0.6rem 1.25rem;
	}
}

.dt-bootstrap4 {
	width: calc(100% - 2px);
}

.table-select-row {
	background-color: $orange;
}

.table-light-th {

	table {

		th {
			color: $gray-600;
			font-weight: $font-weight-normal;
		}
	}
}

.table-filter-label-none {
	table {
		th {
			.filter-label {
				display: none;
			}
		}
	}
}


.filters-top {
	padding-left: 25px;
	padding-right: 25px;

	.range-slider {
		position: relative;

		.label {
			position: absolute;
			bottom: -40px;
			left: 0;
			width: 100%;
			text-align: center;
			color: #808080;
			font-size: 13px;
		}
	}
}

.status-select {
	display: flex;
	align-items: center;

	.dot {
		width: 6px;
		min-width: 6px;
		height: 6px;
		display: inline-block;
		margin-right: 8px;
		border-radius: 50%;
	}

	.text {
		margin-bottom: 0;
		margin-right: 20px;
	}

	.number {
		margin-left: auto;
		border-left: 1px solid #ccc;
		padding: 0 10px;
		min-width: 35px;
		text-align: center;
		margin-left: auto;
	}

	&-single {

		.text {
			margin-right: 0;
		}
	}
}

.custom-table {

	table {	
		border-collapse: separate;
		border-spacing: 0 1px;
	}

	thead {
		th {
			border-bottom: none;
		}
	}

	.tr-class {

		td {
			padding: 1rem 0.75rem;

			&:first-child {
				border-left: 4px solid transparent;
			}
		}

		&.tr-impact {
			background: #fdf8fa;

			td {

				&:first-child {
					border-left-color: #d9155f;
				}
			}
		}
	}
}

.line-clamp {
	font-size: 14px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: break-word;
	-webkit-line-clamp: 2;
}


.react-bootstrap-table {
	@media only screen and (max-width: 1200px) {
		overflow-x: auto;

		.table {
			min-width: 1000px;
		}
	}
}