@media (max-width: 1365px) {
    .pi-expanded-report-body {
        .pi-expanded-report-content {
            .pi-log-section-row {
                .pi-info-string {
                    padding: 0 20px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .pi-reports-wrapper {
        .pi-table {
            .pi-reports-table {
                tbody {
                    tr {
                        &.pi-expanded-row {
                            position: fixed;
                            z-index: 1000;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 0;
                            overflow: auto;
                            .pi-logs-body {
                                padding: 25px 30px 125px;
                            }
                        }
                    }
                }
            }
        }
    }
    .pi-expanded-report-body {
        .pi-expanded-report-top {
            .pi-expanded-action {
                position: fixed;
                justify-content: center;
                bottom: 0;
                left: 0;
                z-index: 2;
                padding: 30px;
                background: #fff;
                width: 100%;
                border-top: 1px solid #E7E7E7;
            }
            .pi-expanded-close {
                display: block;
            }
        }
        .pi-expanded-report-total {
            padding-top: 40px;
        }
    }
}
@media (max-width: 900px) {
    .pi-reports-wrapper {
        .pi-reports-table {
            &.pi-table {
                tbody {
                    tr {
                        &.pi-expanded-row {
                            .pi-logs-body {
                                padding: 25px 20px 125px;
                            }
                        }
                    }
                }
            }
        }
    }
    .pi-expanded-report-body {
        .pi-expanded-report-content {
            padding: 0;
            .pi-log-section-row {
                flex-wrap: wrap;
                .pi-info-string {
                    width: 50%;
                    padding: 0 30px;
                    &:first-child {
                        padding-left: 30px;
                    }
                    &:nth-child(2n) {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 620px) {
    .pi-expanded-report-body {
        .pi-log-title {
            h5 {
                padding: 0;
            }
            &:before {
                display: none;
            }
        }
        .pi-log-gallery {
            grid-template-columns: repeat(auto-fit, 90px);
            li {
                width: 90px;
                height: 90px;
            }
        }
        .pi-expanded-report-content {
            padding: 0;
            .pi-info-string {
                flex-direction: column;
                margin-top: 16px;
                .pi-info-label {
                    padding-right: 0;
                }
                .pi-info-text {
                    font-size: 14px;
                }
                &.pi-note {
                    flex-direction: row;
                    flex-wrap: wrap;
                    .pi-info-text {
                        width: 100%;
                        margin-top: 8px;
                    }
                }
            }
            .pi-log-section-row {
                flex-wrap: wrap;
                .pi-info-string {
                    width: 100%;
                    padding: 0;
                    flex-direction: row;
                    &:before {
                        display: none !important;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .pi-expanded-report-body {
        .pi-expanded-report-top {
            .pi-expanded-action {
                button {
                    padding: 0 12px;
                    i {
                        font-size: 16px;
                    }
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}