.battery {
    font-size: 1px;
    padding: $battery-padding;
    border: solid $battery-border-width $black;
    position: relative;
    height: $battery-padding*2+$battery-border-width*2+$battery-unit-height;

    :after {
        content: " ";
        top: calc(50% - #{$battery-plus-height/2});
        right: -$battery-plus-width;
        height: $battery-plus-height;
        width: $battery-plus-width;
        position: absolute;
        background: $black;
    }

    .bar {
        cursor: pointer;
        display: inline-block;
        width: 0;
        border: solid thin $white;
        padding: $battery-unit;
        height: $battery-unit-height;
        background: transparent;
        transition: background 1s;

        &.active {
            background: $black;
        }
    }

    &.warning {
        border-color: $warning;
        :after,
        .bar.active {
            background: $warning;
        }
    }
}