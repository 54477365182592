// Tab content and navigation styles

.pi-info-tabs {
    .pi-tab-navigation {
        padding: 0 60px;
        background-color: $color-bg;
        border: 1px solid $color-border;
        border-radius: $radius-md $radius-md 0 0;

        .pi-tab-nav-item {
            width: 50%;
            text-align: center;

            &:not(.pi-size-md) {
                width: 50%;
            }

            &.pi-size-md {
                max-width: 400px;
            }

            &.pi-align-left {
                text-align: left;
            }

            &.pi-active {
                .pi-tab-nav-link {
                    font-weight: 700;

                    &::after {
                        background-color: $color-text;
                    }
                }
            }

            &:not(.pi-active) {
                .pi-tab-nav-link {
                    font-weight: 500;

                    &::after {
                        background-color: rgba($color-text, 0.2);
                    }
                }
            }

            .pi-tab-nav-link {
                width: 100%;
                color: $color-text;
                position: relative;
                padding: 22px 15px 15px;
                text-transform: uppercase;

                &::after {
                    width: 100%;
                    height: 2px;
                    content: '';
                    bottom: 0;
                    left: 0;
                    //right: 0;
                    display: block;
                    margin: 0 auto;
                    max-width: 400px;
                    position: absolute;
                }
            }
        }
    }
}
