// Add new workzone flow

// Add from existing
.pi-add-workzone-modal,
.pi-add-member-modal,
.pi-add-location-modal {
    .pi-search-bar {
        display: flex;
        padding: 15px 25px;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        .pi-text-field {
            width: 100%;
        }
    }

    .pi-table-wrapper {
        padding: 5px 25px 25px;

        .pi-type-checkbox-text {
            span {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .pi-side-panel {
        width: 100%;
        max-width: 290px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        height: 100%;
        background-color: $color-bg;
        border-top-right-radius: $radius-md;
        border-left: 1px solid $color-border;
        border-bottom-right-radius: $radius-md;

        .pi-close {
            @include close-button(24px, 24px, 24px);
        }
    }
}

// Add new
.pi-new-workzone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: $color-bg;

    .pi-top-bar {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        border-bottom: 1px solid $color-border;

        .pi-back-btn {
            border: 0;
            padding: 0;
            font-size: 20px;
            margin-right: 12px;
            color: $color-text;
            background-color: transparent;

            i {
                @include transform(rotate(90deg));
            }
        }

        .pi-btn-holder {
            font-size: $xs;
            font-weight: 500;
            color: $color-text;
            text-transform: uppercase;
        }
    }

    .pi-header {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-border;

        h3 {
            display: flex;
            font-weight: 500;
            margin-bottom: 0;
            color: $color-text;
            align-items: center;
            text-transform: uppercase;

            .pi-back-btn {
                border: 0;
                padding: 0;
                font-size: 24px;
                margin-right: 12px;
                color: $color-text;
                background-color: transparent;

                i {
                    @include transform(rotate(90deg));
                }
            }
        }
    }

    .pi-content-wrapper {
        display: flex;
        height: calc(100% - 103px);
        justify-content: space-between;

        .pi-form-side {
            width: 100%;
            padding: 25px;
            overflow: auto;
            max-width: 315px;
            border-right: 1px solid $color-border;
            max-height: calc(var(--app-height) - 62px);

            .pi-devices-list {
                padding-top: 12px;
                border-top: 1px dashed rgba($color-text, 0.5);

                .pi-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        font-weight: 500;
                        margin-bottom: 0;
                        color: $color-text;
                    }
                }

                .pi-holder {
                    opacity: 0.7;
                    margin: 12px 0 15px;
                }

                .pi-block {
                    margin-top: 20px;
                    border-bottom: 1px dashed $color-border;
                }

                .pi-checklist {
                    margin: 12px 0 15px;
                }
            }

            .pi-btns-wrapper {
                display: flex;
                justify-content: space-between;
            }
        }

        .pi-map-side {
            width: 100%;
            max-width: calc(100% - 315px);

            .pi-device-map {
                &-content {
                    height: calc(100% - 62px) !important;
                    width: calc(100% - 315px) !important;
                }
            }
        }
    }
}