@keyframes fadeInLeft {
    /*0% {
        width: 50%;
        opacity: 1;
    }
    20% {
        width: 35%;
        opacity: 0.66;
    }
    80% {
        width: 10%;
        opacity: 0.33;
    }
    100% {
        width: 0;
        opacity: 0;
    }*/

    from {
        opacity: 1;
    }

    to {
        width: 0;
        opacity: 0;
    }
}

.fade_in_left {
    animation: fadeInLeft 0.3s forwards;
}
