@media (max-width: 700px) {
    .pi-picker-window {
        .pi-details-body {
            flex-wrap: wrap;

            &.pi-with-image {
                .pi-image-wrapper {
                    border-right: 0;
                    padding-left: 0;

                    .pi-image {
                        width: 100px;
                        height: 100px;
                    }
                }
            }

            .pi-content-wrapper {
                padding-left: 0;
                padding-right: 0;
                
                .pi-info-row {

                    .pi-row {
                        flex-wrap: wrap;

                        &.pi-with-border {
                            border: 0;
                            padding: 0;
                        }

                        .pi-detail-item {
                            &::before {
                                display: none;
                            }

                            &:not(.pi-not-row) {
                                width: 100%;
                                display: flex;
                                align-items: center;

                                p {
                                    padding-right: 5px;
                                }

                                &:first-of-type {
                                    padding-right: 0;
                                }

                                &:not(:first-of-type) {
                                    padding: 0;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
        .pi-details-footer {
            display: block;
            .pi-detail-item {
                padding: 0 !important;
                max-width: 100%;
                &:before {
                    display: none;
                }
            }
        }
    }
}