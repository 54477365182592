.navbar {
  border-bottom: $navbar-border-bottom;
  font-family: $font-headlines;
  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}

.navbar-brand {
  font-weight: $navbar-brand-font-weight;
}

.nav-icon,
.nav-flag {
  padding: .1rem .8rem;
  display: block;
  font-size: 1.5rem;
  color: $gray-600;
  transition: $transition-appearance-fast;
  line-height: 1.4;

  &:after {
    display: none !important;
  }

  &:hover,
  &.active {
    color: $primary;
  }

  svg,
  .feather {
    width: 20px;
    height: 20px;
  }
}

.nav-item {
  .indicator {
    background: $primary;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: block;
    height: 18px;
    width: 18px;
    padding: 1px;
    position: absolute;
    top: 0;
    right: -8px;
    text-align: center;
    transition: top .1s ease-out;
    font-size: 0.675rem;
    color: $white;
  }

  &:hover .indicator {
    top: -4px;
  }

  a:focus {
    outline: 0;
  }
}

.notification-dropdown {

  .dropdown-menu {
    width: 20rem;
  }
  
  .dropdown-item {

    p {
      white-space: normal;
    }

    .number {
      font-weight: 600;
    }
  }

  h4 {
    padding: 0.35rem 1.5rem;
    font-weight: 400;
    color: #495057;
  }

  .notification-btn {
    position: relative;
    color: $gray-600;
    display: inline-block;
    
    .number {
      background: $red;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: $font-size-sm;
      color: #fff;
      position: absolute;
      top: -8px;
      right: -3px;
      line-height: 20px;
      text-align: center;
    }
  }
}


// .road-safe-logo {
//   height: 32px;
// }

.user-image {
  margin: .1rem 0;
  height: 40px;
  vertical-align: middle;
}

.serviced_by {
  font-weight: $font-weight-bold;
  margin-bottom: unset;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar .avatar {
    max-height: 47px;
  }
}

@include media-breakpoint-down(xs) {
  .navbar {
    padding: 0.75rem;
  }

  .nav-icon {
    padding: .1rem .75rem;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: inherit;
  }

  .navbar-expand .navbar-nav .dropdown-menu-lg {
    min-width: 100%;
  }

  .nav-item .nav-link:after {
    display: none;
  }
}

.nav-flag img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
