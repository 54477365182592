// Webkit scrollbar styles

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
    border-radius: $radius-xs;
}

* ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: $radius-xs;
}

* ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
}