.pi-role-accordion-wrapper {
	padding: 20px 25px;
}

.pi-role-accordions {
	display: flex;
	margin-top: 10px;
	margin-bottom: 15px;
	align-items: center;
	justify-content: space-between;

	.pi-block-title {
		margin-bottom: 0;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;

		li {
			padding: 0 16px;
		}
	}
}

.pi-role-details-body {
	padding: 8px 5px;

	ul {
		display: flex;
		flex-wrap: wrap;

		li {
			padding: 8px 0;

			strong {
				padding: 0 15px;
				font-weight: 500;
				color: $color-text;
			}

			&:not(:last-child) {
				strong {
					border-right: 1px solid $color-border;
				}
			}
		}
	}
}