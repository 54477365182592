@media (max-width: 1440px) {
    .pi-reports-table {
        &.pi-wrapper {
            tbody {
                tr {
                    td {
                        &.pi-type-image {
                            .pi-td {
                                .pi-image {
                                  width: 77px;
                                  height: 77px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .pi-reports-wrapper {
        .pi-page-body {
            height: calc(100% - 170px);
        }
        .pi-filter-mobile-btn {
            display: flex;
        }
        .pi-reports-table {
            &.pi-wrapper {
                padding-left: 0;
                padding-right: 0;
            }
            &.pi-wrapper.pi-list-resp {
                tbody {
                    tr {
                        padding: 0 0 20px;
                        td {
                            &.pi-type {
                                &-date {
                                    border: 1px solid rgba(44, 123, 229, 0.3);
                                    border-radius: 8px 8px 0 0;
                                    margin-bottom: 20px;
                                    .pi-td {
                                        display: flex;
                                        padding: 16px;
                                        .pi-date-status {
                                            margin-bottom: 0;
                                            width: 20px;
                                            height: 20px;
                                            overflow: hidden;
                                            margin-right: 10px;
                                        }
                                        span {
                                            font-size: 16px;
                                        }
                                    }
                                    &.pi-date-impact {
                                        border: 1px solid rgba(44, 123, 229, 0.3);
                                    }
                                    &.pi-date-missing {
                                        border: 1px solid rgba(233, 15, 15, 0.2);
                                    }
                                }
                                &-image {
                                    display: none;
                                }
                            }
                            .pi-cell-holder {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}