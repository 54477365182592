// Image uploader and image gallery

.pi-image-uploader {
    .pi-image-gallery {
        display: grid;
        grid-gap: 10px;
        margin-bottom: 10px;
        grid-template: unset;
        grid-template-columns: repeat(auto-fit, 100px);

        .pi-upload-new {

            label {
                width: 100px;
                height: 100px;
                display: flex;
                cursor: pointer;
                margin-bottom: 0;
                position: relative;
                align-items: center;
                justify-content: center;
                border-radius: $radius-md;
                @include transition(all 0.3s);
                border: 1px solid $color-border;

                &:hover {
                    opacity: 0.7;
                }

                i {
                    font-size: 30px;
                    color: $color-text;
                }
            }

            input {
                display: none;
            }
        }

        li {
            position: relative;

            &.pi-placeholder {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: $radius-md;
                background-color: rgba($color-text, 0.1);

                i {
                    font-size: 30px;
                    color: rgba($color-text, 0.5);
                }
            }

            .pi-image {
                width: 100px;
                height: 100px;
                cursor: pointer;
                border-radius: $radius-md;
                background: no-repeat center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                background-color: rgba($color-text, 0.1);
                background-size: contain;
            }

            .pi-delete {
                width: 24px;
                height: 24px;
                top: 3px;
                border: 0;
                right: 3px;
                padding: 0;
                font-size: 18px;
                color: $color-red;
                position: absolute;
                border-radius: 100%;
                background-color: #fff;
                @include transition(all 0.2s);

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}