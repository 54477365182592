// Teams page team list sidebar

@media (max-width: 1200px) {
  .pi-teams-sidebar {
    position: relative;
    z-index: 2;

    &:not(.pi-open) {
      display: none;
    }

    &.pi-open {
      width: 100%;
    }
  }
}
