// Radio Button

.pi-radio-btn {
    position: relative;
    text-align: center;

    &:not(.pi-active-dark) {
        input {
            &:checked + label {
                border-color: $color-text;
            }
        }
    }

    &.pi-active {
        input {
            &:checked + label {
                border-color: $color-text;
            }
        }
    }

    &.pi-active-dark {
        input {
            &:checked + label {
                background-color: $color-text;

                strong {
                    color: #fff;
                }

                i {
                    color: #fff;
                }

                .icon-radio-button-unchecked::before {
                    content: $icon-radio-button-checked;
                }
            }
        }
    }

    input {
        display: block;
    }

    label {
        display: flex;
        cursor: pointer;
        margin-bottom: 0;
        align-items: center;
        white-space: nowrap;
        background-color: #fff;
        border-radius: $radius-sm;
        @include transition(all 0.2s);
        border: 1px solid $color-border;

        &:not(.pi-disabled):hover {
            border-color: rgba($color-text, 0.4);
        }
        
        &.pi-disabled {
            cursor: default;

            strong, i {
                color: rgba($text-color, 0.6);;
            }
        }

        &::before, &::after {
            visibility: hidden;
            display: none !important;
        }
    }

    i {
        font-size: 20px;
        padding-right: 8px;
        position: relative;

        &.pi-size {
            &-md {
                font-size: 24px;
            }
        }
    }

    img {
        display: inline-block;

        & + strong {
            padding-left: 8px;
        }
    }

    strong {
        font-size: $sm;
        font-weight: 400;
        color: $text-color;
        @include transition(all 0.2s);
    }

    &.pi-padding-xs {
        justify-content: center;

        label {
            padding: 0 8px;
        }
    }

    &:not(.pi-padding-xs) {
        label {
            padding: 0 15px;
        }
    }

    &.pi-width-sm {
        min-width: 72px;

        label {
            strong {
                min-width: 40px;
            }
        }
    }

    &.pi-width-md {
        label strong {
            min-width: 70px;
        }
    }

    &.pi-size {
        &-sm {
            label {
                height: 41px;
                line-height: 39px;
            }
        }
        &-md {
            label {
                height: 50px;
                line-height: 49px;

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    &.round-radio {
        label {
            height: auto;
            padding: 0;
            line-height: normal;
            background-color: transparent;
            border-radius: 0;
            border: none;
            strong {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.3125rem;
                color: $text-color;
                position: relative;
                padding-left: 32px;
                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    border: 2px solid $color-secondary;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                }
            }
        }
        input {
            &:checked + label {
                strong {
                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        left: 5px;
                        top: 5px;
                        width: 10px;
                        height: 10px;
                        background-color: $color-secondary;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                    }
                }
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }

    &.pi-loading {
        .pi-icon, strong {
            opacity: 0;
        }

        .pi-loader {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}