@media (min-width: 1201px) {
    .pi-switcher {
        .pi-switcher-wrapper {
            height: calc(100% - 100px);
        }
    }
}

@media (max-width: 1200px) {
    .pi-switcher {
        .pi-switcher-wrapper {
            padding: 25px 20px 5px;
            height: calc(100% - 72px);
        }
    }
}

@media (max-width: 1200px) {
    .pi-switcher {
        .pi-switcher-wrapper {
            display: block;

            .pi-current {
                width: 100%;
                padding-right: 0;
                margin-bottom: 30px;

                &::after {
                    display: none;
                }
            }

            .pi-group {
                & > .pi-inner {

                    .pi-workspace-switch {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}