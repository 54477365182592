@media (max-width: 1600px) {
    .pi-locations-table, .pi-activity-table, .pi-wz-device-table {
        tr {
            .pi-visibility {
                display: none !important;
            }
        }
    }
}
@media (max-width: 1365px) {
    .pi-reports-table {
        &.pi-size-lg {
            .pi-th {
                padding: 20px 16px;
            }
            .pi-td {
                padding: 12px 16px;
            }
        }
        tr {
            .pi-visibility {
                display: none !important;
            }
        }
    }
    .pi-team-members-table {
        tr {
            .pi-visibility {
                display: none !important;
            }
        }
    }
    .pi-wz-device-table {
        @include table();
        tbody {
            tr {
                width: calc(50% - 5px);
                td {
                    &.pi-type-text-icon {
                        .pi-td {
                            width: fit-content;
                            strong {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .pi-locations-table,
    .pi-reports-table,
    .pi-activity-table,
    .pi-detail-workzone-table,
    .pi-detailDevice-workzone-table,
    .pi-WorkZone-table,
    .pi-add-workzone-table,
    .pi-assign-device-table,
    .pi-users-table {
        @include table();
        tbody {
            tr {
                width: calc(50% - 5px);
                td {
                    &.pi-type-text-icon {
                        .pi-td {
                            width: fit-content;
                            strong {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .pi-reports-table {
        tr {
            .pi-visibility {
                display: block !important;
            }
            td {
                div[data-id="tooltip"] {
                    display: none !important;
                }
            }
        }
    }

    @include table-actions();

    .pi-name {
        max-width: calc(100% - 85px);
    }
}
@media (max-width: 1100px) {
    .pi-team-members-table {
        tr {
            .pi-visibility {
                display: block !important;
            }
        }
    }
}
@media (max-width: 900px) {
    .pi-locations-table,
    .pi-reports-table,
    .pi-activity-table,
    .pi-WorkZone-table,
    .pi-detailDevice-workzone-table,
    .pi-detail-workzone-table,
    .pi-wz-device-table,
    .pi-add-workzone-table,
    .pi-assign-device-table,
    .pi-users-table {
        tbody {
            tr {
                width: 100%;
            }
        }
    }
}