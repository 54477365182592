.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

.btn .feather {
  width: 14px;
  height: 14px;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-color($white);
    @include button-color-hover($white);
  }

  .btn-outline-#{$color} {
    @include button-color-hover($white);
    @include button-color-active($white);
  }
}

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($white);
    @include button-color-hover($white);
  }
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
  @include button-color-hover($gray-800);
  @include button-color-active($gray-800);
}

.btn-normal {
  background-color: $white;
  border-color: $gray-500;
}

.yellow-button {
  background-color: $orange;
  padding: 8px;
  color: $white;
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
  border-radius: $border-radius;

  &:hover {
    background-color: $orange-trans-50;
    color: $white;
  }
}


.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $gray-200;
  border-color: $gray-200;
  opacity: 1;
  svg {
    color: #FAA528 !important;
  }
}
