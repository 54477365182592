.rm-body {
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  position: relative;
  background: #4a5257;

  .rm-content {
    padding: 45px 10px;

    /*RM top side for main page structure*/
    .rm-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding: 0 14px;
      &-location {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.6);
      }
      &-logo {
        width: 60px;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    /*RM user info for main page structure*/
    .rm-userInfo {
      padding: 0 14px 17px;
      &-top {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        .rm-user-avatar {
          display: block;
          width: 150px;
          height: 150px;
          object-fit: cover;
          box-sizing: border-box;
          border-radius: 8px;
          margin-right: 16px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .rm-userInfo-addReport {
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.6);
          background-color: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
          margin-bottom: 16px;
        }
        .rm-user-name {
          font-weight: 500;
          font-size: 21px;
          line-height: 32px;
          color: #fff;
        }
      }
      .rm-warning-message {
        display: inline-block;
        padding: 12px 16px 12px 12px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
        svg {
          margin-right: 12px;
          color: #fa5c5c;
        }
      }
      .rm-date-time {
        margin-top: 15px;

        .pi-info-string:not(.pi-note) {
          strong,
          span,
          i {
            color: rgba(#fff, 0.8);
          }
        }
      }
    }

    /*RM map*/
    .pi-device-location {
      .map-marker-mode-button {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
        color: #354052;
        background: #ffffff;
        border: 1px solid rgba(53, 64, 82, 0.3);
        box-sizing: border-box;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin: 0 12px 12px 0;
        padding: 5px 16px;

        &.pi-active {
          color: #fff;
          border-color: #354052;
          background-color: #354052;
        }
        img {
          display: block;
          margin-right: 10px;
        }
      }
    }

    /*RM top side and user info for steps structure*/
    .rm-steps {
      padding-top: 10px;
      .rm-top {
        margin-bottom: 45px;
        justify-content: flex-end;
        &-location {
          display: none;
        }
        &-logo {
          width: 87px;
        }
      }
      .rm-userInfo {
        &-top {
          display: block;
          .rm-user-avatar {
            margin-bottom: 20px;
          }
        }
      }

      /*RM step*/
      .rm-step {
        background: #fff;
        box-shadow: 0px -8px 23px rgba(0, 0, 0, 0.0632077);
        border-radius: 16px 16px 0 0;
        // padding: 42px 24px 100px;
        padding: 42px 24px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        // min-height: 400px;
        max-height: 95vh;
        overflow-y: auto;
        &-content {
          h4 {
            font-weight: bold;
            font-size: 22px;
            line-height: 28px;
            color: #354052;
            margin-bottom: 32px;
          }
          input[type='date'],
          input[type='time'],
          input[type='number'],
          input[type='text'] {
            width: 100%;
            height: 50px;
            background: #fff;
            border: 1px solid #e4e4e4;
            box-sizing: border-box;
            border-radius: 6px;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #354052;
            padding: 0 12px;
            margin-bottom: 32px;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
          }
          .rm-select {
            position: relative;
            margin-bottom: 32px;
            select {
              width: 100%;
              height: 50px;
              background: #fff;
              border: 1px solid #e4e4e4;
              box-sizing: border-box;
              border-radius: 6px;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              color: #354052;
              padding: 0 12px;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
            }
            img {
              display: block;
              width: 11px;
              position: absolute;
              right: 13px;
              top: 50%;
              margin-top: -5.5px;
              z-index: 2;
            }
          }
          textarea {
            width: 100%;
            height: 144px;
            background: #fff;
            border: 1px solid #e4e4e4;
            box-sizing: border-box;
            border-radius: 6px;
            padding: 12px;
            resize: none;
          }
          label {
            display: block;
            margin-bottom: 0;
            span {
              display: block;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #4b5258;
              margin-bottom: 7px;
            }
          }
          .rm-select {
            .css-1hwfws3 {
              height: 50px;
              padding: 0 12px;
              .css-1g6gooi {
                margin: 0;
                input {
                  margin: 0 !important;
                }
              }
            }
            .css-bgvzuu-indicatorSeparator {
              display: none;
            }
          }
          .rm-cost-content {
            .rm-cost-section {
              &:not(:last-of-type) {
                border-bottom: 1px dashed rgba(53, 64, 82, 0.5);
                margin-bottom: 24px;
                padding-bottom: 24px;
              }
            }
          }
          .cost-field {
            position: relative;
            img {
              display: block;
              width: 10px;
              position: absolute;
              left: 14px;
              top: 50%;
              transform: translateY(-50%);
            }
            input {
              padding-left: 32px;
            }
          }
          .rm-addCost-button {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #2c7be5;
            margin-bottom: 34px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            img {
              display: block;
              width: 10px;
              margin-right: 10px;
            }
          }
          .rm-total {
            margin-top: 32px;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: rgba(75, 82, 88, 0.7);
            span {
              color: #000;
            }
          }
          .rm-radio-buttons {
            label {
              display: block;
              width: 100%;
              margin-bottom: 10px;
              span {
                display: block;
                background: #ffffff;
                border: 1px solid rgba(53, 64, 82, 0.3);
                box-sizing: border-box;
                border-radius: 6px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #354052;
                padding: 16px;
                transition: all 0.3s ease;
              }
            }
            input {
              display: none;
            }
            input:checked + span {
              background: #354052;
              border: 1px solid #354052;
              color: #fff;
            }
          }
          .rm-remove-add-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 32px;
            button {
              width: calc(50% - 5px);
              height: 52px;
              border: 1px solid #e4e4e4;
              box-sizing: border-box;
              border-radius: 6px;
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              color: #354052;
              background-color: #fff;
              cursor: pointer;
              img {
                display: block;
                width: 20px;
                margin-right: 10px;
              }
              &.only-remove-btn {
                width: 100%;
              }
            }
          }

          .rm-customDatePicker {
            margin-bottom: 32px;
            .rm-error {
              margin-top: 10px;
              font-size: 14px;
            }
          }
          .rm-input-group {
            input {
              margin-bottom: 0;
            }
            .input-group-append {
              position: absolute;
              right: 5px;
              top: 50%;
              margin-top: -14px;
              svg {
                font-size: 18px;
                color: #000;
              }
            }
          }
          .rm-image-upload-group {
            width: 100% !important;
            grid-template-columns: 30% 30% 30% !important;
            .rm-image-uploader {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background: #ffffff;
              border: 1px solid #e4e4e4;
              box-sizing: border-box;
              border-radius: 6px;
              width: 100% !important;
              height: 25vw !important;
              cursor: pointer;
              img {
                display: block;
                width: 20px;
                margin-bottom: 10px;
              }
              span {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: uppercase;
                color: rgba(53, 64, 82, 0.6);
                text-align: center;
              }
            }
            .rm-image-uploader-background {
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(53, 64, 82, 0.1);
              border-radius: 6px;
              width: 100% !important;
              height: 25vw !important;
              position: relative;
              overflow: hidden;
              .rm-remove-image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                img {
                  display: block;
                  width: 12px;
                }
              }
            }
          }
          .rm-images-error {
            margin-top: 40px;
            font-size: 14px;
          }
        }
        &-success {
          h4 {
            padding-bottom: 50px;
          }
        }
        .rm-checkbox {
          label {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 21px;
            color: #354052;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              box-sizing: border-box;
              border-radius: 2px;
              border: 1px solid #e4e4e4;
              transition: all 0.3s ease;
              margin-right: 12px;
              margin-bottom: 0;
              svg {
                color: #fff;
              }
            }
          }
          input {
            display: none;
          }
          input:checked + span {
            background: #2c7be5;
            border-color: #2c7be5;
          }
        }
        &-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // position: fixed;
          background-color: #fff;
          bottom: 0;
          left: 0;
          width: 100%;
          // padding: 24px;
          padding-top: 24px;
          box-sizing: border-box;
          & > button {
            display: block;
            width: calc(50% - 4px);
            height: 50px;
            border-radius: 6px;
            text-transform: uppercase;
            font-weight: bold;
            border: none;
            background-color: transparent;
            cursor: pointer;
          }
          .rm-back-button {
            border: 1px solid rgba(41, 49, 63, 0.3);
            font-size: 17px;
            line-height: 20px;
            color: #354052;
          }
          .rm-next-button {
            background: #2c7be5;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
            &.disabled {
              pointer-events: none;
              opacity: 0.4;
            }
          }
        }

        .rm-mainPage-button {
          display: block;
          position: absolute;
          bottom: 42px;
          left: 24px;
          width: calc(100% - 48px);
          height: 50px;
          border-radius: 6px;
          text-transform: uppercase;
          font-weight: bold;
          background-color: transparent;
          cursor: pointer;
          border: 1px solid rgba(41, 49, 63, 0.3);
          font-size: 17px;
          line-height: 20px;
          color: #354052;
        }
      }
    }

    .rm-impact-information {
      background: #fff;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      h6 {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1875px;
        text-transform: uppercase;
        color: #4b5258;
        padding: 28px 28px 20px;
        margin-bottom: 0;
      }
      .rm-graph-tab-nav {
        list-style-type: none;
        margin: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 16px;
        li {
          button {
            border: 1px solid rgba(53, 64, 82, 0.3);
            box-sizing: border-box;
            border-radius: 6px;
            padding: 6px;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #354052;
            background-color: transparent;
            &.active {
              background: #354052;
              border: 1px solid #354052;
              font-weight: 500;
              color: #fff;
            }
          }
          &:not(last-of-type) {
            margin-right: 7px;
          }
        }
      }
      .activity_impact_grpah {
        box-shadow: none !important;
        .card-body {
          padding: 0 !important;
        }
      }
    }

    .pi-device-location {
      overflow: hidden;
      margin-top: 10px;
      margin-bottom: 10px;
      position: relative;
      border-radius: 8px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

      .pi-device-map {
        position: relative;
        width: 100%;
        height: 320px;
      }
    }

    .rm-start-inspection {
      width: 100%;
      padding: 15px 0;
      button {
        display: block;
        width: 100%;
        height: 50px;
        background: #2c7be5;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }
    .rm-report-added {
      margin-top: 30px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 50px;
        background-color: #fff;
        border-radius: 6px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        color: #354052;
      }
    }
  }
}

.imagePreviewModal {
  background: transparent !important;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  .modal-content {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    .modal-header {
      background: transparent !important;
      border-radius: 0 !important;
      padding-right: 0 !important;
      .close {
        text-shadow: none;
        opacity: 1;
        span {
          color: #fff !important;
        }
      }
    }
  }
}
.modal-backdrop.show {
  background: #3c4145;
  opacity: 0.3;
}

@media screen and (max-width: 768px) {
  .rm-main {
    height: 100%;
  }
}
