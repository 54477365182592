.pi-create-user-role-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

	.pi-header {
		display: flex;
		padding: 10px 30px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $color-border;

		h3 {
			display: flex;
			font-weight: 500;
			margin-bottom: 0;
			color: $color-text;
			align-items: center;
			text-transform: uppercase;

			.pi-back-btn {
				border: 0;
				padding: 0;
				font-size: 24px;
				margin-right: 12px;
				color: $color-text;
				background-color: transparent;

				i {
					@include transform(rotate(90deg));
				}
			}
		}
	}

	.pi-create-role-container {
		width: 100%;
		overflow: auto;
		padding: 0 30px;
		max-width: 950px;
		flex: 1;
		//height: calc(var(--app-height) - 80px);
	}

	.pi-create-role-content {
		padding: 0 39px;
	}
}
