@media (min-width: 1201px) {
    .pi-filter-block {
        min-width: 700px;
    }
}

@media (max-width: 1200px) {
    .pi-search {
        .pi-applied-filters {
            overflow-x: auto;

            .pi-tags {
                width: 100%;
                padding-bottom: 5px;

                li {
                    white-space: nowrap;
                }
            }
        }
    }

    .pi-filter-block .pi-block-body .pi-group-row {
        .pi-date-picker, .pi-select {
            max-width: 100%;

            &:first-of-type {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }

        .pi-column {
            width: 100%;
        }

        .pi-column.pi-custom-column {
            padding-left: 0;
            margin-top: 25px;
        }
    }
}

@media (max-width: 1200px) {
    .pi-filter-block {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100%;
        overflow: auto;
        border-radius: 0;

        .pi-block-footer {
            padding-left: 15px;
            padding-right: 15px;
            justify-content: center;
        }
    }
}