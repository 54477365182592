.range-slider {
	margin-bottom: 18px;

	.input-range__track, input-range {
		height: 7px;
	}

	.input-range__slider, .input-range__track--active {
		background: #4CAF50;
		border: 1px solid #4CAF50;
	}

	.input-range__label-container {
		color: #000000;
	}

	&.battery-range {
		.input-range__label .input-range__label-container {
			&::after {
				content: '%';
			}
		}
	}
}
.sensitivity_labels{
	label{
		color: #000000;
		font-family: "Helvetica Neue", san-serif;
		font-size: 0.8rem;
		position: relative;
		&:first-child{
			left: -2.5%;
		}
		&:last-child{
			left: 3.5%;
		}
	}
 }
@media (min-width: 1200px){
	.container {
		max-width: 1725px;
	}
}
@media (min-width: 991px){
	.input-range__label-container{
		left: 0 !important;
	}
	.sensitivity_labels {
		label {
			color: #000000;
			font-family: "Helvetica Neue", san-serif;
			font-size: 0.8rem;
			position: relative;

			&:first-child {
				left: 0 !important;
			}

			&:last-child {
				left: 0 !important;
			}
		}
	}
}
