// RC range slider

.pi-rc-slider {
  .rc-slider {
    position: relative;

    .rc-slider-rail {
      height: 14px;
      border-radius: $radius-md;
      background: linear-gradient(90deg, #63d682 0%, #e0cb59 34%, #d28b38 68.03%, #e90f0f 100%);
    }

    .rc-slider-track {
      position: absolute;
      top: 0;
      display: none;
    }

    .rc-slider-tooltip {
      display: none;
    }

    .rc-slider-handle {
      top: -11px;
      width: 35px;
      height: 35px;
      outline: none;
      margin-top: 0;
      cursor: pointer;
      position: absolute;
      background-color: #fff;
      border: 1px solid #e7e7e7;
      border-radius: $radius-circle;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    .rc-slider-tooltip-inner {
      display: none;
    }
  }

  .pi-slider-header {
    display: flex;
    margin-bottom: 25px;

    h3 {
      font-size: $md;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 12px;
      color: $color-text;
      margin-right: 25px;
      text-transform: uppercase;
    }

    .pi-text-field {
      font-size: $extra - 4px;
    }

    .pi-slider-settings {
      width: 100%;
      display: flex;

      &:not(.pi-actions) {
        max-width: 225px;
      }

      &.pi-actions {
        max-width: 400px;
      }
    }

    .pi-field-wrapper {
      margin-right: 5px;
    }

    .pi-increment {
      .pi-field-wrapper {
        position: relative;

        input {
          padding: 0 45px;
          text-align: center;
        }

        .pi-inc,
        .pi-dec {
          top: 2px;
          border: 0;
          z-index: 1;
          padding: 0;
          width: 30px;
          height: 39px;
          font-size: 20px;
          line-height: 39px;
          color: $color-text;
          position: absolute;
          text-align: center;
          background-color: $color-bg;
        }

        .pi-inc {
          right: 1px;
          border-top-right-radius: $radius-sm;
          border-left: 1px solid $color-border;
          border-bottom-right-radius: $radius-sm;
        }

        .pi-dec {
          left: 1px;
          border-top-left-radius: $radius-sm;
          border-right: 1px solid $color-border;
          border-bottom-left-radius: $radius-sm;
        }
      }
    }

    .pi-btn-wrapper {
      display: flex;
      white-space: nowrap;

      .pi-btn:nth-child(2) {
        margin-left: 2px;
      }

      .pi-pending-status {
        border: 0;
        color: #fff;
        height: 41px;
        display: flex;
        padding: 0 12px;
        font-weight: 400;
        align-items: center;
        border-radius: $radius-sm;
        align-content: space-between;
        @include transition(all 0.2s);
        background-color: rgba($color-text, 0.5);

        i {
          opacity: 0.7;
          font-size: 20px;
          padding-left: 8px;
        }
      }
    }
  }

  .pi-slider-inner {
    display: flex;
    align-items: center;

    .rc-slider {
      padding: 0;
      width: 100%;
      margin: 0 25px;
      //max-width: 560px;
    }

    .pi-high-value,
    .pi-low-value {
      strong {
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: $color-text;
      }

      span {
        font-size: $sm;
        font-weight: 500;
        color: rgba($color-text, 0.6);
      }
    }
  }
}
