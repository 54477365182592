.pi-subgroup-header {
    display: flex;
    cursor: pointer;
    margin-bottom: 6px;
    position: relative;
    align-items: center;
    background-color: #fff;
    border-radius: $radius-sm;
    padding: 11px 90px 11px 10px;
    @include transition(all 0.2s);
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &.show {
        &::before {
            border-color: $color-text;
            background-color: $color-text;
        }

        &::after {
            background-color: $color-text;
        }

        & + .pi-collapse {
            &::before {
                border-color: $color-text;
            }
        }

        .pi-logs-header {
            &::after {
                @include transform(translateY(-50%) rotate(270deg));
            }
        }

        .pi-collapse-header {
            position: relative;

            &::before {
                content: ' ';
                height: 1px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: calc(100% - 40px);
                border-top: 1px solid $color-border;
            }
        }
    }

    .pi-border-left {
        position: relative;

        &::after {
            right: 0;
            top: 50%;
            width: 1px;
            content: '';
            height: 42px;
            position: absolute;
            display: inline-block;
            background-color: $color-border;
            @include transform(translateY(-50%));
        }
    }

    .pi-name {
        padding-left: 15px;
        padding-right: 30px;

        span, strong {
            display: block;
        }

        span {
            font-size: $sm;
            text-transform: uppercase;
            color: rgba($color-text, 0.6);
        }

        strong {
            font-size: $md;
            font-weight: 500;
            color: $color-text;
        }
    }

    .pi-username {
        display: flex;
        padding: 0 30px;
        align-items: center;

        a, button {
            font-size: $md;
            cursor: pointer;
            font-weight: 500;
            @include transition(all 0.2s);

            &:hover {
                opacity: 0.8;
            }

            &:not([class*="pi-color-"]) {
                color: $color-text;
            }
        }

        a {
            padding-left: 8px;
            text-decoration: underline;
        }

        button {
            border: 0;
            padding: 0 0 0 8px;
            background-color: transparent;
        }
    }

    &::after {
        top: 50%;
        right: 32px;
        font-size: 24px;
        position: absolute;
        color: $color-text;
        content: $icon-expand-right;
        @include transition(all 0.2s);
        font-family: $font-family-icons;
        @include transform(translateY(-50%) rotate(90deg));
    }

    &::before {
        top: 50%;
        width: 1px;
        right: 88px;
        content: '';
        height: 42px;
        position: absolute;
        display: inline-block;
        background-color: $color-border;
        @include transform(translateY(-50%));
    }
}

.pi-collapse {
    &.show .pi-subgroup-header {
        border-bottom: 0;
        box-shadow: none;
        margin-bottom: 0;
        border-color: $color-border;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
            @include transform(translateY(-50%) rotate(270deg));
        }

    }
}

@media (max-width: 1100px) {
    .pi-subgroup-header {
        flex-wrap: wrap;
        padding-right: 60px;

        &::before {
            display: none;
        }

        &::after {
            top: 20px;
            right: 10px;
        }

        .pi-actions {
            position: absolute;
            top: 0;
            right: 30px;
        }

        .pi-name {
            width: 100%;
            padding-left: 0;

            &::after {
                display: none;
            }

            span {
                display: none;
            }
        }

        .pi-username {
            width: 100%;
            padding-left: 0;
            margin-top: 15px;
        }
    }
}