@media (min-width: 1601px) {
    .pi-devices-table {
        tr {
            td, th {
                &:nth-child(1) {
                    .pi-cell-note {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) and (min-width: 1366px) {
    .pi-devices-table {
        thead {
            .pi-resp-title {
                display: block;
                
                & + .pi-title {
                    display: none;
                }
            }
        }
        tr {
            td, th {
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(6) {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 1365px) {
    .pi-devices-wrapper {
        .pi-devices-table {
            @include table();
            tbody {
                tr {
                    width: calc(50% - 5px);

                    td {
                        &.pi-type-text-icon {
                            .pi-td {
                                width: fit-content;

                                strong {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            tr {
                td, th {
                    &:nth-child(1) {
                        .pi-cell-note {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @include table-actions();
}

@media (max-width: 1200px) {
    .pi-devices-wrapper {
        .pi-page-body {
            height: calc(100% - 150px);
        }
        .pi-devices-table {
            tr {
                overflow: visible !important;
            }
            th, td {
                width: 100% !important;
            }
            td {
                &:not(.pi-empty-td):last-child {
                    position: absolute !important;
                    top: 0;
                    right: 0;
                    width: 41px !important;

                    .pi-td {
                        padding: 0;

                        .pi-menu-expandable {
                            margin: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .pi-device-modal {
        max-width: 100% !important;
    }
}

@media (max-width: 900px) {
    .pi-devices-wrapper {
        .pi-devices-table {
            tbody {
                tr {
                    width: 100%;
                }
            }
        }
    }
}