// Custom popover styles

.pi-popover {
    padding: 25px 20px;
    position: absolute;
    z-index: 3;
    margin-top: 10px;
    border-radius: $radius-md;
    background-color: $color-text;

    &:not(.pi-align-right):not(.pi-align-custom) {
        left: 50%;
        @include transform(translateX(-50%));
    }

    &.pi-size {
        &-sm {
            width: 245px;
        }
    }

    &.pi-align {
        &-right {
            right: 0;
        }
    }

    h5 {
        color: #fff;
        font-weight: 500;
        margin-bottom: 8px;
    }

    p {
        font-size: $sm;
        color: rgba(#fff, 0.7);

        strong {
            font-weight: 700;
        }
    }

    .pi-btn-wrapper {
        display: flex;
        margin-top: 25px;
        justify-content: flex-end;

        .pi-btn:nth-child(2) {
            margin-left: 8px;
        }
    }
}

.pi-has-popover {
    position: relative;
}