@media (min-width: 1201px) {
    .pi-workzone-map-wrapper {
        .pi-filter-mobile-btn {
            display: none;
        }

        .pi-filter-bar-top, .pi-filter-bar-bottom {
            display: none;
        }
    }
}

@media (max-width: 1366px) {
    .pi-workzone-map-wrapper {
        height: var(--app-height);
        position: relative;

        .pi-workzone-map-filter {
            .pi-filter-bar-item {
                flex-wrap: wrap;
                width: min-content;

                .pi-filter-name {
                    width: 100%;
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@media (max-width: 1200px) and (min-width: 661px) {
    .pi-device-map-content {
        height: 100% !important;
    }
}

@media (max-width: 1200px) {
    .pi-workzone-map-wrapper {
        position: relative;

        .pi-filter-mobile-btn {
            display: block;
        }

        .pi-workzone-map-filter {
            &.pi-open {
                position: fixed;
                height: 100%;
                width: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 4;
                overflow: auto;
                background-color: #fff;
            }

            &:not(.pi-open) {
                display: none;
            }
        }

        .pi-filter-bar-top {
            display: flex;
            margin-bottom: 15px;
        }

        .pi-filter-bar-bottom {
            display: flex;
        }

        .pi-filter-bar-group {
            display: block;
            min-height: calc(100% - 180px);

            .pi-filter-bar-item {
                width: 100%;
                display: block;
                padding-right: 0;
                margin-bottom: 10px;

                .pi-radio-group {
                    overflow: auto;
                    flex-wrap: nowrap;
                }

                &:not(:last-of-type) {
                    border-right: 0;
                }

                .pi-select {
                    width: 226px;
                }
            }
        }
    }
}

@media (min-width: 661px) {
    .pi-workzone-map-wrapper {
        .pi-page-title {
            display: none;
        }
    }
}