// Authorization flow styles

@media only screen and (max-width: 730px) {
  .pi-auth-container {
    height: auto !important;
    padding-left: 25px;
    padding-right: 25px;
    overflow: visible;
  }

  .pi-auth-wrapper {
    .pi-validation-rules {
      flex-wrap: wrap;

      li {
        &:last-child {
          padding-left: 0;

          &::after {
            display: none;
          }
        }

        &:first-child {
          padding-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 730px) and (max-height: 700px), (max-height: 700px) {
  .pi-auth-wrapper {
    justify-content: start;
    overflow: auto;
    height: 100dvh;
  }
}

