// Page title styles and alignment

.pi-page-title {
    display: flex;
    align-items: flex-start;
    background-color: #F5F8FA;
    padding: 33px 40px 25px 40px;
    border-bottom: 1px solid $color-border;

    .pi-sidebar-trigger, .pi-sidebar-close {
        border: 0;
        font-size: 24px;
        color: $color-text;
        padding: 0 15px 0 0;
        @include transition(all 0.2s);
        background-color: transparent;

        &:hover {
            color: $color-text;
        }
    }

    .pi-back-btn {
        border: 0;
        padding: 0;
        font-size: 20px;
        margin-right: 12px;
        color: $color-text;
        background-color: transparent;

        i {
            @include transform(rotate(90deg));
        }
    }

    h1 {
        font-weight: 500;
        margin-bottom: 0;
        line-height: 41px;
        font-size: $extra;
        color: $color-text;
        margin-right: 25px;
        white-space: nowrap;

        &.pi-has-btn {
            display: flex;
            align-items: center;
        }
    }

    .pi-search {
        width: 100%;
        max-width: 820px;
        margin-right: 15px;
    }

    .pi-page-action {
        display: flex;
        margin-left: auto;
        padding-left: 20px;

        button {
            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }
}