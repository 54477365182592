// All input styles

.pi-text-field {
  position: relative;

  .pi-field-wrapper {
    width: 100%;
    height: 41px;
    line-height: 39px;
    border-radius: $radius-sm;
    @include transition(all 0.2s);
    border: 1px solid $color-border;
    background-color: white;
    display: flex;
    overflow: hidden;
    input {
      &:focus {
        outline: none;
        border-color: $color-text;
      }
      flex: 1;
      height: 100%;
      box-shadow: none !important;
      width: inherit;
    }

    .pi-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:not(.pi-with-icon) {
    input {
      padding: 0 12px;
    }
  }

  &.pi-left-icon {
    input {
      padding-left: 48px;
    }
    .pi-icon {
      left: 16px;
    }
  }

  &.pi-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    //input {
    //}
  }

  .pi-icon-wrapper,
  .pi-field-wrapper {
    position: relative;

    .pi-icon {
      font-size: 24px;
      height: 100%;
      width: 40px;
    }
  }

  .pi-hint {
    font-size: $xs;
    padding-top: 3px;
    margin-bottom: 0;
    color: rgba($color-text, 0.7);
  }

  &.pi-invalid {
    .pi-error-message {
      font-size: $xs;
      margin-bottom: 0;
      color: $color-red;
      position: absolute;
    }

    &:not(.pi-error-two-lines) {
      .pi-error-message {
        bottom: -19px;
      }
    }

    &.pi-error-two-lines {
      .pi-error-message {
        bottom: -38px;
      }
    }
  }

  &.pi-password-field {
    .pi-icon {
      font-size: 18px;
      cursor: pointer;
      color: $color-text;
    }
  }

  &.pi-phone-field {
    .pi-field-wrapper {
      &::before {
        content: '+';
        position: absolute;
        top: 50%;
        padding: 0 12px;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: rgba($color-text, 0.5);
        @include transform(translateY(-50%));
        border-right: 1px solid $color-border;
      }
    }

    input {
      padding-left: 48px;
    }
  }
}

// Should be Removed after WZ team redesign
.phone-field {
  padding-left: 45px;

  & + small {
    position: relative;

    &::before {
      content: '+';
      position: absolute;
      top: -36px;
      padding: 0 12px;
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: rgba($color-text, 0.5);
      border-right: 1px solid $color-border;
    }
  }
}
