// Team page: Subgroup WorkZone tab

.pi-workzone-search {
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .pi-search-field {
        width: calc(100% - 55px);

        input {
            padding-left: 40px;
        }

        svg {
            left: 15px !important;
        }
    }
}