@media (min-width: 660px) {
    .pi-page-title {
        .pi-sidebar-trigger {
            display: none;
        }
    }
}

@media (max-width: 1200px) {
    .pi-page-title {
        flex-wrap: wrap;
        align-items: center;
        padding: 25px 20px 5px;

        h1 {
            margin-right: 0;
            font-size: 22px;
        }

        .pi-search {
            max-width: 100%;
            padding-top: 12px;
            margin-right: 0;

            &::before {
                content: '';
                height: 1px;
                display: block;
                position: absolute;
                top: 0;
                left: -20px;
                width: calc(100% + 40px);
                background-color: $color-border;
            }
        }
        .pi-page-action {
            position: absolute;
            top: 18px;
            right: 20px;
            .pi-btn.pi-left-icon {
                padding: 0 10px;
                i {
                    padding-right: 0;
                }
            }
            span {
                display: none;
            }
        }

        .pi-back-btn {
            padding-bottom: 0;
        }
    }
}