// Default styles improvements

body {
    font-family: "Roboto", san-serif !important;
}
ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

sub {
    bottom: 0;
    text-transform: none;
    vertical-align: inherit;
}

button:focus {
    outline: none !important;
}