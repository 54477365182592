.pi-roles-empty {
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--app-height);

	.pi-empty-content {
		text-align: center;
		color: $color-text;

		h2 {
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 15px;
		}

		p {
			font-size: 18px;
			font-weight: 400;
			margin-bottom: 30px;
		}

		.pi-btn {
			margin: auto;
		}
	}
}