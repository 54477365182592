.container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px;
  padding: 12px 24px;

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.21875px;
    margin: 0px;
    min-height: 31px;
    vertical-align: middle;
    text-align: left;
  }

  .createLabel {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #354052;
    margin: 0px;
    min-height: 31px;
    vertical-align: middle;
    text-align: left;

    .create {
      cursor: pointer;
      color: rgba(44, 123, 229, 1);
    }
  }
}
