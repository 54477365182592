.w-40 {
    width: 40px;
}

.mt-8 {
    margin-top: 8px;
}

.mb-2rem {
    margin-bottom: 2rem;
}

.mb-8 { margin-bottom: 8px; }
.mr-8 { margin-right: 8px; }
.mt-16 { margin-top: 16px; }
.mr-16 { margin-right: 16px; }

.mb-1 { margin-bottom: 8px; }

.ml-8 { margin-left: 8px; }

.pd-8 { padding: 8px; }
.pdt-8 { padding-top: 8px}
.pdl-8 { padding-left: 8px}
.pdt-15 { padding-top: 15px}
.pd-32 { padding: 32px; }

.font-1 { font-size: 1.1em; }
.font-2 { font-size: 1.2em; }
.font-6 { font-size: 1.6em; }
.font-lg { font-size: $font-size-lg; }
.font-base { font-size: $font-size-base; }
.font-sm { font-size: $font-size-sm; }

.font-bold { font-weight: $font-weight-bold; }

.letter-spacing { letter-spacing: $letter-spacing}
