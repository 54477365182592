// Profile card component

.pi-profile-field {
    display: flex;
    position: relative;
    padding: 15px 20px;
    margin-bottom: 8px;
    align-items: center;
    background-color: #fff;
    border-radius: $radius-md;
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &.pi-invalid {
        border-color: $color-red;
    }

    .pi-field-key {
        width: 100%;
        display: flex;
        max-width: 140px;
        align-items: center;
        color: rgba($color-text, 0.5);

        i {
            font-size: 20px;
        }

        span {
            font-size: $sm;
            font-weight: 400;
            padding-left: 14px;
            text-transform: uppercase;
        }
    }

    .pi-field-value {
        strong {
            font-size: $md;
            font-weight: 500;
            color: $color-text;
        }
    }

    .pi-field-action {
        margin-left: auto;
        padding-left: 24px;
        border-left: 1px solid $color-border;

        button {
            border: 0;
            font-size: 22px;
            background-color: transparent;

            &:not(:disabled) {
                color: $color-text;
            }

            &:disabled {
                color: rgba($color-text, 0.5);
            }
        }
    }
}

.pi-profile-field-wrapper {
    position: relative;

    .pi-field-error {
        position: absolute;
        top: 50%;
        right: -600px;
        display: flex;
        max-width: 570px;
        padding: 16px 18px;
        align-items: center;
        background-color: #DD5E5E;
        border-radius: $radius-xs;
        transform: translateY(-50%);
        border: 1px solid rgba(53, 64, 82, 0.2);

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-width: 15px 22px 15px 0;
            border-color: transparent #DD5E5E transparent transparent;
            border-style: solid;
            position: absolute;
            left: -13px;
        }

        .pi-close {
            background-color: transparent;
            border: 0;
            color: rgba(#fff, 0.5);
            border-left: 1px solid rgba(#fff, 0.1);
            padding: 2px 0 2px 10px;

            i {
                font-size: 20px;
            }
        }

        .pi-error-content {
            padding-left: 16px;
        }

        i {
            color: #fff;
            font-size: 24px;
        }

        h4 {
            color: #fff;
            font-size: 16px;
            margin-bottom: 8px;
        }
        
        p {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}

// Card Responsive styles

@media (max-width: 1600px) {
    .pi-profile-field-wrapper {
        .pi-field-error {
            right: -500px;
            max-width: 450px;
        }
    }
}

@media (max-width: 1500px) {
    .pi-profile-field-wrapper {
        .pi-field-error {
            position: relative;
            right: 0;
            top: 0;
            transform: none;
            max-width: 100%;
            margin-bottom: 15px;

            &::before {
                display: none;
            }
        }
    }
}

@media (max-width: 780px) {
    .pi-profile-field {
        padding: 10px;
        flex-wrap: wrap;

        .pi-field-key {
            max-width: none;
        }

        .pi-field-value {
            width: 100%;
            margin-top: 5px;
            padding-left: 34px;
            @include ellipsis(100%);

            strong {
                font-size: 14px;
            }
        }

        .pi-field-action {
            position: absolute;
            top: 10px;
            right: 5px;
            border-left: 0;

            button {
                font-size: 18px;
            }
        }
    }
}