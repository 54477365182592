$icon-warning-alt: "\e95c";
$icon-flood: "\e909";
$icon-lane-closure: "\e957";
$icon-snow-plow: "\e958";
$icon-vehicle-stopped: "\e959";
$icon-waste-vehicle: "\e95a";
$icon-construction-hard-hat: "\e95b";
$icon-manage-accounts: "\e956";
$icon-error-circle: "\e953";
$icon-visibility-on: "\e954";
$icon-visibility-off: "\e955";
$icon-devices: "\e951";
$icon-missing: "\e952";
$icon-restart: "\e94f";
$icon-backup-restore: "\e950";
$icon-analytics: "\e94e";
$icon-bar-chart: "\e94b";
$icon-night-light: "\e94c";
$icon-sunny: "\e94d";
$icon-upload: "\e94a";
$icon-radio-button-unchecked: "\e948";
$icon-radio-button-checked: "\e949";
$icon-group: "\e947";
$icon-remove-circle-outline: "\e946";
$icon-devices-old: "\e945";
$icon-call: "\e940";
$icon-email: "\e941";
$icon-logout: "\e942";
$icon-notifications: "\e943";
$icon-job: "\e944";
$icon-add-location: "\e93b";
$icon-email-send: "\e93c";
$icon-group-add: "\e93d";
$icon-add-admin: "\e93e";
$icon-map-marker: "\e93f";
$icon-show-chart: "\e93a";
$icon-workspaces: "\e939";
$icon-task-black: "\e938";
$icon-drawer-menu: "\e936";
$icon-floating-filter: "\e937";
$icon-circle-remove: "\e931";
$icon-image: "\e932";
$icon-check: "\e933";
$icon-attach-money: "\e934";
$icon-unfold-more: "\e935";
$icon-pause: "\e92e";
$icon-checkbox: "\e92f";
$icon-checkbox-outline: "\e930";
$icon-battery-alert: "\e927";
$icon-build-circle: "\e928";
$icon-chart-bar: "\e929";
$icon-engineering: "\e92a";
$icon-circle-plus: "\e92b";
$icon-file-download: "\e92c";
$icon-double-arrow: "\e92d";
$icon-alert-octagon-o: "\e908";
$icon-account-arrow: "\e921";
$icon-alert-octagon: "\e922";
$icon-notes: "\e923";
$icon-speed: "\e924";
$icon-calendar: "\e925";
$icon-event: "\e926";
$icon-account: "\e900";
$icon-assessment: "\e901";
$icon-close: "\e902";
$icon-delete: "\e903";
$icon-desktop: "\e904";
$icon-exit: "\e905";
$icon-expand-right: "\e906";
$icon-expand-down: "\e907";
$icon-groups: "\e90a";
$icon-help: "\e90b";
$icon-edit: "\e90c";
$icon-info: "\e90d";
$icon-map: "\e90e";
$icon-more-vert: "\e90f";
$icon-person-add: "\e910";
$icon-place: "\e911";
$icon-play-arrow: "\e912";
$icon-radio-on-path1: "\e913";
$icon-radio-on-path2: "\e914";
$icon-radio-off-path1: "\e915";
$icon-radio-off-path2: "\e916";
$icon-schedule: "\e917";
$icon-search: "\e918";
$icon-signal-path1: "\e919";
$icon-signal-path2: "\e91a";
$icon-signal-path3: "\e91b";
$icon-signal-path4: "\e91c";
$icon-arrow-down: "\e91d";
$icon-filter: "\e91e";
$icon-arrow-up: "\e91f";
$icon-warning: "\e920";
$icon-emergency: "\e95d";
