.pi-WorkZone-wrapper {
  .pi-filter-bar-content {
    padding: 0;
  }
}
.pi-WorkZone-table,
.pi-detailDevice-workzone-table {
  table {
    tbody {
      tr {
        td {
          &:last-child {
            p {
              overflow: visible !important;
            }
          }
        }
      }
      .pi-menu-expandable {
        text-align: center;
        button {
          border: none;
          box-shadow: none;
          i {
            color: $color-text;
          }
        }
      }
    }
  }
}
.pi-device-details-workZone {
  //max-width: 700px;
  .pi-search-bar {
    padding: 13px;
    background: $body-bg;
    border: 1px solid $color-border;
    border-top: none;
  }
  .pi-table {
    table {
      &.pi-white-row {
        background-color: transparent;
      }
    }
  }
  .pi-device-info {
    .pi-device-header {
      padding: 0 30px 0 0;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0 15px;
      .flex {
        display: flex;
        align-items: center;
      }
      .pi-device-info-switch {
        border: 1px solid rgba(53, 64, 82, 0.3);
        box-sizing: border-box;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 16px;
        margin-right: 20px;
        .toggle-switch {
          &-title {
            color: rgba($text-color, 0.7);
          }
        }
      }
      p {
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: rgba($color-text, 0.7);
        margin-bottom: 0;
        padding-left: 20px;
        position: relative;
        strong {
          color: $color-text;
        }
        &:before {
          content: '';
          width: 1px;
          height: 100%;
          display: block;
          max-height: 21px;
          position: absolute;
          background-color: #e4e4e4;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
  .pi-info-tabs {
    .tab-content {
      .pi-information-team {
        border: 1px solid #e7e7e7;
        border-radius: 8px;
        padding: 20px 8px;
        margin-top: 8px;
        position: relative;
        &-title {
          margin-left: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid $color-border;
          margin-bottom: 12px;
          max-width: 364px;
          p {
            font-weight: bold;
            font-size: 0.9375rem;
            line-height: 25px;
            text-transform: uppercase;
            color: rgba($color-text, 0.7);
            margin-bottom: 0;
            strong {
              color: $color-text;
            }
          }
        }
        .pi-teams-group-list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          li {
            padding: 0 12px;
            p {
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 25px;
              color: rgba($color-text, 0.7);
              margin-bottom: 0;
              strong {
                color: $color-text;
              }
            }
            &:not(:last-of-type) {
              position: relative;
              &:before {
                content: '';
                width: 1px;
                height: 100%;
                display: block;
                max-height: 21px;
                position: absolute;
                background-color: #e4e4e4;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
        .pi-view-team-button {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }
  }
}
.pi-workZone-device {
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  background-color: #fff;
  top: 12px;
  left: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: auto;
  z-index: 2;
  &-info {
    background: $color-bg;
    padding: 12px;
    .pi-workZone-device-header {
      display: flex;
      align-items: center;
      margin: 8px 0 16px;
      .pi-close {
        color: rgba($color-text, 0.7);
        background-color: transparent;
        border: none;
        transition: all 0.2s;
        transform: rotate(-90deg);
        padding: 0;
        margin: 0 16px;
        i {
          font-size: 20px;
        }
        &:hover {
          color: $color-text;
        }
      }
      h2 {
        margin-bottom: 0;
      }
    }
    .pi-details-body {
      .pi-image {
        //display: flex;
        //align-items: center;
        //justify-content: center;
        max-width: 200px;
        background-color: #fff;
      }
      .pi-details {
        max-width: calc(100% - 200px);
        padding: 15px 15px 15px 25px;
        .pi-details-list {
          li {
            .pi-device-info-switch {
              border: 1px solid rgba(53, 64, 82, 0.3);
              box-sizing: border-box;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              padding: 10px 16px;
              margin: 0 20px;
              background-color: #fff;
              .toggle-switch {
                &-title {
                  color: rgba($text-color, 0.7);
                }
              }
            }
          }
        }
      }
      .pi-details-settings {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        border-top: 1px solid $color-border;
        &-switch {
          display: flex;
          align-items: center;
          .toggle-switch {
            padding: 0 16px;
            position: relative;
            &:before {
              content: '';
              width: 1px;
              height: 100%;
              display: block;
              max-height: 41px;
              position: absolute;
              background-color: #e4e4e4;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            &-title {
              font-size: 0.9375rem;
            }
            &:first-child {
              padding-left: 0;
              &:before {
                display: none;
              }
            }
          }
        }
        .pi-select {
          max-width: 170px;
          width: 100%;
        }
      }
      .pi-details-scheduleMode {
        padding: 18px 16px;
        border-top: 1px solid $color-border;
        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .pi-checkbox {
            font-weight: 500;
            font-size: 0.9375rem;
            line-height: 1.3125rem;
            color: $color-text;
            margin: 5px 0;
            input[type='checkbox'] {
              display: block;
              position: static;
              width: 17px;
              height: 17px;
              border: 1px solid $color-text;
              margin-right: 8px;
            }
          }
          .pi-edit-button {
            font-size: 18px;
            color: $color-text;
            background-color: transparent;
            border: none;
          }
          .pi-schedule-actions {
            display: block;
            .pi-cancel {
              border: 1px solid rgba($color-text, 0.3);
              margin-right: 4px;
            }
          }
        }
        &-body {
          .pi-details-scheduleMode-edited {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .pi-info-string {
              padding: 2px 15px;
              position: relative;
              &:before {
                content: '';
                width: 1px;
                height: 30px;
                display: block;
                max-height: 41px;
                position: absolute;
                background-color: #e4e4e4;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
              &:first-child {
                padding-left: 0;
              }
            }
            .pi-details-scheduleMode-weekdays {
              width: 100%;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              padding-left: 0;
              margin-top: 15px;
              .pi-info-string {
                &:before {
                  display: none;
                }
              }
            }
            .pi-tags {
              flex-wrap: wrap;
              li {
                background-color: rgba($color-text, 0.1);
                &:first-child {
                  margin-left: 0;
                }
                span {
                  color: $color-text;
                }
              }
            }
          }
          .pi-details-scheduleMode-editable {
            .pi-timepicker-body {
              display: flex;
              margin-bottom: 50px;
              .pi-timepicker {
                max-width: 25%;
                margin-right: 16px;
                .MuiFormControl-root {
                  width: 100%;
                  .MuiInputBase-root {
                    border: none;
                    .MuiInputBase-input {
                      height: 40px;
                      border: 1px solid $color-border;
                      box-sizing: border-box;
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                      border-radius: 4px;
                      margin: 0 !important;
                      padding: 0 15px;
                      background-color: #fff;
                    }
                  }
                }
              }
            }
            label {
              width: 100%;
              span {
                display: block;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1rem;
                color: #4b5258;
                margin-bottom: 8px;
              }
            }
            .pi-multiSelect {
              .css-bg1rzq-control {
                border: 1px solid $color-border;
                box-sizing: border-box;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                border-radius: 4px;
              }
              .select__indicators {
                display: none;
              }
              .css-151xaom-placeholder {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .pi-info-tabs {
    padding: 0;
    .pi-tab-navigation {
      padding: 0 12px;
      border: none;
      border-radius: 0;
      .pi-tab-nav-item {
        .pi-tab-nav-link {
          padding: 8px 15px 12px;
        }
      }
    }
    .tab-content {
      padding: 12px;
      .pi-device-location {
        margin-top: 0;
      }
      .pi-search-bar {
        border: none;
        border-bottom: 1px solid $color-border;
      }
    }
  }
}

.pi-calendarMode-modal {
  .pi-modal-body {
    label {
      width: 100%;
      span {
        display: block;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #4b5258;
        margin-bottom: 8px;
      }
    }
    .pi-multiSelect {
      .css-bg1rzq-control {
        border: 1px solid $color-border;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
      .select__indicators {
        display: none;
      }
      .css-151xaom-placeholder {
        display: none;
      }
    }
  }
}
.pi-wz-modal {
  .pi-modal-body {
    padding: 0 30px;
    .pi-weekdays-label {
      width: 100%;
      span {
        display: block;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #4b5258;
        margin-bottom: 8px;
      }
    }
    .pi-timepicker-body {
      display: flex;
      margin-bottom: 36px;
      .pi-timepicker {
        width: calc(50% - 8px);
        margin-right: 16px;
        span {
          display: block;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1rem;
          color: #4b5258;
          margin-bottom: 8px;
        }
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            border: none;
            .MuiInputBase-input {
              height: 40px;
              border: 1px solid $color-border;
              box-sizing: border-box;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
              border-radius: 4px;
              margin: 0 !important;
              padding: 0 15px;
            }
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    .pi-search-bar.pi-search-bar-with-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pi-text-field {
        width: 100%;
        max-width: calc(100% - 50px);
      }
    }
    .pi-notification-channel {
      h6 {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        color: $text-color;
        margin-bottom: 18px;
      }
    }
    .pi-move-member {
      h6 {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: rgba($text-color, 0.8);
      }
      label {
        width: 100%;
        margin-bottom: 32px;
        & > span {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          color: rgba(53, 64, 82, 0.8);
          margin-bottom: 3px;
        }
      }
    }
  }
  .pi-team-singleSelect {
    .css-bg1rzq-control {
      border: 1px solid $color-border;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      min-height: 41px;
    }
    .select__indicators {
      display: none;
    }
    .css-151xaom-placeholder {
      display: none;
    }
  }
  .pi-select-arrow {
    .css-1hwfws3 {
      background-image: url('../../../assets/img/svg/down-arrow.svg');
      background-repeat: no-repeat;
      background-position: right 25px center;
    }
  }
  .pi-modal-footer {
    padding: 20px;
  }
  &.pi-wz-newTeam {
    .pi-modal-header {
      background-color: $color-bg;
      border-bottom: 1px solid $color-border;
    }
    .pi-modal-body {
      background-color: $color-bg;
      padding: 18px 25px;
      //max-height: 500px;
      overflow-x: auto;

      .pi-text-field.pi-team-text-field {
        margin-bottom: 32px;
      }
      .pi-select-label {
        width: 100%;
        margin-bottom: 32px;
        & > span {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          color: rgba(53, 64, 82, 0.8);
          margin-bottom: 3px;
        }
      }
      .pi-addTeam-options {
        &-list {
          display: flex;
          li {
            width: calc(50% - 4px);
            margin-right: 8px;
            & > span {
              display: block;
              font-weight: 500;
              font-size: 0.875rem;
              line-height: 1rem;
              color: #4b5258;
              margin-bottom: 6px;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        .addTeam-option-tags {
          padding-top: 20px;
          & > span {
            display: block;
            font-size: 0.875rem;
            line-height: 1rem;
            color: #4b5258;
            margin-bottom: 12px;
          }
          ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            li {
              display: flex;
              width: auto;
              font-size: 13px;
              padding: 5px 10px;
              align-items: center;
              border-radius: $radius-sm;
              justify-content: space-between;
              background-color: rgba($color-text, 0.07);
              margin-bottom: 8px;
              margin-right: 8px;

              span {
                font-weight: 400;
                padding-right: 5px;
                color: rgba($color-text, 0.5);
              }

              strong {
                font-weight: 500;
                color: $color-text;
                padding-right: 2px;
              }

              .pi-remove {
                border: 0;
                padding: 0;
                cursor: pointer;
                background-color: transparent;

                &:hover {
                  i {
                    color: $color-red;
                  }
                }

                i {
                  font-size: 17px;
                  @include transition(all 0.2s);
                  color: rgba($color-text, 0.7);
                }
              }
            }
          }
        }
      }
    }
    .pi-side-panel {
      width: 100%;
      max-width: 290px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      height: 100%;
      background-color: $color-bg;
      border-top-right-radius: $radius-md;
      border-left: 1px solid $color-border;
      border-bottom-right-radius: $radius-md;
      form {
        height: 100%;
      }
      .pi-side-body {
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: flex-start;
        height: calc(100% - 82px);
        padding: 30px 20px 0;
        h4 {
          font-weight: 500;
        }
        .pi-team-select {
          width: 100%;
          & > span {
            display: block;
            font-size: 0.875rem;
            font-weight: 500;
            color: rgba(53, 64, 82, 0.8);
            margin-bottom: 3px;
          }
        }
      }
      .pi-side-footer {
        padding: 20px;
        display: flex;
        position: relative;
        z-index: 1;
        background-color: $color-bg;
        border-top: 1px solid $color-border;
      }
    }
    .pi-modal-footer {
      margin-top: 0;
    }
  }
}

.pi-inner-scroll {
  min-height: 300px;
  max-height: calc(100vh - 300px);
}

.pi-add-member-modal,
.pi-add-location-modal {
  .pi-info-tabs {
    .pi-tab-navigation {
      padding: 0 24px;
      border-radius: 0;
      .pi-tab-nav-item {
        .pi-tab-nav-link {
          padding: 12px 0;
          &:after {
            max-width: 100%;
          }
        }
      }
    }
  }
  .pi-new-member-form {
    display: flex;
    flex-wrap: wrap;
    max-width: 668px;
    width: 100%;
    margin: 0 auto;
    padding: 44px 40px;
    .pi-text-field {
      width: calc(50% - 12px);
      margin-right: 24px;
      margin-bottom: 36px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .pi-modal-body {
    &:not(.pi-clear-overflow) {
      overflow: auto;
    }
    .pi-search-bar.pi-search-bar-with-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pi-text-field {
        width: 100%;
        max-width: calc(100% - 50px);
      }
    }
    .pi-team-addGroup {
      width: 100%;
      padding: 32px 25px 0;
      border-top: 1px solid $color-border;
      &-content {
        max-width: 520px;
      }
      label {
        width: 100%;
        margin-bottom: 32px;
        & > span {
          display: block;
          font-size: 0.875rem;
          font-weight: 500;
          color: rgba(53, 64, 82, 0.8);
          margin-bottom: 3px;
        }
        .pi-map-button {
          display: flex;
          width: 100%;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          height: 40px;
          padding: 0;
          border: none;
          &-left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 100%;
            background-color: #fff;
            border-radius: 8px 0 0 8px;
            i {
              font-size: 18px;
              color: $color-text;
            }
          }
          &-right {
            width: calc(100% - 48px);
            height: 100%;
            background-size: cover;
            border-radius: 0 8px 8px 0;
            position: relative;
            overflow: hidden;
            img {
              width: 22px;
              position: absolute;
              left: 40px;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
            }
            &:before {
              display: block;
              content: '';
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.11);
              z-index: 1;
              left: 0;
              top: 0;
            }
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  .pi-side-panel {
    width: 100%;
    max-width: 290px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    height: 100%;
    background-color: $color-bg;
    border-top-right-radius: $radius-md;
    border-left: 1px solid $color-border;
    border-bottom-right-radius: $radius-md;
    form {
      height: 100%;
    }
    .pi-side-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100% - 82px);
      padding: 30px 20px 20px;
      overflow: auto;

      h4 {
        font-weight: 500;
      }
    }
    .pi-side-footer {
      padding: 20px;
      display: flex;
      position: relative;
      z-index: 1;
      background-color: $color-bg;
      border-top: 1px solid $color-border;
    }
  }
  .pi-team-select {
    & > span {
      display: block;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      color: $text-color;
      margin-bottom: 4px;
    }
    .pi-team-singleSelect {
      .css-bg1rzq-control {
        border: 1px solid $color-border;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        min-height: 41px;
      }
      .select__indicators {
        display: none;
      }
      .css-151xaom-placeholder {
        display: none;
      }
    }
  }
  .pi-notification-channel {
    h6 {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      color: $text-color;
      margin-bottom: 18px;
    }
  }
  .pi-select-arrow {
    .css-1hwfws3 {
      background-image: url('../../../assets/img/svg/down-arrow.svg');
      background-repeat: no-repeat;
      background-position: right 25px center;
    }
  }
  .pi-modal-footer {
    padding: 20px;
  }
}
.pi-modal-memberProfile {
  .pi-modal-body {
    padding: 32px !important;
    border-top: 1px solid $color-border;
    position: relative;
    .pi-edit-button {
      position: absolute;
      right: 32px;
      top: 18px;
      font-size: 18px;
      color: $color-text;
      border: none;
      cursor: pointer;
      background-color: transparent;
      padding: 0;
    }
  }
}
.pi-teamGroup-popup {
  .modal-content {
    overflow: hidden;
  }
}
// Teams-Groups log components
.pi-teamGroups-logs {
  position: relative;
  .pi-collapse {
    margin-bottom: 6px;
    position: relative;
    background-color: #fff;
    border-radius: $radius-sm;
    @include transition(all 0.2s);
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    .pi-collapse-header {
      cursor: pointer;
    }

    &:hover {
      .pi-collapse-header {
        background-color: rgba($color-bg, 0.5);
      }
    }

    &.show {
      .pi-teamsGroups-logs-header {
        border-bottom: 1px solid $color-border;
        &::after {
          @include transform(translateY(-50%) rotate(270deg));
        }
      }
    }
  }
}
// Teams-Groups logs header
.pi-teamsGroups-logs-header {
  padding: 20px 50px 20px 0;
  margin: 0 20px;
  position: relative;
  &::after {
    top: 50%;
    right: 0px;
    font-size: 24px;
    position: absolute;
    color: $color-text;
    content: $icon-expand-right;
    @include transition(all 0.2s);
    font-family: $font-family-icons;
    @include transform(translateY(-50%) rotate(90deg));
  }

  &::before {
    top: 50%;
    width: 1px;
    right: 42px;
    content: '';
    height: 42px;
    position: absolute;
    display: inline-block;
    background-color: $color-border;
    @include transform(translateY(-50%));
  }
}
// Teams-Groups logs body
.pi-teamsGroups-logs-body {
  padding: 8px 20px;
  .pi-teamsGroups-item {
    padding: 12px 0;
    .pi-teamsGroups-subgroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 6px;
      p {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 25px;
        color: rgba($color-text, 0.7);
        margin-bottom: 0;
        strong {
          display: inline-block;
          color: $color-text;
          padding: 0 16px;
          &:not(:last-of-type) {
            position: relative;
            &:before {
              content: '';
              width: 1px;
              height: 100%;
              display: block;
              max-height: 21px;
              position: absolute;
              background-color: #e4e4e4;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
      }

      ul {
        width: 100%;

        li {
          padding: 10px 0;

          &:not(:last-child) {
            border-bottom: 1px solid $color-border;
          }
        }
      }
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-border;
    }
  }
}
// Teams-Groups-modal
.pi-teams-groups-modal {
  min-height: 350px;
  max-height: calc(100vh - 150px);
}
