.pi-switcher {
    height: 100%;

    .pi-switcher-wrapper {
        padding: 25px 40px;
        display: flex;
        overflow: auto;

        .pi-group {
            .pi-inner {
                display: flex;
                flex-wrap: wrap;
            }
            h5 {
                width: 100%;
            }
        }

        .pi-current {
            position: relative;
            padding-right: 30px;

            &::after {
                content: '';
                width: 1px;
                height: 110px;
                display: block;
                position: absolute;
                top: 33px;
                right: 19px;
                border-left: 2px dashed $color-border;
            }
        }

        .pi-workspace-switch {
            margin-right: 10px;

        }
    }
}