// Fonts
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-size-base: .875rem !default;
$font-size-lg: 1rem !default;
$font-size-sm: .75rem !default;
$font-size-huge: 2rem !default;

// Theme colors
$blue:    #2c7be5;
$pink:    #9D7BD8;
$red:     #e51c23;
$yellow:  #ff9800;
$green:   #4CAF50;
$cyan:    #47BAC1;
$orange:  #faa528;
$orange-trans-50: #faa528AA;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$greyish-black: #4B5258;

$primary:          $blue;
$secondary:        $pink;
$tertiary:         lighten($blue, 10%);
$success:          $green;
$info:             $cyan;
$warning:          $orange;
$danger:           $red;
$light:            $gray-100;
$dark:             #354052;

// Body
$body-bg: #F8FAFC;
$body-color: $black;

// Border radius
$border-radius-sm: .15rem;
$border-radius:    .3rem;
$border-radius-lg: .45rem;

// Navbar
$navbar-border-bottom: 1px solid #EDEEF0;

// Card
$card-border-color: #EAECEF;
$card-shadow: 0 .5rem 1rem rgba(20,40,60,.03);
$card-title-font-weight: $font-weight-bold;

// Sidebar general
$sidebar-bg: $greyish-black;
$sidebar-border: 0 solid $greyish-black;

// Sidebar brand
$sidebar-brand-color: $gray-800;
$sidebar-brand-font-weight: $font-weight-bold;

// Sidebar header
$sidebar-header-color: $gray-600;
$sidebar-header-text-transform: uppercase;
$sidebar-header-font-size: 0.65rem;

// Sidebar link
$sidebar-link-font-weight: $font-weight-normal;
$sidebar-link-color: $white;
$sidebar-link-hover-color: $white;
$sidebar-link-hover-bg: $orange;
$sidebar-link-hover-font-weight: $font-weight-bold;
$sidebar-link-active-color: $white;
$sidebar-link-active-bg: $orange;
$sidebar-link-active-font-weight: $font-weight-bold;

// Sidebar child link
$sidebar-link-child-color: $white;
$sidebar-link-child-bg: $greyish-black;
$sidebar-link-child-hover-color: $white;
$sidebar-link-child-hover-bg: $orange;
$sidebar-link-child-active-color: $white;
$sidebar-link-child-active-bg: $orange;

// Sidebar link icon
$sidebar-link-icon-color: $orange;
$sidebar-link-icon-hover-color: $white;
$sidebar-link-icon-active-color: $white;

// Sidebar bottom
$sidebar-bottom-color: $gray-800;
$sidebar-bottom-bg: $gray-100;

// Simplebar
$simplebar-scrollbar-bg: rgba(darken($primary, 50%), 0.175);

// Hamburger
$hamburger-width-top: 24px;
$hamburger-width-middle: 24px;
$hamburger-width-bottom: 14px;
