.pr-wrapper {
  height: 100%;
  background-color: $color-bg;
}
.pr-container {
  width: 100%;
  margin: auto;
  padding: 0 15px;
  max-width: 930px;
}

.pr-header {
  padding: 16px 0 12px;
  border-bottom: 1px solid $color-border;

  .pr-title {
    display: flex;
    align-items: center;

    img {
      width: 52px;
      display: block;
    }

    h2 {
      font-weight: 500;
      margin-bottom: 0;
      padding-left: 20px;
      color: $color-text;
    }
  }

  .pr-search {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    gap: 10px;

    .pr-select-wrapper {
      position: relative;
      flex: 1;
      min-width: 300px;
    }

    .icon-search {
      position: absolute;
      top: 50%;
      left: 16px;
      z-index: 1;
      font-size: 24px;
      transform: translateY(-50%);
      color: rgba($color-text, 0.4);
    }

    .react-select__value-container {
      padding-left: 48px;
    }

    .pr-device-count {
      position: absolute;
      top: 50%;
      right: 40px;
      display: flex;
      padding: 4px 8px;
      color: $color-text;
      align-items: center;
      border-radius: $radius-xs;
      transform: translateY(-50%);
      background-color: rgba($color-primary, 0.15);

      i {
        font-size: 22px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        padding-left: 4px;
      }
    }
  }
}

.pr-content {
  padding: 16px 0;
  padding-bottom: 100px;

  .pr-group-row {
    margin-bottom: 20px;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .pi-text-field {
      .pi-icon {
        color: $color-primary;
      }
    }

    .pr-col-6 {
      max-width: 50%;
    }

    .pr-col-7 {
      max-width: calc(100% / 12 * 7);
    }
  }

  .pr-radio-group {
    display: flex;

    li {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .pr-selected-tags {
    display: flex;
    margin-top: 12px;

    li {
      &:first-child {
        margin-left: 0;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .pr-custom-createble {
    display: flex;
    justify-content: space-between;

    .pi-select {
      width: 80px;
    }

    .pi-text-field {
      width: 100%;
      max-width: calc(100% - 120px);
    }
  }
}
