.events-card-container {
    .body {
        width: calc(100% + 40px);
        height: calc(66vh - 52px);
        overflow-y: auto;
        position: relative;
        margin: 0 -20px;

        .event-item-container {
            height: 48pt;
            padding: 8px 20px;
            display: flex;
            border-bottom: 1px solid $border-color;

            .date-time {
                font-size: $font-size-sm;
            }
        }
    }

    .yellow-dot {
        background-color: $orange;
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
}