//ticket status types
.ticket-status {
  margin: 15px 15px 15px 0;
  display: inline-block;
  border-radius: 16px;
  text-align: center;
  padding: 6px 15px;
  border: 1px solid;
  min-width: 80px;
  color: white;
  cursor: auto;

  &.in-progress {
    background-color: $blue;
    border-color: $blue;
  }

  &.open {
    background-color: $teal;
    border-color: $teal;
  }

  &.completed {
    background-color: $green;
    border-color: $green;
  }

  &.deleted {
    background-color: $red;
    border-color: $red;
  }
}

//ticket options
.ticket-options {
  .title {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 10px;
    min-width: 90px;
  }
}
//editable ticket and a new ticket
.add-ticket-to-event {
  margin: 5px auto;

  form {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;

    &>.form-group {
      width: calc(33.3% - 25px);
      margin-bottom: 0.2rem;

      label {
        margin-bottom: 0.1rem;
      }
    }
  }
}

.editable-ticket {
  border: 1px solid #2c7be5;
  background: #f8fafc;
  border-radius: 16px;
  position: absolute;
  padding: 12px 15px;
  z-index: 1;
  left: 0;
  top: 0;

  form {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;

    .form-group {
      width: calc(33.3% - 50px);

      &.full-width {
        width: 100%;
      }
    }
  }

  &.hide {
    overflow: hidden;
    display: none;
  }
}

.add-ticket-to-event, .editable-ticket {
  form {
    >.form-group {
      &:nth-last-of-type(2), &:nth-last-of-type(3){
        width: calc(50% - 25px);
      }

      &.description-field {
        width: 100%;

        textarea {
          height: 80px;
        }
      }
    }
  }
}

//editable ticket and a new ticket end

//about user
.user-info {
  color: rgba(255,255,255,0.85);
  letter-spacing: 2.5px;
  border-radius: 50px;
  margin-right: 10px;
  text-align: center;
  border: 1px solid;
  line-height: 50px;
  background: #000;
  font-weight: 900;
  font-size: 18px;
  height: 50px;
  width: 50px;
}

.users-comment {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
  margin-bottom: 35px;
  margin-top: 25px;

  &:last-child {
    border-bottom: none;
  }
}

.user-name {
  font-weight: 700;
}

//about user end

.post-date {
  color: rgba(#000000,0.8);
}

//opacity clasess
.opacity-6 {
  opacity: 0.6;
}

.opacity-9 {
  opacity: 0.9;
}

.newTicketDispatcherError {
  border: 1px solid red;
  border-radius: 5px;
}
