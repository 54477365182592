.fl-switch-outlined {
  padding: 0 12px;
  position: relative;
  align-items: center;
  display: inline-flex;
  border-radius: $radius-sm;
  justify-content: space-between;
  border: 1px solid $color-border;

  & > span {
    position: relative;
    padding: 10px 9px 10px 0;

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $color-border;
    }
  }
}