.pi-title-container {
    padding: 20px 0;

    &.pi-indicator {
        display: flex;
        align-items: center;

        .pi-title {
            width: 100%;
            max-width: calc(100% - 40px);
        }
    }

    .pi-title {
        width: 100%;
        position: relative;

        &:not(.pi-align-left):not(.pi-align-right) {
            text-align: center;

            h5 {
                padding: 0 20px;
            }
        }

        &.pi-align-right {
            text-align: right;

            h5 {
                padding-left: 20px;
            }
        }

        &.pi-align-left {
            text-align: left;

            h5 {
                padding-right: 20px;
            }
        }

        &::before {
            content: ' ';
            height: 1px;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            @include transform(translateY(-50%));
            border-top: 1px dashed rgba($color-text, 0.5);
        }

        h5 {
            z-index: 1;
            margin-bottom: 0;
            font-weight: 500;
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            color: rgba($color-text, 0.6);
        }
    }

    &:not(.pi-gray-bg) h5 {
        background-color: #fff;
    }

    &.pi-gray-bg h5 {
        background-color: #f8fafc;
    }

    .pi-step-indicator {
        color: #fff;
        width: 24px;
        height: 24px;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        margin-right: 15px;
        border-radius: 100%;
        
        i {
            display: inline-block;
        }

        &:not(.pi-completed) {
            background-color: rgba($color-text, 0.4);
        }

        &.pi-completed {
            background-color: $color-green;
        }
    }
}