.pi-reports-wrapper {
  .pi-reports-table {
    tbody {
      tr {
        td {
          .pi-cell-holder {
            display: none;
          }
        }
      }
    }
  }
}

.pi-expanded-report-body {
  .pi-expanded-report-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    h5 {
      font-weight: bold;
      font-size: 21px;
      color: $color-text;
      margin-bottom: 0;
    }
    .pi-expanded-action {
      display: flex;
    }
    .pi-expanded-close {
      display: none;
      border: 0;
      color: rgba(53, 64, 82, 0.7);
      background-color: transparent;
      transition: all 0.2s;
      padding: 0;
      i {
        font-size: 24px;
      }
    }
  }
  .pi-log-title {
    text-align: left;
    h5 {
      padding: 0 32px 0 0;
    }
  }
  .pi-expanded-report-content {
    padding: 0 18px;
    .pi-log-section {
      max-width: 1130px;
      padding: 24px 0 40px;
      &:not(:last-of-type) {
        border-bottom: 1px solid $color-border;
        padding: 24px 0;
      }
      &:first-child {
        padding: 0 0 24px;
      }
      &-row {
        display: flex;
        .pi-info-string {
          padding: 0 38px;
          position: relative;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:not(:last-of-type) {
            &:before {
              right: 0;
              top: 50%;
              width: 1px;
              height: 100%;
              content: '';
              max-height: 34px;
              position: absolute;
              display: inline-block;
              background-color: $color-border;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  .pi-expanded-report-total {
    padding-top: 20px;
    .pi-info-string {
      justify-content: flex-end;
    }
  }
}

// Add/edit reports
.pi-add-reports {
  width: 100%;
  height: 100%;
  position: fixed;
  max-width: 930px;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 5;
  display: block;
  background-color: #fff;
  border: 1px solid $color-border;

  .pi-report-header {
    display: flex;
    align-items: center;
    padding: 25px 30px 15px;
    justify-content: space-between;
    border-bottom: 1px solid $color-border;

    h5 {
      display: flex;
      align-items: center;
      font-size: 24px;
      margin-bottom: 0;
      font-weight: bold;
      color: $color-text;
      button {
        &.pi-back-btn {
          border: 0;
          padding: 0;
          font-size: 24px;
          margin-right: 12px;
          color: $color-text;
          background-color: transparent;

          i {
            @include transform(rotate(90deg));
          }
        }
      }
    }

    .pi-expanded-action {
      display: flex;
    }

    .pi-chart-trigger {
      margin-right: 8px;
      color: $color-text;

      &.pi-active {
        background-color: $color-text;

        i {
          color: #fff;
        }
      }

      i {
        font-size: 24px;
      }
    }

    .pi-close {
      @include close-button(24px, 10px, 24px);
    }
  }

  .pi-charts {
    padding: 15px 40px;
  }

  .pi-current-report {
    flex-wrap: wrap;
    margin-top: 15px;
    align-items: center;

    .pi-image {
      width: 80px;
      height: 80px;
      cursor: pointer;
      border-radius: $radius-md;
      border: 1px solid $color-border;
      background: no-repeat center;
      background-size: contain;
    }

    .pi-info {
      padding-left: 15px;

      strong {
        font-size: 20px;
        color: $color-text;
      }
      p {
        opacity: 0.6;
        display: flex;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        align-items: center;

        i {
          font-size: 22px;
          padding-right: 5px;
        }
      }
    }

    .pi-title {
      width: 100%;
      margin-top: 20px;
      font-weight: 700;
      color: $color-text;
    }
  }

  .pi-reports-info {
    padding: 0 30px;
    .error-message {
      color: #f44336;
      margin-top: 5px;
    }
  }

  .pi-multiple-areas {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .pi-text-area {
      width: calc(100% - 50px);
    }

    .pi-btn {
      margin-bottom: 5px;
    }
  }

  .pi-reports-content {
    padding: 15px 40px;

    .pi-group-row {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .pi-date-fields {
      display: flex;
      flex-wrap: wrap;
      max-width: 420px;
      .pi-date-picker {
        width: calc(100% - 185px);
      }
      .pi-time-picker {
        padding-left: 10px;
        width: 185px;
      }
    }

    .pi-inspection-field {
      width: 100%;
      max-width: calc(100% - 440px);
    }

    .pi-group-label {
      display: flex;
      align-items: center;

      .pi-info-tooltip {
        margin-left: 5px;
        font-size: 20px;
        color: $color-text;
      }

      .pi-field-label {
        margin-bottom: 0;
      }
    }

    .pi-impact-type {
      margin-top: 30px;
      margin-bottom: 30px;
      .pi-group-row {
        align-items: center;
      }
      .pi-radio-group {
        display: flex;
        padding-left: 16px;

        li {
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }

    .pi-section-label {
      display: flex;
      align-items: center;

      h4 {
        margin-bottom: 0;
        font-weight: 500;
        color: $color-text;
      }

      .pi-add-btn {
        border: 0;
        padding: 0;
        font-size: 24px;
        margin-left: 10px;
        color: $color-text;
        background-color: transparent;
      }
    }
  }

  .pi-additional {
    padding-right: 80px;

    .pi-group-row {
      margin-top: 25px;
      position: relative;
      align-items: flex-end;

      & > .pi-text-field,
      & > .pi-select {
        width: 100%;
        max-width: 280px;
        margin-right: 10px;
        .css-fwvn83-container {
          border: none;
          box-shadow: none;
          .css-1wy0on6 {
            display: none;
          }
        }
      }

      .pi-text-area {
        width: 100%;
        margin-top: 25px;
      }

      .pi-cost {
        width: calc(100% - (2 * 290px));

        .pi-btn {
          padding: 0;
          text-transform: none;
        }
      }
    }

    .pi-aditional-delete {
      position: absolute;
      right: -25px;
      height: 115px;
      bottom: 0;
      border-left: 1px solid $color-border;

      &::before,
      &::after {
        content: '';
        height: 1px;
        width: 8px;
        display: block;
        position: absolute;
        left: -8px;
        background-color: $color-border;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      .pi-remove {
        top: 50%;
        border: 0;
        padding: 0;
        font-size: 24px;
        margin-left: 10px;
        color: $color-red;
        position: absolute;
        background-color: transparent;
        @include transform(translateY(-50%));
      }
    }
  }

  .pi-additinal-actions {
    width: 100%;
    margin-top: 10px;

    .pi-btn:nth-child(1) {
      margin-right: 13px;
    }
  }
}

.pi-activity-wrapper {
  .pi-add-reports {
    .pi-report-header {
      border-bottom: none;
    }
    .pi-reports-info {
      .pi-text-area {
        textarea {
          resize: none;
        }
      }
      .pi-additional {
        .pi-select {
        }
      }
    }
  }
}

.pi-filter-mobile-btn {
  display: none;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background: $text-color;
  box-shadow: 4px 8px 20px rgba(53, 64, 82, 0.19);
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  border: none;
  border-radius: 50%;
  i {
    font-size: 24px;
    color: #fff;
  }
}

.add-report-desktop-time-picker {
  ul {
    overflow-y: auto !important;
    li {
      height: 40px !important;
    }
    &::after {
      height: unset !important;
    }
  }

  ul:nth-child(3) {
    overflow: visible !important;
  }
}
