// Global checkbox element

.pi-radio {
    display: flex;
    cursor: pointer;
    margin-bottom: 0;
    align-items: center;
    @include transition(all 0.2s);

    input {
        display: none;

        &:checked + .pi-check-icon {
            &::before {
                content: $icon-radio-button-checked;
            }
        }
    }

    .pi-check-icon {
        display: block;
        padding-right: 10px;

        &::before {
            font-size: 24px;
            content: $icon-radio-button-unchecked;
            font-family: $font-family-icons;
        }
    }

    .pi-check-text {
        font-size: $sm;
        font-weight: 500;
        color: $color-text;
    }

    // Color modifications
    &.pi-primary {
        .pi-check-icon {
            &::before {
                color: $color-primary;
            }
        }
    }

    &.pi-secondary {
        .pi-check-icon {
            &::before {
                color: $color-secondary;
            }
        }
    }

    &.pi-text {
        .pi-check-icon {
            &::before {
                color: $color-text;
            }
        }
    }
}