// Global table styles
tbody {
    tr {
        td {
            vertical-align: middle !important;
        }
    }
}
.pi-table {
    position: relative;

    .pi-pointer {
        tbody {
            tr {
                cursor: pointer;
            }
        }
    }

    table {
        width: 100%;
        border-spacing: 0 3px;
        border-collapse: separate;

        td {
            position: relative;
            border: solid $color-border;
            border-top-width: 1px;
            background-color: #fff;
            border-bottom-width: 1px;

            &:not(:last-child) {
                border-right-width: 0;
            }

            &:not(:first-child) {
                border-left-width: 0;
            }

            &:first-child {
                border-left-width: 1px;
            }

            &:last-child {
                border-right-width: 1px;
            }

            .pi-td {
                &::before {
                    top: 50%;
                    left: 1px;
                    width: 1px;
                    content: '';
                    height: 100%;
                    max-height: 69px;
                    position: absolute;
                    display: inline-block;
                    background-color: $color-border;
                    @include transform(translateY(-50%));
                }
            }
        }

        &.pi-white-row {
            background-color: $color-bg;
        }
    }
    thead {
        position: relative;
        top: -4px;
        z-index: 2;

        .pi-resp-title {
            display: none;
        }

        tr {
            th {
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: #F8FAFC;

                &:first-child {
                    .pi-th {
                        &::before {
                            display: none;
                        }
                    }
                }
                .pi-th {
                    display: flex;
                    position: relative;
                    align-items: center;

                    &::before {
                        left: 0;
                        top: 50%;
                        width: 1px;
                        height: 80%;
                        content: '';
                        max-height: 34px;
                        position: absolute;
                        display: inline-block;
                        background-color: $color-border;
                        @include transform(translateY(-50%));
                    }

                    &.pi-asc {
                        .pi-sort {
                            @include transform(rotate(180deg));
                        }
                    }

                    &.pi-sorted {
                        .pi-icon {
                            color: $color-secondary;
                        }
                    }

                    .pi-checkbox {
                        position: relative;
                        left: -3px;
                    }
                }

                span {
                    font-weight: 500;
                    color: rgba($color-text, 0.7);
                }

                .pi-sort {
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                    margin-left: 8px;
                    background-color: transparent;

                    &:hover {
                        .pi-icon {
                            color: $color-secondary;
                        }
                    }

                    .pi-icon {
                        position: relative;
                        font-size: 18px;
                        color: $color-text;
                        @include transition(all 0.2s);
                    }
                }
            }
        }
    }
    tbody {
        tr {
            &.pi-pointer {
                cursor: pointer;
            }
            &.pi-active {
                pointer-events: none;
                position: relative;
                z-index: 4;

                td {
                    position: relative;
                    z-index: 4;
                }

                &-primary {
                    td {
                        background-color: $color-primary;

                        &.pi-type {
                            &-url {
                                .pi-td {
                                    a {
                                        color: #fff;
                                    }
                                }
                            }

                            &-text {
                                .pi-td {
                                    p {
                                        color: #fff;
                                        word-break: break-word;
                                    }
                                }
                            }

                            &-icon-btns {
                                .pi-td {
                                    .pi-icon-btn {
                                        &.pi-color-secondary {
                                            i {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            td {
                &:first-child {
                    border-top-left-radius: $radius-md;
                    border-bottom-left-radius: $radius-md;
                }

                &.no-border, &:first-child {
                    .pi-td {
                        &::before {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    border-top-right-radius: $radius-md;
                    border-bottom-right-radius: $radius-md;
                }

                &.pi-type {
                    &-url {
                        .pi-td {
                            text-align: left;

                            a {
                                font-weight: 500;
                                color: $color-primary;
                                text-decoration: none;
                                word-break: break-word;
                                @include transition(all 0.2s);
                                &.pi-color-text {
                                    color: $text-color;
                                    text-decoration: underline;
                                }
                                &:hover {
                                    opacity: 0.8;
                                }
                            }

                            i {
                                font-size: 17px;
                                color: $color-text;
                                padding-right: 10px;
                            }
                        }

                        &.pi-secondary {
                            .pi-td {
                                a {
                                    font-weight: 400;
                                    color: $text-color;
                                    text-decoration: underline;
                                }
                            }
                        }

                        &.pi-has-icon {
                            .pi-td {
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    &-text-icon {
                        .pi-td {
                            display: flex;
                            text-align: left;
                            align-items: center;
                            //justify-content: space-between;

                            strong {
                                font-weight: 500;
                                line-height: 17px;
                                color: $color-text;
                                display: inline-block;
                                width: calc(100% - 35px);
                            }

                            .pi-icon {
                                cursor: pointer;
                                font-size: 22px;
                                padding-left: 8px;
                            }
                        }
                    }

                    &-text {
                        .pi-td {
                            text-align: left;

                            p {
                                font-weight: 400;
                                margin-bottom: 0;
                                color: $color-text;
                                @include ellipsis(100%);
                            }

                            .pi-cell-note {
                                margin-top: 5px;
                                font-weight: 500;
                                color: rgba($color-text, 0.8);
                            }
                        }
                    }

                    &-image {
                        @extend .no-border;

                        & + td {
                            @extend .no-border;
                        }

                        .pi-td {
                            text-align: left;

                            .pi-image {
                                width: 100px;
                                height: 100px;
                                background: #fff no-repeat center;
                                background-size: contain;
                                border-radius: $radius-md;
                                border: 1px solid $color-border;
                            }
                        }
                    }

                    &-status {
                        border: 1px solid;

                        & + td {
                            @extend .no-border;
                        }

                        @each $key, $val in $typography-colors {
                            &.pi-status-#{$key} {
                                border-color: rgba($val, 0.3);
                                background-color: rgba($val, 0.1);

                                p {
                                    color: rgba($val, 0.8);
                                }
                            }
                        }

                        .pi-td {
                            text-align: left;
                        }

                        p {
                            display: flex;
                            margin-bottom: 10px;
                            align-items: center;

                            i {
                                top: -1px;
                                font-size: 22px;
                                position: relative;
                            }

                            span {
                                font-size: $sm;
                                font-weight: 500;
                                padding-left: 5px;
                            }
                        }

                        strong {
                            font-size: $md;
                            font-weight: 700;
                            color: $color-text;
                        }
                    }

                    &-icon-btns {
                        &.pi-hide-action {
                            .pi-td {
                                ul {
                                    li {
                                        &.pi-active {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        .pi-active {
                            i {
                                @include transform(rotate(180deg));
                            }
                        }

                        .pi-td {
                            padding-left: 0;
                            padding-right: 0;

                            ul {
                                display: flex;
                                padding-left: 0;
                                margin-bottom: 0;
                                list-style: none;
                                align-items: center;

                                li {
                                    width: 50%;
                                    position: relative;
                                    text-align: center;
                                    @include transition(all 0.3s);

                                    &:last-child {
                                        &::before {
                                            left: 0;
                                            top: 50%;
                                            width: 1px;
                                            content: '';
                                            height: 100%;
                                            max-height: 22px;
                                            position: absolute;
                                            display: inline-block;
                                            background-color: $color-border;
                                            @include transform(translateY(-50%));
                                        }
                                    }

                                    .pi-icon-btn {
                                        border: 0;
                                        margin: auto;
                                        outline: none;
                                        display: block;
                                        padding: 0 15px;
                                        background-color: transparent;

                                        i {
                                            @include transition(all 0.3s);
                                        }
                                    }
                                }
                            }
                            button {
                                margin: 0 auto;
                            }
                        }
                    }

                    &-date {
                        background: rgba(44, 123, 229, 0.1);
                        border: 1px solid rgba(44, 123, 229, 0.3);
                        text-align: left;

                        & + td {
                            @extend .no-border;
                        }

                        .pi-date-status {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            color: rgba(53, 64, 82, 0.6);
                            margin-bottom: 12px;
                            white-space: nowrap;

                            .pi-date-icon {
                                font-size: 20px;
                                margin-right: 8px;
                            }
                        }

                        span {
                            font-weight: bold;
                            font-size: 17px;
                            line-height: 20px;
                            color: $color-text;
                            white-space: nowrap;

                            &:not(:last-of-type) {
                                margin-right: 10px;
                            }
                        }

                        &.pi-date-impact {
                            background: rgba(44, 123, 229, 0.1);
                            border: 1px solid rgba(44, 123, 229, 0.3);
                        }

                        &.pi-date-missing {
                            background: #FDECEC;
                            border: 1px solid #F9C0C0;

                            .pi-date-status {
                                color: rgba(233, 15, 15, 0.6);

                                .svg {
                                    color: rgba(233, 15, 15, 0.6);
                                }
                            }
                        }
                    }

                    &-checkbox-text {
                        text-align: left;

                        .pi-td {
                            display: flex;
                            align-items: flex-start;
                        }
                    }

                    &-custom-actions {
                        .pi-td {
                            padding-left: 0;
                            padding-right: 0;

                            >ul {
                                display: flex;
                                padding-left: 0;
                                margin-bottom: 0;
                                list-style: none;
                                align-items: center;
                                justify-content: flex-end;

                                >li {
                                    position: relative;
                                    text-align: center;

                                    &:not(:first-child) {
                                        &::before {
                                            left: 0;
                                            top: 50%;
                                            width: 1px;
                                            content: '';
                                            height: 100%;
                                            max-height: 22px;
                                            position: absolute;
                                            display: inline-block;
                                            background-color: $color-border;
                                            @include transform(translateY(-50%));
                                        }
                                    }

                                    .pi-icon-btn {
                                        border: 0;
                                        margin: auto;
                                        display: block;
                                        padding: 0 15px;
                                        background-color: transparent;
                                    }

                                    .pi-menu-expandable {
                                        .pi-btn {
                                            height: auto;
                                            position: relative;
                                            top: 2px;
                                            right: -10px;
                                        }
                                    }
                                }
                            }
                            button {
                                margin: 0 auto;
                            }
                        }
                    }
                }

                .pi-cell-holder {
                    opacity: 0.6;
                    padding-right: 5px;
                }
            }
        }
    }

    &.pi-size-lg {
        .pi-th {
            padding: 20px;
        }

        .pi-td {
            padding: 16px 20px;

            .pi-icon-btn {
                i {
                    font-size: 24px;
                }
            }
        }
        .pi-menu-expandable {
            .pi-td {
                padding: 0;
            }
        }
    }

    &.pi-size-sm {
        .pi-th {
            padding: 20px;
        }

        .pi-td {
            padding: 10px 16px;

            .pi-icon-btn {
                i {
                    font-size: 18px;
                }
            }
        }
    }

    &.pi-reports-table {
        tbody {
            td {
                &.pi-type-text {
                    .pi-td {
                        p {
                            white-space: normal;
                        }
                    }
                }
            }
        }
    }

    // Table loader
    .pi-loader-row {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-sm;
        @include transition(all 0.3s);
        background-color: rgba(60, 65, 69, 0.3);
    }
}

.pi-loader-row {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius-sm;
    @include transition(all 0.3s);
    background-color: rgba(60, 65, 69, 0.3);

    &:not(.pi-fixed) {
        position: absolute;
    }

    &.pi-fixed {
        position: fixed;
    }
}