// Group card global styles

.pi-group-card {
    height: 100%;
    cursor: pointer;
    background-color: #fff;
    border-radius: $radius-md;
    @include transition(all 0.2s);
    border: 1px solid $color-border;
    box-shadow: 0 2px 4px rgba(41, 49, 63, 0.15);

    &:hover {
        border-color: darken($color-border, 10%);
    }

    .pi-card-header {
        padding: 18px 20px 12px;
        border-bottom: 1px solid $color-border;
    }

    .pi-card-meta {
        display: flex;
        padding: 20px 0;

        li {
            padding: 0 20px;

            &:last-child {
                border-left: 1px solid $color-border;
            }
        }
    }

    // Add new card
    &.pi-add-new {
        display: flex;
        padding: 10px 0;
        text-align: center;
        align-items: center;
        justify-content: center;

        i {
            font-size: 36px;
            color: $color-secondary;
        }

        strong {
            display: block;
            font-size: 16px;
            margin-top: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: rgba($color-text, 0.6);
        }
    }
}

