// Dropdown menu styles

.pi-menu-expandable {
    position: relative;

    .pi-action-menu {
        top: 45px;
        width: 230px;
        padding: 20px;
        position: absolute;
        background-color: #fff;
        border-radius: $radius-md;
        border: 1px solid $color-border;
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.15);

        &:not(.pi-expanded) {
            display: none;
        }

        &.pi-position {
            &-left {
                left: 0;
            }

            &-right {
                right: 0;
            }
        }

        h5 {
            font-weight: 500;
            text-align: left;
            text-transform: uppercase;
            color: rgba($color-text, 0.7);
        }

        ul {
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;

            li {
                padding: 10px 0;

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    @include transition(all 0.2s);

                    &:hover {
                        opacity: 0.8;
                    }

                    i {
                        font-size: $xl;
                        position: relative;
                        top: -1px;
                        margin-right: 10px;
                    }

                    span {
                        font-weight: 500;
                        color: $color-text;
                        font-size: $md - 2px;
                    }
                }
            }
        }
    }

    &.pi-index-sm {
        .pi-action-menu {
            z-index: 3;
        }
    }

    &:not(.pi-index-sm) {
        .pi-action-menu {
            z-index: 1;
        }
    }
}

.pi-devices-wrapper {
    .pi-menu-expandable {
        text-align: center;
        button {
            border: none;
            box-shadow: none;
            i {
                color: $color-text;
            }
        }
    }
}