// Page tables width modifications

.pi-locations-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .pi-page-body {
    height: auto;
    align-self: stretch;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .pi-table {
      height: calc(100% - 111px - 45px);
    }
  }

  .pi-locations-table {
    overflow: auto;
    height: 100%;
  }
}
.pi-reports-wrapper {
  height: 100%;

  .pi-page-body {
    height: calc(100% - 220px);
  }

  .pi-table {
    height: 100%;
    tbody {
      tr {
        td.pi-type-text {
          .pi-td {
            p {
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .pi-reports-table {
    overflow: auto;
    height: 100%;
  }
}
.pi-activity-wrapper {
  height: 100%;

  .pi-table {
    height: 100%;
  }
  .pi-page-body {
    height: calc(100% - 220px);
  }

  .pi-activity-table {
    overflow: auto;
    height: 100%;
  }
}

.pi-reports-table {
  th,
  td {
    &:nth-child(1) {
      width: 11%;
    }
    &:nth-child(2) {
      width: 27%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 11%;
    }
    &:nth-child(4) {
      width: 11%;
    }
    &:nth-child(5) {
      width: 11%;
    }
    &:nth-child(6) {
      width: 12%;
    }
    &:nth-child(7) {
      width: 6%;
    }
  }
}
.pi-activity-wrapper {
  th,
  td {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 11%;
    }
    &:nth-child(3) {
      width: 11%;
    }
    &:nth-child(4) {
      width: 12%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 11%;
    }
    &:nth-child(7) {
      width: 15%;
    }
  }
}
.pi-members-table-sizing {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 25%;
    }
  }
}
@media (min-width: 1601px) {
  .pi-activity-table {
    th,
    td {
      &:nth-child(1) {
        width: 26%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 12%;
      }
      &:nth-child(5) {
        width: 16%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 16%;
      }
    }
  }
}
@media (max-width: 1600px) {
  .pi-locations-table {
    th,
    td {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 9%;
      }
      &:nth-child(5) {
        width: 14%;
      }
      &:nth-child(7) {
        width: 20%;
      }
    }
  }
}

.pi-WorkZone-wrapper {
  height: 100%;
  .pi-table {
    height: 100%;
  }

  .pi-page-body {
    height: calc(100% - 120px);
  }

  .pi-WorkZone-table {
    height: 100%;
    overflow: auto;

    th,
    td {
      &:nth-child(1) {
        width: 60%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 5%;
      }
    }
  }

  .pi-search {
    .pi-filter-trigger {
      display: none;
    }
  }
}

// Location page: Team popup (from details side area)
.pi-team-table {
  overflow: auto;

  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 22%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 14%;
    }
    &:nth-child(5) {
      width: 14%;
    }
    &:nth-child(6) {
      width: 10%;
    }
  }
}

// Add workzone modal table
.pi-add-workzone-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 88%;
    }
    &:nth-child(2) {
      width: 12%;
    }
  }
}

.pi-assign-device-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 80%;
    }
    &:nth-child(2) {
      width: 20%;
    }
  }
}

.pi-add-member-table {
  height: 400px;
  overflow: auto;
  table {
    height: 400px;
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 30%;
    }
  }
}

.pi-add-location-table {
  min-height: 300px;
  max-height: calc(var(--app-height) - 300px);
  overflow: auto;

  &.pi-full-height-table {
    height: 100%;
  }
}

.pi-members-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 28%;
    }
    &:nth-child(2) {
      width: 36%;
    }
    &:nth-child(3) {
      width: 28%;
    }
    &:nth-child(4) {
      width: 8%;
    }
  }
}

.pi-admins-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 36%;
    }
    &:nth-child(2) {
      width: 28%;
    }
    &:nth-child(3) {
      width: 28%;
    }
    &:nth-child(4) {
      width: 8%;
    }
  }
}

.pi-detail-workzone-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 60%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
}
.pi-detailDevice-workzone-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 24%;
    }
    &:nth-child(3) {
      width: 22%;
    }
    &:nth-child(4) {
      width: 24%;
    }
    &:nth-child(5) {
      width: 13%;
    }
  }
}

.pi-team-members-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 16%;
    }
    &:nth-child(2) {
      width: 18%;
    }
    &:nth-child(3) {
      width: 16%;
    }
    &:nth-child(4) {
      width: 16%;
    }
    &:nth-child(5) {
      width: 16%;
    }
    &:nth-child(6) {
      width: 18%;
    }
  }
}

.pi-team-locations-table {
  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 19%;
    }
    &:nth-child(5) {
      width: 15%;
    }
  }
}

.pi-wz-devices {
  height: 100%;

  .pi-table {
    height: 100%;
  }

  .pi-page-body {
    height: calc(100% - 160px);
  }
}

.pi-wz-device-table {
  height: 100%;
  overflow: auto;

  table {
    table-layout: fixed;
  }

  th,
  td {
    &:nth-child(1) {
      width: 18%;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 18%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) {
      width: 10%;
    }
    &:nth-child(8) {
      width: 7%;
    }
  }
}

.pi-devices-wrapper {
  height: 100%;

  .pi-table {
    height: 100%;

    table {
      table-layout: fixed;
    }
  }

  .pi-page-body {
    height: calc(100% - 150px);
  }

  .pi-devices-table {
    height: 100%;
    overflow: auto;

    th,
    td {
      &:nth-child(1) {
        width: 17%;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      &:nth-child(2) {
        width: 155px;
      }
      &:nth-child(3) {
        width: 155px;
      }
      &:nth-child(4) {
        width: 145px;
      }
      &:nth-child(5) {
        width: 16%;
      }
      &:nth-child(6) {
        width: 14%;
        min-width: 190px;
      }
      &:nth-child(7) {
        width: 14%;
        min-width: 190px;
      }
      &:nth-child(8) {
        width: 13%;
      }
      &:nth-child(9) {
        width: 6%;
      }
    }

    td.pi-type-text .pi-td p {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.pi-add-member-list-table,
.pi-add-admins-table {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 25%;
    }
  }
}

.pi-add-location-list-table {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
}

.pi-team-members {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 30%;

      &.pi-type-url .pi-td a {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;

      .pi-td p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    &:nth-child(5) {
      width: 8%;
    }
  }
}

.pi-team-locations {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 30%;

      &.pi-type-url .pi-td a {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 8%;
    }
  }
}

.pi-group-members {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 25%;

      &.pi-type-url .pi-td a {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(5) {
      width: 22%;
    }
  }
}

.pi-group-locations {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 30%;

      &.pi-type-url .pi-td a {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 15%;
    }
  }
}

.pi-impact-by-team {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 70%;
    }
    &:nth-child(2) {
      width: 30%;
    }
  }
}

.pi-assign-role-table {
  table {
    table-layout: fixed;
  }
  th,
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 70%;
    }
  }
}

@media screen and (max-width: 600px) {
  .pi-users-table-wrapper {
    .pi-users-table {
      overflow: auto;
      height: calc(100vh - 200px) !important;
      padding-bottom: 180px;
    }
  }
}

.pi-users-table-wrapper {
  .pi-users-table {
    overflow: auto;
    height: calc(var(--app-height) - 280px);
  }
}

.pi-roles-table-wrapper {
  overflow-y: auto;
  height: calc(var(--app-height) - 220px);
}
