// Team page container heading

.pi-header-loader,
.pi-content-heading {
}

.pi-search-header {
  min-height: 70px;
}

.pi-content-heading {
  background-color: #fff;
  padding: 25px 40px 25px 20px;
  border-bottom: 1px solid $color-border;

  .pi-details {
    display: flex;
    align-items: center;

    .pi-title-area {
      display: flex;

      &:not(.pi-has-btn) {
        padding-left: 30px;
      }
    }

    .pi-back-btn {
      border: 0;
      padding: 0;
      font-size: 20px;
      margin-right: 12px;
      color: $color-text;
      background-color: transparent;

      i {
        @include transform(rotate(90deg));
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;
      color: $color-text;
    }

    .pi-team-stats {
      display: flex;
      padding-left: 25px;
      align-items: center;

      li {
        padding: 0 32px;

        &:not(:first-child) {
          border-left: 1px solid $color-border;
        }

        &.pi-has-icon {
          display: flex;
          align-items: center;

          i {
            font-size: 27px;
            margin-right: 15px;
            color: rgba($color-text, 0.5);
          }
        }
      }
    }

    .pi-actions {
      display: flex;
      margin-left: auto;

      .pi-menu-expandable {
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
}

