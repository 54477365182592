@media (max-width: 1400px) {
    .pi-add-reports {
        max-width: 780px;

        .pi-reports-content {
            padding-right: 0;
        }

        .pi-additional {
            .pi-group-row {
                & > .pi-text-field,
                & > .pi-select {
                    max-width: 35%;
                }

                .pi-cost {
                    width: calc(30% - 20px);
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .pi-add-reports {
        max-width: 100%;
    }
}

@media (min-width: 769px) {
    .pi-add-reports {
        .pi-close {
            display: none;
        }

        .pi-additinal-actions {
            display: none;
        }

        .pi-current-report {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .pi-add-reports {
        padding-bottom: 120px;

        .pi-report-header {
            padding-left: 20px;
            padding-right: 20px;

            h5 {
                font-size: 20px;
            }
        }

        .pi-current-report {
            display: flex;
        }

        .pi-charts {
            padding-left: 0;
            padding-right: 0;
        }

        .pi-reports-content {
            padding-left: 0;

            .pi-date-fields {
                max-width: 100%;

                .pi-date-picker, .pi-time-picker {
                    width: 100%;
                }

                .pi-time-picker {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 30px;
                }
            }

            .pi-inspection-field {
                max-width: 100%;
                margin-top: 30px;
            }

            .pi-impact-type {
                .pi-group-label {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .pi-radio-group {
                    padding-left: 0;
                    li {
                        .pi-popover {
                            left: 100%;
                        }
                    }
                }
            }
        }

        .pi-additional {
            padding-right: 0;

            .pi-add-btn {
                display: none;
            }

            .pi-aditional-delete {
                display: none;
            }
        }

        .pi-expanded-action {
            position: fixed;
            bottom: 0;
            z-index: 3;
            width: 100%;
            left: 0;
            background-color: #fff;
            justify-content: center;
            padding: 20px 30px 30px 30px;
            border-top: 1px solid #E4E4E4;
        }

        .pi-additinal-actions {
            display: flex;
        }
    }
}

@media (max-width: 600px) {
    .pi-add-reports {
        .pi-logs-body {
            .pi-log-title {
                text-align: left;
                h5 {
                    padding: 0;
                }
                &:before {
                    display: none;
                }
            }
        }
        .pi-reports-info {
            padding-left: 20px;
            padding-right: 20px;
        }
        .pi-additional {
            .pi-group-row {
                & > .pi-text-field, .pi-cost, & > .pi-select {
                    width: 100%;
                    max-width: 100%;
                    margin-right: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .pi-add-reports {
        .pi-reports-content {
            .pi-impact-type {

                .pi-radio-group {
                    display: block;

                    li {
                        margin-bottom: 10px;
                    }

                    li:not(:first-child) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}