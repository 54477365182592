$no_impact: #498f5c;
$minor: #faa528;
$major: #e90f0f;
$title_color: #4b5258;

$body_height: 200px;

.container {
  display: flex;
  padding: 20px 20px 31px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid rgba(53, 64, 82, 0.3);
  background: #fff;
  margin: 10px 0px;

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      margin: 0px;
      color: $title_color;
      font-family: Roboto;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.128px;
    }

    .rangesInfoContainer {
      display: flex;
      justify-content: flex-start;

      .rangeInfo {
        display: flex;
        align-items: center;
        color: rgba(75, 82, 88, 0.7);
        font-family: Roboto;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.128px;
        text-transform: uppercase;
      }
      .rangeInfo::before {
        content: '• ';
        font-size: 1.1rem;
        margin: 0px 6px;
      }
      .rangeInfo[data-type='minor']::before {
        color: $minor;
      }
      .rangeInfo[data-type='major']::before {
        color: $major;
      }
      .rangeInfo[data-type='no_impact']::before {
        color: $no_impact;
      }
    }
  }

  .body {
    height: $body_height;
    width: 100%;
    position: relative;
    .bodyBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      .rangesLabel {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        justify-content: space-between;

        li {
          margin: 0px 10px 0px 0px;
        }
      }

      .rangeSecons {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        height: 100%;
        .section {
          width: 100%;
          flex: 1;
          border-bottom: dotted 1px black;
        }

        .section[data-type='minor'] {
          background: rgba(250, 165, 40, 0.1);
          border-bottom-color: $no_impact;
        }
        .section[data-type='major'] {
          background: rgba(233, 15, 15, 0.08);
          border-bottom-color: $major;
        }
        .section[data-type='no_impact'] {
          background: rgba(73, 143, 92, 0.1);
          border-bottom-color: $no_impact;
        }
      }
    }

    .bar {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      //height: calc(attr(data-value) * 100 / $body_height);
      min-height: 10px;
      width: 100px;
      background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;

      &__value {
        user-select: none;
        font-weight: bold;
        color: white;
        font-size: 1.1rem;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.2s ease;
        transform: scale(0.8);
      }

      &:hover .bar__value {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .rangesInfoContainer {
    margin: 8px 0px;

    .rangeInfo::before {
      font-size: 1.5rem !important;
    }
  }
}
