// Global cards styles

.pi-card-title {
    font-weight: 500;
    margin-bottom: 0;
    padding: 15px 20px;
    color: $color-text;
    text-transform: uppercase;
}

.pi-card {
    width: 100%;
    margin-bottom: 10px;


    .pi-card-body {
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid $color-border;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        .pi-content {
            &.pi-spacing {
                &-sm {
                    padding: 15px;
                }
                &-md {
                    padding: 24px;
                }
            }
        }

        .pi-footer {
            padding: 10px 15px;
            border-top: 1px solid $color-border;

            .pi-load-more {
                border: 0;
                display: flex;
                margin-left: auto;
                color: $color-text;
                align-items: center;
                background-color: #fff;
                @include transition(all 0.2s);

                span {
                    font-size: $sm;
                    font-weight: 500;
                }

                i {
                    font-size: 22px;
                    margin-left: 5px;
                    @include transition(all 0.3s);
                }

                &:hover {
                    opacity: 0.8;
                }

                &.pi-open {
                    i {
                        @include transform(rotate(180deg));
                    }
                }
            }
        }

        &.pi-loader {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.pi-placeholder {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}