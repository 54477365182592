.container {
  min-height: 300px;
  .tabs {
    display: flex;
    column-gap: 8px;

    .tab,
    .activeTab {
      display: flex;
      height: 35px;
      padding: 8px 12px;
      align-items: center;
      border-radius: 6px;
      border: 1px solid rgba(53, 64, 82, 0.3);
      cursor: pointer;
      background-color: white;

      &[disabled] {
        opacity: 0.5;
      }
    }

    .activeTab {
      background: var(--Pi-Lit-Text, #354052);
      color: #ffffff;
    }
  }
}
