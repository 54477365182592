// Authorization flow layout

.pi-auth-wrapper {
    display: flex;
    padding: 40px 0;
    justify-content: center;
    background: url("../../../../public/img/login_back.png") no-repeat;
    background-size: cover;
}

.pi-auth-container {
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    background-color: #fff;
    padding: 40px 80px 40px;
    box-shadow: 0 18px 64px rgba(0, 0, 0, 0.19);

    .pi-logo {
        max-width: 335px;
        margin: 0 auto 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid $color-border;
    }

    .pi-heading {
        margin-bottom: 25px;

        p {
            font-size: $md;
            font-weight: 400;
            max-width: 350px;
            line-height: 27px;
            text-align: center;
            margin: 12px auto 0;
            color: rgba($color-text, 0.7);
        }
    }
}


