// Search and filter block styles

.pi-search {
    position: relative;

    .pi-filter {
        input {
            padding-right: 48px;
        }
    }
    .pi-filter-trigger {
        top: 50%;
        border: 0;
        padding: 0;
        right: 16px;
        position: absolute;
        color: $color-text;
        @include transition(all 0.2s);
        background-color: transparent;
        @include transform(translateY(-50%));

        &:hover {
            opacity: 0.8;
        }

        i {
            font-size: 24px;
        }
    }
}

// Already selected filters

.pi-applied-filters {
    margin-top: 10px;
}

.pi-tags {
    display: flex;
    overflow: auto;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-start;

    li {
        display: flex;
        font-size: 13px;
        padding: 5px 10px;
        white-space: nowrap;
        align-items: center;
        border-radius: $radius-sm;
        justify-content: space-between;
        background-color: rgba($color-text, 0.07);

        &:first-child {
            margin-left: auto;
        }

        &:not(:first-child) {
            margin-left: 5px;
        }

        span {
            font-weight: 400;
            padding-right: 5px;
            color: rgba($color-text, 0.5);
        }

        strong {
            font-weight: 500;
            color: $color-text;
            padding-right: 2px;
        }

        .pi-remove {
            border: 0;
            padding: 0;
            cursor: pointer;
            background-color: transparent;

            &:hover {
                i {
                    color: $color-red;
                }
            }

            i {
                font-size: 17px;
                @include transition(all 0.2s);
                color: rgba($color-text, 0.7);
            }
        }
    }
}

// Filter block styles

.pi-filter-block {
    top: 45px;
    left: 0;
    right: 0;
    z-index: 4;
    width: 100%;
    position: absolute;
    background-color: #fff;
    border-radius: $radius-md;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .pi-block-header {
        position: relative;
        padding: 40px 30px 0;

        .pi-filter-close {
            border: 0;
            top: 24px;
            right: 24px;
            position: absolute;
            color: rgba($color-text, 0.7);
            background-color: transparent;
            @include transition(all 0.2s);

            &:hover {
                color: $color-text;
            }

            i {
                font-size: 24px;
            }
        }
    }

    .pi-block-body {
        padding: 0 30px 40px;

        .pi-group-row {
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;
            align-items: center;

            .pi-date-picker,
            .pi-select,
            .pi-text-field {
                width: 100%;
                max-width: 225px;
            }

            .pi-custom-column {
                min-width: 150px;
            }

            .pi-date-picker,
            .pi-select {
                &:first-of-type {
                    margin-right: 8px;
                }
            }
        }

        .pi-radio-group {
            display: flex;
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            overflow-x: auto;
            flex-wrap: nowrap;
            align-items: center;

            li {
                white-space: nowrap;

                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }
    }

    .pi-block-footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 32px 32px 32px;
        border-top: 1px solid $color-border;

        .pi-btn:first-of-type {
            margin-right: 8px;
        }
    }
}