// Logo and signal loader

.pi-loader-screen {
    display: flex;
}

.pi-logo-loader {
    width: 166px;
    margin: auto;
    display: flex;
    align-items: center;

    .pi-logo-part1 {
        width: 51px;
    }

    .pi-loader-animation, svg {
        width: 60px;
        margin-left: 5px;
        margin-right: 3px;
    }

    .pi-logo-part2 {
        width: 55px;
    }
}

#eG7u8xzrNEY2_ts {
    animation: eG7u8xzrNEY2_ts__ts 2000ms linear infinite normal forwards
}

@keyframes eG7u8xzrNEY2_ts__ts {
    0% {
        transform: translate(104.949894px, 77.340094px) scale(0.5, 0.5)
    }
    50% {
        transform: translate(104.949894px, 77.340094px) scale(0.72, 0.72)
    }
    100% {
        transform: translate(104.949894px, 77.340094px) scale(0.5, 0.5)
    }
}

#eG7u8xzrNEY5 {
    animation: eG7u8xzrNEY5_c_o 2000ms linear infinite normal forwards
}

@keyframes eG7u8xzrNEY5_c_o {
    0% {
        opacity: 0
    }
    25% {
        opacity: 1
    }
    75% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

#eG7u8xzrNEY8 {
    animation: eG7u8xzrNEY8_c_o 2000ms linear infinite normal forwards
}

@keyframes eG7u8xzrNEY8_c_o {
    0% {
        opacity: 0
    }
    25% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    75% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}