@media (max-width: 1600px) {
    .pi-activity-wrapper {
        .pi-device-details {
            max-width: 840px;
            .pi-device-info {
                .pi-details-body {
                    .pi-image {
                        max-width: 210px;
                    }
                    .pi-details {
                        max-width: calc(100% - 180px);
                        padding: 20px;
                    }
                }
            }
        }
        .pi-add-reports {
           max-width: 820px;
        }
    }
}
@media (max-width: 1200px) {
    .pi-activity-wrapper {
        .pi-page-body {
            height: calc(100% - 170px);
        }
        .pi-filter-mobile-btn {
            display: flex;
        }
        .pi-table {
            padding-left: 0;
            padding-right: 0;
        }
        .pi-device-details {
            max-width: 100%;
            .pi-device-info {
                .pi-details-body {
                    .pi-details {
                        .pi-details-list {
                            .pi-map-button {
                                &-right {
                                    width: 120px;
                                }
                            }
                        }
                    }
                }
            }
            & > .pi-device-details {
                position: fixed;
                max-width: 100%;
                height: 100%;
                right: 0;
                top: 0;
            }
        }
        .pi-add-reports {
            max-width: 100%;
            top: 0;
            right: 0;
            max-height: 100%;
        }
    }
    .pi-activity-report {
        max-width: 100%;
        &-header {
            padding: 25px 12px;
            .pi-expanded-action {
                .pi-width-sm {
                    min-width: 80px;
                }
            }
        }
        .pi-logs-body {
            padding: 25px 12px;
        }
    }
}
@media (max-width: 800px) {
    .pi-activity-wrapper {
        .pi-device-details {
            .pi-device-info {
                .pi-device-header {
                    .pi-info-tag {
                        margin-top: 8px;
                    }
                }
                .pi-details-body {
                    .pi-image {
                        max-width: 210px;
                    }
                    .pi-details {
                        max-width: calc(100% - 210px);
                        padding: 10px 10px 10px 15px;
                        position: initial;

                        .pi-details-list {
                            li {
                                flex-wrap: wrap;
                                border-bottom: 0;
                                padding-top: 0;
                                padding-bottom: 0;

                                .pi-detail-item {
                                    width: 100%;
                                    display: flex;
                                    padding: 5px 0;
                                    flex-wrap: wrap;
                                    max-width: 100%;
                                    align-items: center;

                                    &::before {
                                        display: none;
                                    }

                                    p, strong {
                                        font-size: 14px;
                                        margin-bottom: 0;
                                    }

                                    p {
                                        padding-right: 12px;
                                    }
                                }
                                .pi-view-location-button {
                                    position: static;
                                    margin-bottom: 8px;
                                }
                                &:first-child {
                                    flex-direction: column-reverse;
                                }
                            }
                        }
                    }
                }
            }
            .pi-impact-info {
                padding: 24px 10px;
                &-top {
                    flex-wrap: wrap;

                    h4 {
                        margin-right: 10px;
                    }

                    .pi-title-area {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
                &-empty {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
                .pi-device-chart {
                    padding: 10px 10px 0 0;
                }
            }
            .pi-info-tabs {
                .pi-tab-navigation {
                    padding: 0;
                }
                .pi-team-overview {
                    flex-wrap: wrap;
                    .pi-details-label {
                        max-width: 100%;
                        border: none;
                        padding: 32px 20px 18px;
                    }
                    .pi-details-part {
                        .pi-details-info {
                            display: block;
                            p {
                                max-width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                .pi-statistics {
                    padding-left: 0;
                    padding-right: 0;
                }
                .pi-device-sensitivity {
                    padding: 41px 20px 20px;
                    .pi-slider-header {
                        flex-wrap: wrap;
                        h3 {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                    }
                    .pi-slider-inner {
                        width: 100%;
                        flex-wrap: wrap;
                        flex-direction: row;
                        padding: 15px 20px 0;
                        justify-content: space-between;
                        .pi-high-value {
                            order: 2;
                            margin-top: 15px;
                        }
                        .rc-slider {
                            order: 1;
                            margin: 0;
                            max-width: 100%;
                        }
                        .pi-low-value {
                            order: 3;
                            margin-top: 15px;
                        }
                    }
                }
                .pi-device-orientation {
                    flex-wrap: wrap;
                    padding: 32px 20px 20px;
                    h3 {
                        padding: 0;
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    ul {
                        li:not(.pi-active) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .pi-modal-fullMap {
        .modal-content {
            max-width: 100%;
        }

        .pi-map-marker {
            &.pi-right-top {
                top: inherit;
                bottom: 25px;
            }
        }
    }
}

@media (max-width: 600px) {
    .pi-activity-wrapper {
        .pi-device-details {
            .pi-image {
                border-right: none;
                border-bottom: 1px solid #E4E4E4;
            }
            .pi-device-info {
                .pi-device-header {
                    padding: 0;
                }
                .pi-details-body {
                    .pi-details-overview {
                        flex-direction: column;
                        .pi-image {
                            max-width: 100%;
                            height: 280px;
                            &-box {
                                padding-top: 0;
                                height: 200px;
                            }
                        }
                        .pi-details {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}